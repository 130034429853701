// use when api die :))
export const skipLogin = false;

// Date
export const API_RESPONSE_DATE_FORMAT = 'YYYY-MM-DD';
export const DISPLAY_FORMAT = 'DD/MM/YYYY';
export const MONTH_YEAR = 'MMM YYYY';
export const VN_TIMEZONE = 'Asia/Ho_Chi_Minh';

export const SPECIAL_KEYS = [
  'Alt',
  'Tab',
  'Control',
  'Shift',
  'Backspace',
  'Enter',
  'ArrowLeft',
  'ArrowUp',
  'ArrowRight',
  'ArrowDown',
  'Meta',
  'Delete',
];
