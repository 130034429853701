import { Col, Row } from 'antd';
import moment from 'moment';
import React, { useEffect } from 'react';
import OfferCV from '../../../api/offer';
import { API_RESPONSE_DATE_FORMAT } from '../../../global/constants';
import { statusPreInterviewLabel } from '../constant/stepStatusLabel';
import withStepFrame from './withStepFrame';

function Offer(props) {
  const { Frame, Notes, Status, setConfig, step, status } = props;

  useEffect(() => {
    setConfig({
      api: OfferCV.getById,
      submitApi: OfferCV.post,
      fillData: values => {
        values.onboard = values.onboard
          ? moment(values?.onboard, API_RESPONSE_DATE_FORMAT)
          : undefined;
        return values;
      },
      submitObject: values => {
        if (values.onboard) {
          values.onboard = values.onboard.format(API_RESPONSE_DATE_FORMAT);
        }
        return values;
      },
    });
    //React Hook useEffect has a missing dependency: 'setConfig'
    //eslint-disable-next-line
  }, []);

  return (
    <Frame>
      <Row gutter={20}>
        <Col span={12}>
          <Notes />
        </Col>
        <Col span={12}>
          <Status
            step={step}
            status={status}
            statusLabel={statusPreInterviewLabel}
          />
        </Col>
      </Row>
    </Frame>
  );
}

export default withStepFrame(Offer);
