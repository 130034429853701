import { Col, Row } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PhysiognomyReview2Api from '../../../api/PhysiognomyReview2Api';
import SelectLevel from '../form-components/SelectLevel';
import withStepFrame from './withStepFrame';

function Physiognomy2(props) {
  const { Frame, Notes, Status, setConfig, step, status, isFailed } = props;
  const { t } = useTranslation();

  useEffect(() => {
    setConfig({
      api: PhysiognomyReview2Api.getById,
      submitApi: PhysiognomyReview2Api.post,
      submitObject: values => {
        return values;
      },
    });
    //React Hook useEffect has a missing dependency: 'setConfig'
    //eslint-disable-next-line
  }, []);

  return (
    <Frame>
      <Row gutter={20}>
        <Col span={12}>
          <SelectLevel
            name="psychology"
            label={t('review.psychology')}
            disabled={isFailed}
          />
          <SelectLevel
            name="ability"
            label={t('review.ability')}
            disabled={isFailed}
          />
          <SelectLevel
            name="thinking"
            label={t('review.thinking')}
            disabled={isFailed}
          />
          <SelectLevel
            name="communication"
            label={t('review.communication')}
            disabled={isFailed}
          />
          <SelectLevel
            name="summary"
            label={t('review.summary')}
            disabled={isFailed}
          />
        </Col>
        <Col span={12}>
          <Notes />
          <Status step={step} status={status} />
        </Col>
      </Row>
    </Frame>
  );
}

export default withStepFrame(Physiognomy2);
