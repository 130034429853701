import { Form, message } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import GeneralDrawer from '../../../../components/Drawer/GeneralDrawer';
import { FormInput, GeneralSelect } from '../../../../components/Form';
import Status from '../../components/Status';
import useCurrentBreakPoint from '../../../../hooks/useCurrentBreakPoint';
import { handleGetCommonModalWith } from '../../BasicTable/Template/constants';
import { DEFAULT_STATUS } from '../../../../constants';
import criteriaGroupApi from '../../../../api/criteriaGroupApi';
import criteriaApi from '../../../../api/criteriaApi';

export default function CriteriaSetForm(props) {
  console.log('AAAAAAAAAAAAAAAAAAAAAAAA');
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { currentBreakpoint } = useCurrentBreakPoint();
  const { initial = {}, mode } = useSelector(state => state.drawer);

  const catchCallback = e => {
    const msg = e?.data?.message;
    const more = e?.data?.more;
    if (msg?.includes('exists') || more?.includes('Duplicate')) {
      message.error(t('user.updateWarn'));
    }
  };
  console.log(
    '🚀 ~ file: CriteriaGroupForm.jsx:31 ~ fillData ~ initial',
    initial,
  );

  const fillData = () => {
    form.resetFields();
    const criteriasShow = initial?.criterias
      ? initial?.criterias?.map(item => item.id)
      : [];
    const initialData = {
      name: initial?.name,
      id_criteria_group: initial?.id_criteria_group,
      criterias: criteriasShow ? criteriasShow : [],
      description: initial?.description,
      status: initial?.status,
    };
    const init =
      mode === 'add'
        ? { status: initial.status ?? DEFAULT_STATUS }
        : initialData;
    form.setFieldsValue(init);
  };

  const handleGetModalWidth = useMemo(() => {
    const modalWidth = handleGetCommonModalWith(currentBreakpoint);
    return modalWidth;
  }, [currentBreakpoint]);

  const onFinish = async data => {
    const criteriaSetId = initial?.id;
    if (criteriaSetId) {
      await props.editApi(criteriaSetId, data);
    }
    props.fetchData();
  };

  return (
    <GeneralDrawer
      {...props}
      fillData={fillData}
      form={form}
      onFinish={mode === 'edit' ? onFinish : undefined}
      catchCallback={catchCallback}
      fullscreenClassName="table-fullscreen"
      requiredFields={['name']}
      modal
      width={handleGetModalWidth}
    >
      <FormInput
        name="name"
        label={t('criteriaGroup.name')}
        placeholder={t('criteriaGroup.namePlaceHolder')}
        min={3}
        required
      />
      <GeneralSelect
        label={t('sidebar.criteria')}
        name="criterias"
        valueKey="id"
        contentKey="name"
        api={criteriaApi.getAll}
        mode="multiple"
        required
      />
      <FormInput
        label={t('position.description')}
        name="description"
        rows={8}
        textArea
      />
      <Status />
    </GeneralDrawer>
  );
}
