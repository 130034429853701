import moment from 'moment';

/**
 *
 * @param {*} disableFuture if true return from the start date of this month to today else to the end date of this month
 * @returns [startDate, endDate]
 */
export function getThisMonth(disableFuture = false) {
  return [
    moment().startOf('month'),
    disableFuture ? moment().endOf('day') : moment().endOf('month'),
  ];
}
export function getPreviousMonth(time) {
  const newTime = new Date(time);
  newTime.setDate(1);

  return moment(newTime).subtract(1, 'months');
}
export function getEndOfPreviousMonth(time) {}
export function getTwoPreviousMonth(time) {
  const newTime = new Date(time);
  newTime.setDate(1);
  return moment(newTime).subtract(2, 'months');
}
