import moment from 'moment';
import { BACKEND_ONBOARD_CV_DETAIL_FORMAT } from '../../../constants';
import { apiDateToDisplayDate } from '../../../utils/inputDatePickerUtils';
export const preProcessCv = cv => {
  const newCv = { ...cv };
  const { images } = newCv;

  // Images
  newCv.images = images && JSON.parse(images).map(item => ({ show: item }));

  // Birthday
  newCv.birthday = apiDateToDisplayDate(cv?.birthday);

  // Onboard
  newCv.momentOnboard =
    cv?.onboard && moment(cv?.onboard, BACKEND_ONBOARD_CV_DETAIL_FORMAT);

  return newCv;
};
