import { Button, Form, Input, message, Modal, Typography } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import {
  getRefreshToken,
  setCookieRefreshToken,
  setToken,
} from '../../api/Cookie';
import { postLogin, postRefeshToken } from '../../api/userApi';
import FormItem from '../../components/Form/FormItem';
import { REFRESH_TOKEN_TIME_UNIT } from '../../constants/auth';
import LoginLayout from './LoginLayout';
import { setLogin } from './reducer/auth';

const Login = () => {
  const token = getRefreshToken('Refresh-Token');
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loadingLogin, setLoadingLogin] = useState(false);
  const [showingHelp, setShowingHelp] = useState(false);

  const sendRequest = async () => {
    const refreshToken = getRefreshToken('Refresh-Token');
    if (refreshToken) {
      try {
        const res = await postRefeshToken({ refresh_token: refreshToken });
        dispatch(setLogin(res.data.data));
        setToken(res.data.data.access_token);
        setCookieRefreshToken(res.data.data.refresh_token);
        setTimeout(async () => {
          await sendRequest();
        }, res.data.data.expires_in * REFRESH_TOKEN_TIME_UNIT);
      } catch {
        navigate('/login');
      }
    } else {
      navigate('/login');
    }
  };

  const handleLogin = async value => {
    setLoadingLogin(true);
    try {
      const data = {
        username: value.username,
        password: value.password,
      };
      const res = await postLogin(data);
      setLoadingLogin(false);
      dispatch(setLogin(res.data.data));
      setToken(res.data.data.access_token);
      setCookieRefreshToken(res.data.data.refresh_token);
      setTimeout(async () => {
        await sendRequest();
      }, res.data.data.expires_in * REFRESH_TOKEN_TIME_UNIT);
      message.success(t('login.success'));
      const triedLink = localStorage.getItem('triedLink');
      if (triedLink) navigate(triedLink);
      else navigate('/');
    } catch (res) {
      setLoadingLogin(false);
      if (res?.data?.message) {
        if (res.data.message.includes('block'))
          message.error(t('login.lockedAccount'));
        else if (res.data.message.includes('credentials'))
          message.error(t('login.loginFailed'));
        else if (res.data.message.includes('permission'))
          message.error(t('login.noPermission'));
        else if (res.data.message.includes('not found'))
          message.error(t('login.notFound'));
        else message.error(res.data.message);
      }

      console.log('CATCH error', res);
    }
  };
  const redirect = () => {
    if (!token) {
      return (
        <LoginLayout>
          <Form layout="vertical" autoComplete="off" onFinish={handleLogin}>
            <FormItem label={t('login.username')} name="username" required>
              <Input />
            </FormItem>
            <FormItem label={t('login.password')} name="password" required>
              <Input.Password />
            </FormItem>
            <div className="login-form__info">
              <Typography.Link onClick={() => setShowingHelp(true)}>
                {t('login.forgot-password')}
              </Typography.Link>
              <Modal
                title={t('login.forgot-password')}
                visible={showingHelp}
                footer={false}
                onCancel={() => setShowingHelp(false)}
                width={500}
                centered
                className="login-modal"
              >
                <Typography.Text>
                  {t('forgotpassword.it-helpdesk')}
                </Typography.Text>
              </Modal>
            </div>
            <Form.Item>
              <Button
                htmlType="submit"
                className="ant-normal-primary"
                loading={loadingLogin}
                block
                type="primary"
              >
                {t('login.title')}
              </Button>
            </Form.Item>
          </Form>
        </LoginLayout>
      );
    }
    return <Navigate to="/" />;
  };
  return <>{redirect()}</>;
};
export default Login;
