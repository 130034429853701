import { Col, Form } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormInput, GeneralSelect } from '../../../../components/Form';
import { Filter } from '../../../../components/Table';
import criteriaGroupApi from '../../../../api/criteriaGroupApi';

export default function CriteriaSetFilter(props) {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  return (
    <Filter {...props} form={form}>
      <Col sm={8} md={6}>
        <FormInput
          label={t('common.keyword')}
          keywordPlaceholder
          placeholder={t('common.keywordPlaceholder')}
          name="keyword"
        />
      </Col>
      <Col sm={8} xxl={4}>
        <GeneralSelect
          label={t('sidebar.criteriaGroup')}
          name="id_criteria_group"
          valueKey="id"
          contentKey="name"
          api={criteriaGroupApi.getAll}
        />
      </Col>
    </Filter>
  );
}
