import { Col, DatePicker, Form, Row } from 'antd';
import { pick } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import hrInterviewApi from '../../../api/hrInterviewApi';
import techInterviewApi from '../../../api/techInterviewApi';
import toInterviewApi from '../../../api/toInterviewApi';
import { LevelSelect } from '../../../components/Form';
import { DATE_FORMAT_BACKEND } from '../../../constants';
import { DISPLAY_FORMAT } from '../../../global/constants';
import {
  EDITOR_MENUBAR_IN_STEP,
  FIELD_HR_INTERVIEW,
  FIELD_TECH_INTERVIEW,
} from '../constant';
import {
  statusHrInterviewLabel,
  statusTechInterviewLabel,
} from '../constant/stepStatusLabel';
import { onChangeSalary } from '../utils';
import withStepFrame from './withStepFrame';

function Interview(props) {
  const {
    form,
    Frame,
    FormInput,
    FormRichText,
    Status,
    setConfig,
    isFailed,
    setIsFailed,
    step,
    status,
  } = props;
  const { t } = useTranslation();
  const [typeInterview, setTypeInterview] = useState(0);
  const { id } = useParams();

  const objectHRSubmit = values => {
    const valuesSubmit = pick(values, FIELD_HR_INTERVIEW);
    if (valuesSubmit.onboard) {
      valuesSubmit.onboard = valuesSubmit.onboard.format(DATE_FORMAT_BACKEND);
    }
    return valuesSubmit;
  };

  const fillDataHr = item => ({
    hr_notes: item?.notes,
    hr_status: item?.status,
    onboard: item?.onboard
      ? moment(item?.onboard, DATE_FORMAT_BACKEND)
      : undefined,
  });

  const objectHRSubmitFailed = values => {
    return {
      hr_notes: values.hr_notes,
      hr_status: 0,
    };
  };

  const handleChangeForm = index => {
    setTypeInterview(index);
    if (index === 0) {
      setConfig(prev => ({
        ...prev,
        api: hrInterviewApi.getByID,
        submitApi: hrInterviewApi.post,
        requiredFields: ['hr_notes'],
        submitProps: {
          reasonName: 'hr_notes',
        },
        fillData: fillDataHr,
        submitObject: objectHRSubmit,
        submitFailedObject: objectHRSubmitFailed,
        fieldCheckBeforeGetData: FIELD_HR_INTERVIEW,
        fieldCheckSubmitFail: 'hr_status',
      }));
    } else {
      setConfig(prev => ({
        ...prev,
        api: techInterviewApi.getById,
        submitApi: techInterviewApi.post,
        requiredFields: FIELD_TECH_INTERVIEW,
        submitProps: {
          reasonName: 'tech_notes',
        },
        fillData: item => {
          return {
            tech_notes: item?.notes,
            tech_status: item?.status,
          };
        },
        submitObject: values => {
          const valuesSubmit = pick(values, FIELD_TECH_INTERVIEW);
          return valuesSubmit;
        },
        submitFailedObject: values => {
          return {
            tech_notes: values.tech_notes,
            tech_status: 0,
          };
        },
        fieldCheckBeforeGetData: FIELD_TECH_INTERVIEW,
        fieldCheckSubmitFail: 'tech_status',
      }));
    }
  };

  useEffect(() => {
    setConfig({
      api: hrInterviewApi.getByID,
      submitApi: hrInterviewApi.post,
      requiredFields: ['hr_notes'],
      submitProps: {
        reasonName: 'hr_notes',
      },
      fillData: fillDataHr,
      submitObject: objectHRSubmit,
      submitFailedObject: objectHRSubmitFailed,
      fieldCheckSubmitFail: 'hr_status',
    });

    //React Hook useEffect has a missing dependency: 'setConfig'
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (typeInterview) {
      const getInteriewLink = async () => {
        try {
          const res = await toInterviewApi.getById(id);
          const result = res.data.data;
          if (result?.appoint_link) {
            form.setFieldsValue({
              ...form.getFieldsValue(),
              social_link: result.appoint_link,
            });
          }
        } catch (e) {
          console.log('e :', e);
        }
      };
      getInteriewLink();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, typeInterview]);

  useEffect(() => {
    const formValue = form.getFieldsValue();
    if (
      (typeInterview === 0 && formValue?.hr_status === 0) ||
      (typeInterview === 1 && formValue?.tech_status === 0)
    ) {
      setIsFailed(true);
    } else {
      setIsFailed(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeInterview]);

  const disabledDate = dateToChoose => {
    return dateToChoose < moment().subtract(1, 'day').endOf('day');
  };

  return (
    <Frame
      title={[t('hrinterview.title'), t('techinterview.title')]}
      handleChangeForm={handleChangeForm}
      formType={typeInterview}
      statusField={typeInterview ? 'tech_status' : 'hr_status'}
    >
      <Row gutter={20} className={typeInterview ? 'd-none' : 'd-flex'}>
        {/* Salary Now */}
        <Col span={8}>
          <FormInput
            name="salary_now"
            label={t('hrinterview.currentSalary')}
            placeholder={t('hrinterview.currentSalaryPlaceholder')}
          />
        </Col>
        {/* Salary Want */}
        <Col span={8}>
          <FormInput
            name="salary_want"
            label={t('hrinterview.wishSalary')}
            onChange={e => onChangeSalary(e, form, 'salary_want')}
            placeholder={t('hrinterview.currentSalaryPlaceholder')}
          />
        </Col>
        {/* On Board Date */}
        <Col span={8}>
          <Form.Item
            name="onboard"
            label={t('hrinterview.onboard')}
            rules={[
              {
                validator(_, value) {
                  if (!value) return Promise.resolve();
                  if (
                    moment().isBefore(value) ||
                    moment().format(DISPLAY_FORMAT) ===
                      value.format(DISPLAY_FORMAT)
                  )
                    return Promise.resolve();
                  else
                    return Promise.reject(
                      new Error(t('hrinterview.futureTime')),
                    );
                },
              },
            ]}
          >
            <DatePicker
              disabled={isFailed}
              format={DISPLAY_FORMAT}
              disabledDate={disabledDate}
              className="w-100"
              placeholder={t('hrinterview.onboardPlaceholder')}
            />
          </Form.Item>
        </Col>
        {/* Hr Notes */}
        <Col span={24}>
          <FormRichText
            label={t('hrinterview.hrNotes')}
            name="hr_notes"
            height={300}
            menubar={EDITOR_MENUBAR_IN_STEP}
          />
        </Col>
        <Col span={24}>
          <Status
            name="hr_status"
            step={step}
            status={status}
            statusLabel={statusHrInterviewLabel}
          />
        </Col>
      </Row>
      <Row gutter={20} className={typeInterview === 1 ? 'd-flex' : 'd-none'}>
        {/* Salary Suggested */}
        <Col span={8}>
          <FormInput
            name="salary_suggested"
            label={t('techinterview.salarySuggested')}
            onChange={e => onChangeSalary(e, form, 'salary_suggested')}
            placeholder={t('hrinterview.currentSalaryPlaceholder')}
          />
        </Col>
        {/* Level */}
        <Col span={8}>
          <LevelSelect
            name="level_id"
            required={typeInterview}
            disabled={isFailed}
          />
        </Col>
        <Col span={8}>
          <FormInput
            disabled
            name="social_link"
            label={t('tointerview.appointLink')}
            placeholder={t('techinterview.interviewLinkPlaceholder')}
            addonAfter={
              <span
                aria-disabled="true"
                className="add-on-input-text-content"
                onClick={() => {
                  const link = form.getFieldValue('social_link');
                  if (link) {
                    window.open(link, '_blank');
                  }
                }}
              >
                {t('role.view')}
              </span>
            }
          />
        </Col>
        {/* Tech Notes */}
        <Col span={24}>
          <FormRichText
            label={t('techinterview.techNotes')}
            name="tech_notes"
            height={300}
            menubar={EDITOR_MENUBAR_IN_STEP}
          />
        </Col>
        {/* Interview Status */}
        <Col span={24}>
          <Status
            name="tech_status"
            step={step}
            status={status}
            statusLabel={statusTechInterviewLabel}
          />
        </Col>
      </Row>
    </Frame>
  );
}

export default withStepFrame(Interview);
