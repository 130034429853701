import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import emailHistoryApi from '../../../api/emailHistoryApi';
import BreadCrumb from '../../../components/Breadcrumb';
import { DetailBtn } from '../../../components/Buttons';
import NoPermission from '../../../components/NoPermission';
import { TableMain, useTable } from '../../../components/Table';
import EmailHistoryFilter from './components/filter';
import { cols } from './constants';

function EmailHistory() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const title = t('emailHistory.mainTitle');

  const { items, filter, setFilter, loadingTable, fetchData, totalRecord } =
    useTable({
      getApi: emailHistoryApi.getAll,
    });

  const toDetail = id => {
    if (id) {
      navigate('/email-history/' + id);
    }
  };

  const actionButtons = [
    {
      component(record) {
        return <DetailBtn onClick={() => toDetail(record?.id)} />;
      },
    },
  ];

  const renderExcel = cols => {
    return cols.map(col => {
      if (col.dataIndex === 'sent') {
        col.render = value =>
          value ? t('emailHistory.sent') : t('emailHistory.pending');
      }
      return col;
    });
  };

  return (
    <>
      <BreadCrumb
        breadcrumbNameMap={{
          '/email-history': title,
        }}
        buttons={[]}
      />
      <EmailHistoryFilter filter={filter} setFilter={setFilter} />
      <TableMain
        cols={cols}
        nth
        titleLabel={title}
        items={items}
        title={title}
        actionButtons={actionButtons}
        fetchData={fetchData}
        getApi={emailHistoryApi.getAll}
        filter={filter}
        Filter={EmailHistoryFilter}
        setFilter={setFilter}
        callback={renderExcel}
        totalRecord={totalRecord}
        loadingTable={loadingTable}
        excelName="email-history"
        onRow={record => ({
          onClick() {
            toDetail(record?.id);
          },
        })}
      />
    </>
  );
}

function EmailHistoryIndex(props) {
  const permission = useSelector(
    state => state.auth.userInfor.permission['email_history'],
  );
  return permission.view ? (
    <EmailHistory {...props} permission={permission} />
  ) : (
    <NoPermission />
  );
}

export default EmailHistoryIndex;
