import React from 'react';

function RequireIcon() {
  return (
    <span className="ant-menu-item-icon anticon">
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.865 5.4367L3.69836 1.35337C3.37606 1.19296 3.01213 1.13585 2.65619 1.18983C2.30025 1.24381 1.96962 1.40626 1.70938 1.65502C1.44914 1.90378 1.27195 2.22675 1.20198 2.57989C1.132 2.93303 1.17264 3.29917 1.31836 3.62837L2.71836 6.76087C2.75012 6.8366 2.76648 6.91791 2.76648 7.00003C2.76648 7.08216 2.75012 7.16347 2.71836 7.2392L1.31836 10.3717C1.19976 10.6381 1.14963 10.9299 1.17251 11.2207C1.19539 11.5114 1.29056 11.7918 1.44936 12.0364C1.60817 12.2809 1.82558 12.482 2.08184 12.6212C2.33809 12.7604 2.62507 12.8333 2.91669 12.8334C3.18982 12.8306 3.45889 12.7669 3.70419 12.6467L11.8709 8.56337C12.1605 8.41764 12.404 8.1943 12.5742 7.91825C12.7443 7.6422 12.8344 7.3243 12.8344 7.00003C12.8344 6.67576 12.7443 6.35787 12.5742 6.08182C12.404 5.80577 12.1605 5.58243 11.8709 5.4367H11.865ZM11.3459 7.5192L3.17919 11.6025C3.07195 11.654 2.95153 11.6715 2.83408 11.6526C2.71663 11.6337 2.60776 11.5794 2.52207 11.4969C2.43638 11.4143 2.37797 11.3076 2.35467 11.191C2.33136 11.0743 2.34428 10.9533 2.39169 10.8442L3.78586 7.7117C3.8039 7.66987 3.81949 7.62702 3.83252 7.58337H7.85169C8.0064 7.58337 8.15477 7.52191 8.26417 7.41251C8.37356 7.30312 8.43502 7.15474 8.43502 7.00003C8.43502 6.84532 8.37356 6.69695 8.26417 6.58756C8.15477 6.47816 8.0064 6.4167 7.85169 6.4167H3.83252C3.81949 6.37305 3.8039 6.3302 3.78586 6.28837L2.39169 3.15587C2.34428 3.04676 2.33136 2.92577 2.35467 2.80912C2.37797 2.69246 2.43638 2.58572 2.52207 2.5032C2.60776 2.42069 2.71663 2.36634 2.83408 2.34746C2.95153 2.32857 3.07195 2.34604 3.17919 2.39753L11.3459 6.48087C11.4414 6.52982 11.5216 6.60419 11.5776 6.69579C11.6336 6.7874 11.6632 6.89267 11.6632 7.00003C11.6632 7.10739 11.6336 7.21267 11.5776 7.30427C11.5216 7.39588 11.4414 7.47025 11.3459 7.5192Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
}

export default RequireIcon;
