import { Col, Row } from 'antd';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import onBoardCVApi from '../../../api/onBoard';
import { FormDatePicker } from '../../../components/Form';
import { FORMAT_DATEPICKER_L } from '../../../constants';
import { API_RESPONSE_DATE_FORMAT } from '../../../global/constants';
import withStepFrame from './withStepFrame';
import { statusOnboardLabel } from '../constant/stepStatusLabel';

function Onboard(props) {
  const { Frame, Notes, Status, setConfig, isFailed, step, status } = props;
  const { t } = useTranslation();

  useEffect(() => {
    setConfig({
      api: onBoardCVApi.getById,
      submitApi: onBoardCVApi.post,
      requiredFields: ['onboard'],
      submitProps: {
        reasonName: 'notes',
      },
      fillData: values => {
        values.onboard = values.onboard
          ? moment(values?.onboard, API_RESPONSE_DATE_FORMAT)
          : undefined;
        return values;
      },
      submitFailedObject: values => {
        return {
          notes: values.notes,
          status: 0,
        };
      },
      submitObject: values => {
        if (values.onboard) {
          values.onboard = values.onboard.format(API_RESPONSE_DATE_FORMAT);
        }
        return values;
      },
    });
    //React Hook useEffect has a missing dependency: 'setConfig'
    //eslint-disable-next-line
  }, []);

  return (
    <Frame>
      <Row gutter={20}>
        <Col span={12}>
          <Notes name="notes" label={t('review.review')} />
        </Col>
        <Col span={12}>
          <FormDatePicker
            name="onboard"
            label={t('onboard.date')}
            disabled={isFailed}
            format={FORMAT_DATEPICKER_L}
            required
          />
          <Status
            disabled={step === 10 && status === 2}
            statusLabel={statusOnboardLabel}
          />
        </Col>
      </Row>
    </Frame>
  );
}

export default withStepFrame(Onboard);
