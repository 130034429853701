import { Form } from 'antd';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useCallMsApi } from '.';
import GeneralDrawer from '../Drawer/GeneralDrawer';
import { FormDatePicker, FormInput, GeneralSelect } from '../Form';
import {
  DATE_FORMAT_FRONTEND_USE,
  eventTag,
  prioritySelect,
  reminderTitle,
  requiredFields,
} from './constants';

export default function CalendarEventForm({ cv, ...props }) {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const { initial, mode } = useSelector(state => state.drawer);
  const { api: editApi } = useCallMsApi({ type: 'edit' });
  const { api: addApi } = useCallMsApi({ type: 'add' });

  const fillData = () => {
    form.resetFields();
    if (mode === 'edit') {
      const { startDate } = initial;
      const { description, priority } = initial.hrtoolData ?? {};
      form.setFieldsValue({
        startDate: startDate
          ? moment(startDate, DATE_FORMAT_FRONTEND_USE)
          : undefined,
        description,
        priority,
      });
    }
  };

  const onFinish = async values => {
    const { id } = initial;
    const {
      fullname: fullName,
      level,
      last_level,
      position,
      id: cvId,
    } = cv || {};
    const obj =
      mode === 'edit'
        ? { ...initial.hrtoolData, ...values }
        : {
            ...values,
            fullName,
            position: position ? position.title : '',
            cvId,
            level: last_level
              ? last_level
                ? last_level.title
                : ''
              : level
              ? level.title
              : '',
          };

    const data = {
      id,
      body: {
        contentType: 'text',
        content: `${eventTag.open}${JSON.stringify(obj)}${eventTag.close}`,
      },
    };

    const { startDate } = values;
    if (startDate) {
      const obj = {
        timeZone: 'UTC',
        dateTime: startDate.toISOString(),
      };
      data.start = obj;
      data.end = obj;
    }
    if (mode === 'edit') {
      await editApi(data);
    }
    if (mode === 'add') {
      data.subject = reminderTitle(fullName);
      await addApi(data);
    }
  };

  return (
    <GeneralDrawer
      {...props}
      fillData={fillData}
      form={form}
      onFinish={onFinish}
      fullscreenClassName="table-fullscreen"
      requiredFields={requiredFields}
      height={500}
    >
      <FormDatePicker name="startDate" label={t('common.day')} required />
      <GeneralSelect
        name="priority"
        required
        label={t('common.priority')}
        fetchedItems={prioritySelect}
        valueKey="key"
        contentKey="label"
      />
      <FormInput textArea name="description" label={t('common.note')} />
    </GeneralDrawer>
  );
}
