import { DISPLAY_FORMAT } from '../../global/constants';
import i18n from '../../translation/i18n';

export const prioritySelect = [
  {
    key: 'low',
    label: i18n.t('reminder.lowPriority'),
  },
  {
    key: 'medium',
    label: i18n.t('reminder.mediumPriority'),
  },
  {
    key: 'high',
    label: i18n.t('reminder.highPriority'),
  },
  {
    key: 'veryHigh',
    label: i18n.t('reminder.veryHighPriority'),
  },
];

export const eventTag = {
  open: '<hrtool-reminder>',
  close: '</hrtool-reminder>',
};

export const reminderTitle = fullName => `[Cv-reminder] ${fullName}`;

export const requiredFields = ['startDate', 'priority'];

export const DATE_FORMAT_FRONTEND_USE = DISPLAY_FORMAT;

export const MS_API_BASE_URL = 'https://graph.microsoft.com/v1.0/me/';
