import { Col, Form } from 'antd';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormInput,
  RangePicker,
  UserSelect,
} from '../../../../components/Form';
import { Filter } from '../../../../components/Table';

export default function EmailHistoryFilter(props) {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const handleSearch = values => {
    const dateRange = values.daterange;
    if (dateRange) {
      values.daterange = `${dateRange[0].startOf('day').unix()}-${dateRange[1]
        .endOf('day')
        .unix()}`;
    }
    props.setFilter(prev => ({ ...prev, ...values }));
  };

  const convertDateRangeFromFilter = filter => {
    const dateRange = filter.daterange;
    if (dateRange) {
      let arr = dateRange.split('-');
      arr = arr.map(value => moment.unix(value));
      return { daterange: arr };
    }
    return { daterange: undefined };
  };

  return (
    <Filter
      {...props}
      form={form}
      submit={handleSearch}
      initialValues={convertDateRangeFromFilter}
    >
      <Col sm={8} md={6} xl={4} span={6}>
        <RangePicker name="daterange" label={t('common.time')} />
      </Col>
      <Col sm={8} md={6} xl={4} span={4}>
        <UserSelect
          name="author_id"
          label={t('emailHistory.sender')}
          mode="multiple"
        />
      </Col>
      <Col sm={8} md={6} xl={4} span={6}>
        <FormInput
          label={t('common.keyword')}
          placeholder={t('common.keywordPlaceholder')}
          name="keyword"
        />
      </Col>
    </Filter>
  );
}
