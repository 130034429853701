import moment from 'moment-timezone';
import { DEFAULT_PRIORITY } from '../constants/requestPage';
import { API_RESPONSE_DATE_FORMAT, DISPLAY_FORMAT } from '../global/constants';
import i18n from '../translation/i18n';

export const calcPercent = (enumerator, denominator) => {
  return (
    (enumerator && denominator
      ? Math.round((enumerator / (denominator || 1)) * 100)
      : 0) + '%'
  );
};

export const displayPercent = (enumerator, denominator) =>
  `${enumerator}/${denominator} (${calcPercent(enumerator, denominator)})`;

export const levelsObjToString = levels => {
  return levels?.replace(/(\[?{"id": \d+, "title": ")|["}\]]/g, '');
};

//change api date format
export const convertDateFormat = dateString =>
  dateString
    ? moment(dateString, API_RESPONSE_DATE_FORMAT).format(DISPLAY_FORMAT)
    : '-';

export const renderMissingData = text => text ?? '-';

export const getPriority = id => {
  return id === null ? i18n.t('plan.medium') : DEFAULT_PRIORITY[id]?.title;
};
