import { DatePicker, Input } from 'antd';
import moment from 'moment-timezone';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  API_RESPONSE_DATE_FORMAT,
  DISPLAY_FORMAT,
  SPECIAL_KEYS,
} from '../../../global/constants';
import './CorePicker.scss';
import {
  apiDateToDisplayDate,
  formatPostDate,
} from '../../../utils/inputDatePickerUtils';

function CorePicker({ value, onChange, inputProps = {}, ...passedProps }) {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState();

  const { inputDate, pickDate } = useMemo(() => {
    const pickDate = moment(value, API_RESPONSE_DATE_FORMAT);
    if (pickDate.year() === 0) {
      pickDate.set('year', moment().year());
    }
    return {
      inputDate: apiDateToDisplayDate(value),
      pickDate: pickDate.isValid() ? pickDate : undefined,
    };
  }, [value]);

  const handleInputChange = useCallback(
    e => {
      const { value } = e.target;
      setInputValue(value);
      onChange?.(formatPostDate(value));
    },
    [onChange],
  );

  const handleDatePickerChange = useCallback(
    value => {
      setInputValue(value?.format(DISPLAY_FORMAT));
      onChange?.(value?.format(API_RESPONSE_DATE_FORMAT));
    },
    [onChange],
  );

  const val = inputValue ?? inputDate ?? pickDate;

  return (
    <div className="flex-center core-picker">
      <Input
        allowClear
        value={val}
        title={val}
        placeholder={t('updateCv.birthdayPlaceholder')}
        {...inputProps}
        onKeyDown={e => {
          if (
            !e.ctrlKey &&
            !(SPECIAL_KEYS.includes(e.key) || /[\d/]/.test(e.key))
          ) {
            e.preventDefault();
          }
        }}
        onChange={handleInputChange}
      />
      <DatePicker
        placement="bottomRight"
        value={pickDate}
        format={DISPLAY_FORMAT}
        onChange={handleDatePickerChange}
        allowClear={false}
        hideDisabledOptions
        {...passedProps}
      />
    </div>
  );
}

export default memo(CorePicker);
