import i18next from 'i18next';

export const cols = [
  { type: 'id', sorter: undefined, align: 'center' },
  {
    title: i18next.t('criteria.name'),
    type: 'title',
    dataIndex: 'name',
  },
  {
    type: 'tooltip',
    dataIndex: 'description',
    sorter: undefined,
  },
  {
    type: 'status',
  },
  { type: 'action', width: 100 },
];

export const levelCriteriaRules = [
  {
    validator: async (_, names) => {
      if (!names || names.length < 2) {
        return Promise.reject(
          new Error(i18next.t('criteria.levelCriteriaMin')),
        );
      }
      if (names.length > 10) {
        return Promise.reject(
          new Error(i18next.t('criteria.levelCriteriaMax')),
        );
      }
    },
  },
];

export const levelItemCriteriaRules = [
  {
    required: true,
    whitespace: true,
    message: i18next.t('criteria.levelItemCriteriaRequired'),
  },
];
