import dayjs from 'dayjs';
import levelApi from '../../../api/levelApi';
import { LIST_REQUEST_STATUS } from '../../../constants/requestPage';
import { API_RESPONSE_DATE_FORMAT } from '../../../global/constants';
import {
  preHandlePostData,
  safelyParse,
} from '../../../utils/normalizeSendingData';

const REQUEST_DUPLICATE_KEYS = [
  'requestor_id',
  'position_id',
  'target',
  'typework_id',
  'priority',
  'description',
  'jd',
];
const ARRAY_PROPERTIES = ['levels', 'languages', 'criterias'];

export const handleAddRequestDeadline = (obj, deadline) => {
  obj.month = dayjs(deadline).month() + 1;
  obj.year = dayjs(deadline).year();
  obj.day = dayjs(deadline).date();
};

export const duplicateRequest = (originalObj, { deadline, requestor_id }) => {
  const duplicateData = preHandlePostData(originalObj, REQUEST_DUPLICATE_KEYS);
  ARRAY_PROPERTIES.forEach(key => {
    duplicateData[key] = safelyParse(originalObj[key]);
  });
  duplicateData.requestor_id = requestor_id;
  duplicateData.status = LIST_REQUEST_STATUS[0].value;
  handleAddRequestDeadline(duplicateData, deadline);
  return duplicateData;
};

export const handleRequestData = async (values, oldData) => {
  const requestData = preHandlePostData(values);
  if (Array.isArray(values.levels)) {
    const { data: levelData } = await levelApi.getLevel({
      id: values.levels.join('-'),
      limit: 0,
    });
    requestData.levels = Array.isArray(levelData.data)
      ? levelData.data.map(({ id, title }) => ({ id, title }))
      : [];
  }

  // add target and year month fields
  requestData.target = parseInt(values.target);
  if (!oldData) {
    handleAddRequestDeadline(requestData, values.deadline);
  } else {
    requestData.deadline = values.deadline.format(API_RESPONSE_DATE_FORMAT);
  }
  requestData.status = LIST_REQUEST_STATUS[0].value;
  return requestData;
};
