import { CheckOutlined, CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Form, message } from 'antd';
import qs from 'query-string';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import requestApi from '../../../api/requestApi';
import { LIST_REQUEST_STATUS } from '../../../constants/requestPage';
import { hasPermission } from '../../../utils/hasPermission';
import LayoutBreadcrumb from '../../../components/Breadcrumb/LayoutBreadcrumb';
import RequestForm from './RequestForm';
import useFetchDetailRequest from './useFetchDetailRequest';
export default function DetailRequest() {
  const { t } = useTranslation();
  const breadcrumbNameMap = {
    '/request': t('sidebar.request'),
    '/request/detail': t('request.titleDetailForm'),
  };
  const navigate = useNavigate();
  const location = useLocation();
  const { status } = qs.parse(location.search);

  const pathname = location.pathname.split('/').filter(x => x);
  const { detailRequest: requestFormInfo } = useFetchDetailRequest(pathname[2]);
  const [form] = Form.useForm();
  const { userInfor } = useSelector(state => state.auth);
  const handleChangeStatusRequest = async payload => {
    try {
      payload.target = requestFormInfo.target;
      await requestApi.edit(requestFormInfo.id, payload);
      message.success(t('request.titleChangeStatusSuccess'));
      navigate('/request');
    } catch (error) {
      if (!error.status) {
        message.error(t('request.lostConnect'));
      } else {
        message.error(t('request.titleChangeStatusFail'));
      }
      navigate('/request');
    }
  };
  const handleSaveRequest = async () => {
    try {
      await requestApi.edit(requestFormInfo.id, {
        jd: form.getFieldValue('jd'),
        id_criteria_group: form.getFieldValue('id_criteria_group'),
        criterias: form.getFieldValue('criterias'),
      });
      message.success(t('request.editRequestSuccess'));
      navigate('/request');
    } catch (error) {
      if (!error.status) {
        message.error(t('request.lostConnect'));
      } else {
        message.error(t('request.editRequestFail'));
      }
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="form_in_modal"
      className="request__form"
    >
      <LayoutBreadcrumb
        breadcrumbNameMap={breadcrumbNameMap}
        extra={[
          status === '0' ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                type="primary"
                disabled={!hasPermission(userInfor, 'request', 'decision')}
                onClick={() =>
                  handleChangeStatusRequest({
                    status: LIST_REQUEST_STATUS[2].value,
                  })
                }
              >
                <CheckOutlined />
                {t('request.titleApproveRequest')}
              </Button>
              <Button
                type="danger"
                className="ml-20"
                disabled={!hasPermission(userInfor, 'request', 'decision')}
                onClick={() =>
                  handleChangeStatusRequest({
                    status: LIST_REQUEST_STATUS[1].value,
                  })
                }
              >
                <CloseOutlined />
                {t('request.titleRejectRequest')}
              </Button>
            </div>
          ) : (
            <div className="btn-wrapper">
              <Button
                type="primary"
                onClick={handleSaveRequest}
                icon={<SaveOutlined />}
              >
                {t('request.btnSave')}
              </Button>
              <Button className="ml-20" onClick={() => navigate('/request')}>
                <CloseOutlined />
                {t('request.btnCancel')}
              </Button>
            </div>
          ),
        ]}
        component={
          <RequestForm titleForm={t('request.titleDetailForm')} form={form} />
        }
      />
    </Form>
  );
}
