import { Tooltip } from 'antd';
import React from 'react';
import { NoBorderButton } from '../../../Buttons';

function PrimaryTooltip({ title, icon, onClick }) {
  return (
    <Tooltip title={title}>
      <NoBorderButton onClick={onClick} icon={icon} />
    </Tooltip>
  );
}

export default PrimaryTooltip;
