import { SaveOutlined } from '@ant-design/icons';
import { Form, message, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FormEditor } from '../../../components/Form';
import FormInput from '../../../components/Form/FormInput';
import { CV_STATUS_DEFAULT_VALUE, CV_STEP } from '../../../constants';
import StepNotes from '../form-components/StepNotes';
import StepStatus from '../form-components/StepStatus';
import StepSubmitBtn from '../form-components/StepSubmitBtn';
import { checkFormHasValue } from '../utils';

const SwitchHeader = ({ formType, index, handleChangeForm, item }) => (
  <h4
    className={`switch-title ${formType === index && 'active'}`}
    onClick={() => {
      handleChangeForm && handleChangeForm(index);
    }}
  >
    {item}
  </h4>
);

export default function withStepFrame(WrappedComponent) {
  return function ParentComponent(props) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { id } = useParams();

    const [isFailed, setIsFailed] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [requiredFields, setRequiredFields] = useState([]);
    const [config, setConfig] = useState({});

    useEffect(() => {
      if (config.api) {
        const getData = async () => {
          try {
            const res = await config.api(id);
            const result = res.data.data;
            let filledObject = {};
            if (config.fillData) {
              filledObject = config.fillData(result);
            }
            const final = {
              status: result?.status ?? CV_STATUS_DEFAULT_VALUE.PENDING,
            };
            for (let key in result) {
              if (!filledObject.hasOwnProperty(key)) {
                final[key] = result[key];
              }
            }

            form.setFieldsValue({
              ...filledObject,
              ...final,
              status: result?.status ?? CV_STATUS_DEFAULT_VALUE.PENDING,
            });
            if (result?.status === CV_STATUS_DEFAULT_VALUE.FAILED) {
              setIsFailed(true);
            }
            if (config.fillData) {
              config.fillData(result);
            }
          } catch (e) {
            if (config.catchFetchData) {
              config.catchFetchData(e);
            }
            form.setFieldsValue({
              status: CV_STATUS_DEFAULT_VALUE.PENDING,
            });
            console.log('e :', e);
          }
        };

        const dataBefore = form.getFieldsValue();
        if (
          config?.fieldCheckBeforeGetData &&
          checkFormHasValue(dataBefore, config.fieldCheckBeforeGetData)
        ) {
          return;
        } else {
          getData();
        }
      }
      //React Hook useEffect has a missing dependency: 'config'
      //eslint-disable-next-line
    }, [config.api, id, form, props.reloadValue]);

    useEffect(() => {
      if (Object.keys(config).length > 0) {
        setRequiredFields(config.requiredFields);
      }
    }, [config]);

    const GeneralInput = ({ disabled = false, required, name, ...rest }) => {
      return (
        <FormInput
          {...rest}
          name={name}
          disabled={disabled || isFailed}
          required={requiredFields && requiredFields.includes(name)}
        />
      );
    };
    const Input = ({ ...rest }) => <GeneralInput {...rest} />;
    const TextArea = ({ ...rest }) => <GeneralInput {...rest} textArea />;
    const Notes = ({ ...rest }) => <StepNotes {...rest} form={form} />;
    const Status = ({ ...rest }) => (
      <StepStatus {...rest} setIsFailed={setIsFailed} form={form} />
    );

    const FormRichText = ({ name, disabled = false, ...rest }) => (
      <FormEditor
        {...rest}
        name={name}
        disabled={disabled || isFailed}
        required={requiredFields && requiredFields.includes(name)}
        form={form}
      />
    );

    const submitWithoutValidation = values => {
      let data = {
        notes: values.notes,
        status: 0,
      };
      if (config.submitFailedObject) {
        data = config.submitFailedObject(values);
      }
      finalFinish(data);
    };

    const onFinish = values => {
      if (config.submitObject) {
        const data = config.submitObject(values);
        finalFinish(data);
      }
    };

    const finalFinish = async data => {
      setSubmitLoading(true);
      try {
        for (let key in data) {
          if (data[key] && typeof data[key] === 'string')
            data[key] = data[key].trim();
        }
        if (config.submitApi) await config.submitApi({ cv_id: id, ...data });
        message.success(t('common.updateSuccessfully'));
        form.resetFields();
        setSubmitLoading(false);
        if (props.reload) props.reload();
      } catch (e) {
        setSubmitLoading(false);
        message.error(e?.message);
      }
    };

    const handleSubmitForm = () => {
      const fieldCheckSubmit = config?.fieldCheckSubmitFail || 'status';
      if (form.getFieldValue(fieldCheckSubmit) === 0) {
        submitWithoutValidation(form.getFieldsValue());
      } else form.submit();
    };

    const Frame = ({
      step = props.currentStep,
      children,
      title,
      handleChangeForm,
      formType,
      statusField,
    }) => {
      const getTitle = () => {
        if (title) {
          if (typeof title === 'string') {
            return <h4>{title}</h4>;
          } else {
            return (
              <Row>
                {title.map((item, index) => (
                  <React.Fragment key={index}>
                    {index ? (
                      <>
                        <span className="title-slash">|</span>
                        <SwitchHeader
                          formType={formType}
                          index={index}
                          handleChangeForm={handleChangeForm}
                          item={item}
                        />
                      </>
                    ) : (
                      <SwitchHeader
                        formType={formType}
                        index={index}
                        handleChangeForm={handleChangeForm}
                        item={item}
                      />
                    )}
                  </React.Fragment>
                ))}
              </Row>
            );
          }
        }
        if (!step) return '';
        const found = CV_STEP[step];
        return <h4>{t(`cv.${found.title}`)}</h4>;
      };
      return (
        <div className="step-frame">
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            className="standard-form"
          >
            <div className="step-header">
              {getTitle()}
              <StepSubmitBtn
                form={form}
                normalBtn
                icon={<SaveOutlined />}
                requiredFields={requiredFields}
                loading={submitLoading}
                onClick={handleSubmitForm}
                disabled={!props.isDecision}
                statusField={statusField}
                {...config.submitProps}
              />
            </div>
            <div
              className={`step-content ${submitLoading ? 'spin-wrapper' : ''}`}
            >
              {submitLoading ? <Spin /> : children}
            </div>
          </Form>
        </div>
      );
    };

    return (
      <WrappedComponent
        {...props}
        Frame={Frame}
        isFailed={isFailed}
        setIsFailed={setIsFailed}
        form={form}
        FormInput={Input}
        FormTextArea={TextArea}
        Notes={Notes}
        Status={Status}
        setRequiredFields={setRequiredFields}
        setConfig={setConfig}
        config={config}
        FormRichText={FormRichText}
      />
    );
  };
}
