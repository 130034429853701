import { COMPANY_URL } from '../constants/api';
import axiosClient from './axiosClient';

const companyApi = {
  getAll(params) {
    const url = COMPANY_URL;
    return axiosClient.get(url, { params });
  },
  getById(companyId) {
    const url = COMPANY_URL + `/${companyId}`;
    return axiosClient.get(url);
  },
  create(data) {
    const url = COMPANY_URL;
    return axiosClient.post(url, data);
  },
  edit(companyId, data) {
    const url = COMPANY_URL + `/${companyId}`;
    return axiosClient.put(url, data);
  },
  delete() {},
  deleteMany() {},
};
export default companyApi;
