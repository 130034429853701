import { DEFAULT_SUMMARY_COL } from '../components/Dashboard/common';
import i18n from '../translation/i18n';
import { calcPercent, displayPercent } from './formatData';

export function createSummary(summary, isCv, isExport, sources) {
  const summaryCol = { ...DEFAULT_SUMMARY_COL };
  if (summary) {
    for (const key in summaryCol) {
      if (
        Object.hasOwnProperty.call(summaryCol, key) &&
        Object.hasOwnProperty.call(summary, key)
      ) {
        summaryCol[key] = summary[key];
      }
    }
  }

  summaryCol.priority = '';
  summaryCol.ratio_offer_request = calcPercent(
    summaryCol.offer_cv,
    summaryCol.target,
  );
  summaryCol.ratio_onboard_request = displayPercent(
    summary.onboard_cv,
    summary.target,
  );
  summaryCol.ratio_onboard_offer = displayPercent(
    summary.onboard_cv,
    summary.offer_cv,
  );
  summaryCol.rest = `${summaryCol.target - summary.onboard_cv}` || 0;

  if (isCv) {
    delete summaryCol.priority;
    delete summaryCol.target;
    delete summaryCol.rest;
    delete summaryCol.ratio_offer_request;
    delete summaryCol.ratio_onboard_request;
  }
  if (isExport) {
    summaryCol['department_title'] = i18n.t('statistic.total');
    summaryCol['positions_title'] = '';
    summaryCol['levels'] = '';
    summaryCol['employees'] = '';
    return sources?.length ? [...sources, summaryCol] : [];
  }

  return summaryCol;
}
