import { InputNumber } from 'antd';
import React from 'react';

function TableCellWithoutMemo({ children, isHeading }) {
  const Tag = isHeading ? 'th' : 'td';
  return <Tag className="ant-table-cell">{children}</Tag>;
}

export const TableCell = React.memo(TableCellWithoutMemo);

const regexAllowNumber = /[\d.]/;

function RedemptionInputWithoutMemo({ value, onChange, ...props }) {
  return (
    <InputNumber
      className={value ? '' : 'ant-input-number-status-error'}
      min={0}
      maxLength={6}
      value={value}
      onChange={onChange}
      onKeyPress={e => {
        if (!regexAllowNumber.test(e.key)) {
          e.preventDefault();
        }
      }}
      {...props}
    />
  );
}

export const RedemptionInput = React.memo(RedemptionInputWithoutMemo);
