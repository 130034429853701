import i18n from '../translation/i18n';

export const createPositionColumns = (isExported = false) => {
  const cols = [
    {
      type: 'id',
      sorter: undefined,
    },
    {
      title: i18n.t('position.position'),
      dataIndex: 'title',
      type: 'title',
    },
    {
      title: i18n.t('position.department'),
      dataIndex: 'parent_title',
      type: 'allWithoutSort',
    },
    {
      title: i18n.t('position.description'),
      dataIndex: 'description',
      type: 'allWithoutSort',
    },
    {
      title: i18n.t('position.manager'),
      dataIndex: 'manager_id',
      type: 'allWithoutSort',
    },
    {
      title: i18n.t('position.requestor'),
      dataIndex: 'requestor',
      type: 'allWithoutSort',
      render: requestor => (requestor ? JSON.parse(requestor)?.join(',') : '-'),
    },
    {
      type: 'status',
    },
    {
      type: 'all',
      title: i18n.t('position.rank'),
      dataIndex: 'rank',
    },
    {
      type: 'action',
    },
  ];
  const exportCols = isExported
    ? isExported?.map((col, i) => {
        return { ...col, ...cols[i] };
      })
    : [];
  return isExported ? exportCols : cols;
};
