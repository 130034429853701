import { Layout } from 'antd';
import React, { useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import Header from '../components/Header/Header';
import Sidebar from '../components/Sidebar/Sidebar';
import { getToken } from '../api/Cookie';
import { skipLogin } from '../global/constants';

const { Content } = Layout;

function MainLayout(props) {
  const token = getToken('Auth-Token');
  const [collapsed, setCollapsed] = useState(false);

  const handleToggleCollapse = () => {
    setCollapsed(prev => !prev);
  };

  const redirect = () => {
    if (token || skipLogin) {
      return (
        <Layout>
          <Header />
          <Content>
            <Layout className="site-layout-background">
              <Sidebar
                collapsed={collapsed}
                handleToggleCollapse={handleToggleCollapse}
              />
              <Content className={`main__content ${collapsed && 'collapsed'}`}>
                <Outlet />
              </Content>
            </Layout>
          </Content>
        </Layout>
      );
    }
    return <Navigate to="/login" />;
  };
  return <>{redirect()}</>;
}

export default MainLayout;
