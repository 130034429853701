import { useCallback, useState } from 'react';
import { getHistorySearchRequest } from '../../../api/historySearchApi';

export default function useFetchHistoryData({ site }) {
  const [historyData, setHistoryData] = useState([]);
  const handleGetHistorySearch = useCallback(async () => {
    const data = await getHistorySearchRequest(site);
    setHistoryData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClearData = useCallback(async () => {
    setHistoryData([]);
  }, []);

  return { historyData, handleGetHistorySearch, handleClearData };
}
