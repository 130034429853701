import { HISTORY_SEARCH_API_URL } from '../constants/api';
import axiosClient from './axiosClient';

const getHistorySearchRequest = site => {
  const responseData = axiosClient
    .get(`${HISTORY_SEARCH_API_URL}${site}`)
    .then(res => res.data.data)
    .catch(err => {
      return [];
    });

  return responseData;
};
export { getHistorySearchRequest };
