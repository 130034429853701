import React from 'react';
import { useTranslation } from 'react-i18next';
import FormItem from '../FormItem';
import CorePicker from './CorePicker';

function InputDatePicker({
  name,
  label,
  rules = [],
  required,
  formItemProps = {},
  ...props
}) {
  const { t } = useTranslation();
  return (
    <FormItem
      name={name}
      label={label}
      rules={rules.concat({
        pattern: /[\d/]/,
        message: t('validator.invalidDate'),
      })}
      required={required}
      {...formItemProps}
    >
      <CorePicker {...props} />
    </FormItem>
  );
}

export { InputDatePicker };
