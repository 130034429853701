import { EditOutlined, RedditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openDrawer } from '../../../components/Drawer/slice/drawer';
import { DATE_FORMAT_FRONTEND_USE } from '../../../components/MsCalendar/constants';
import { enFullDaysLabels, priorityColors, priorityOrder } from '../constants';

export default function Detail({ selectedDate, data }) {
  const { t } = useTranslation();

  const items = data?.[selectedDate];

  const dispatch = useDispatch();

  const getHeader = () => {
    const day =
      enFullDaysLabels[
        moment(selectedDate, DATE_FORMAT_FRONTEND_USE).isoWeekday() - 1
      ];
    return `${day}, ${selectedDate}`;
  };

  const handleEdit = item => {
    dispatch(
      openDrawer({
        initial: item,
        modeText: {
          btn: t('common.edit'),
          title: t('reminder.editReminder'),
        },
      }),
    );
  };

  const getItems = () => {
    items.sort((item1, item2) => {
      const { priority: priority1, fullName: fullName1 } = item1?.hrtoolData;
      const { priority: priority2, fullName: fullName2 } = item2?.hrtoolData;

      if (priorityOrder[priority2] === priorityOrder[priority1]) {
        if (fullName1 === fullName2) return 0;
        if (fullName1 > fullName2) return 1;
        return -1;
      }

      return priorityOrder[priority2] - priorityOrder[priority1];
    });
    return items.map(item => {
      const { id } = item;
      const { priority, fullName, description, level, position, cvId } =
        item?.hrtoolData;
      return (
        <div
          className="reminder-detail-item"
          onDoubleClick={() => window.open('/cv/' + cvId, '_blank')}
          key={id}
        >
          <div
            className="reminder-detail-bar"
            style={{
              backgroundColor: priority ? priorityColors?.[priority] : '',
            }}
          ></div>
          <div className="reminder-detail-item-body">
            <div className="reminder-detail-position">
              <div>{position}</div>
              <div>{level}</div>
            </div>
            <div className="reminder-detail-description">
              <div className="reminder-detail-fullName">{fullName}</div>
              <div style={{ wordBreak: 'break-word' }}>{description}</div>
            </div>
            <Button
              icon={<EditOutlined />}
              type="text"
              style={{ alignSelf: 'flex-end' }}
              onClick={() => handleEdit(item)}
            ></Button>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="reminder-detail">
      <h2>{getHeader()}</h2>
      {!items && (
        <div className="reminder-detail-no-data">
          <RedditOutlined style={{ fontSize: 25 }} />
          <span>{t('common.noData')}</span>
        </div>
      )}
      {items && <div>{getItems()}</div>}
    </div>
  );
}
