import { Col, Row } from 'antd';
import { orderBy } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import BarChart from '../../../components/Chart/BarChart';
import RadarChart from '../../../components/Chart/RadarChart';
import { LABELSRADAR } from '../../../constants';
import ChartWithExport from './ChartWithExport';
import SmallTable from './SmallTable';

function Charts({ data, isCv }) {
  const { t } = useTranslation();
  const summary = data?.summary;
  const department = data?.department ?? {};
  const { labels, values } = department;

  const barChartProps = useMemo(() => {
    return {
      labels: summary?.labels?.split(','),
      data: [
        {
          label: t(
            `statistic.${isCv ? 'recruitment' : 'request'}`,
          ).capFirstLetter(),
          backgroundColor: isCv ? '#FFD166' : '#FF7D8F',
          data: (isCv ? summary?.list_cv_new : summary?.list_target)?.split(
            ',',
          ),
        },
        {
          label: t(`statistic.${isCv ? 'passed' : 'onboard'}`),
          backgroundColor: isCv ? '#06D6A0' : '#00B4D8',
          data: (isCv ? summary?.list_cv_pass : summary?.list_onboard)?.split(
            ',',
          ),
        },
      ],
    };
  }, [summary, t, isCv]);

  const radarChartData = useMemo(
    () => [
      [
        summary?.total_cv,
        summary?.interview_cv,
        summary?.pass_cv,
        summary?.onboard_cv,
      ],
    ],
    [summary],
  );

  const tableData = useMemo(() => {
    const hasData =
      Array.isArray(labels) &&
      labels.length &&
      Array.isArray(values) &&
      values.length;
    const data = [];

    if (hasData) {
      for (let i = 0; i < values.length && data.length < 5; i++) {
        const value = values[i];
        if (value) {
          data.push({
            label: labels[i],
            value: values[i],
          });
        }
      }
    }
    return orderBy(data, obj => +obj.value, 'desc');
  }, [labels, values]);

  const columns = useMemo(
    () => [
      {
        title: '#',
        width: '3rem',
        render: (_, _record, index) => index + 1,
      },
      {
        title: t(`statistic.${isCv ? 'position' : 'department'}`),
        dataIndex: 'label',
      },
      {
        title: t(`statistic.${isCv ? 'amount' : 'request'}`),
        dataIndex: 'value',
        width: '8rem',
      },
    ],
    [t, isCv],
  );

  return (
    <Row gutter={[32, 64]} align="stretch" style={{ marginBottom: 32 }}>
      <Col xs={24} sm={24} lg={12}>
        <ChartWithExport
          chartName={t(
            `statistic.chartTitle.${
              isCv ? 'recruitment/passed' : 'request_recruitment_success'
            }`,
          )}
          height={470 * 2 + 24}
        >
          <BarChart {...barChartProps} />
        </ChartWithExport>
      </Col>
      <Col xs={24} sm={24} lg={12}>
        <Row gutter={32} style={{ rowGap: 24 }}>
          <Col xs={24} sm={24} lg={24}>
            <ChartWithExport
              chartName={t('statistic.chartTitle.recruitment_rate')}
            >
              <RadarChart labels={LABELSRADAR} data={radarChartData} />
            </ChartWithExport>
          </Col>
          <Col xs={24} sm={24} lg={24}>
            <SmallTable
              columns={columns}
              data={tableData}
              labels={labels}
              values={values}
              title={t(
                `statistic.chartTitle.${
                  isCv
                    ? 'recruited_personnel_ranking'
                    : 'required_number_personnel_ranking'
                }`,
              )}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Charts;
