import { CRITERIA_CV_URL } from '../constants/api';
import axiosClient from './axiosClient';

const criteriaCvData = {
  getById(id) {
    const url = CRITERIA_CV_URL + `/${id}`;
    return axiosClient.get(url);
  },
};
export default criteriaCvData;
