import i18n from '../translation/i18n';

export const DEFAULT_SELECTED_MENU_SIDEBAR = '/statistic';
export const titleConfirmRemove = (quantity = '') => (
  <div className="language__multipleDelete--title">
    <span>{i18n.t('language.areYouSureRemove')}</span>
    <span className="error-color">
      {i18n.t('language.totalItems', { quantity })}
    </span>
    <span>{i18n.t('language.areThis?')}</span>
  </div>
);
