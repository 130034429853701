import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React from 'react';

function DashboardCard({ info, desc, subDesc, titleClassName = '' }) {
  return (
    <div className="card">
      <div className="card__content">
        <div className={titleClassName}>
          <p>{desc}</p>
          <p className="text-center">{`(${subDesc})`}</p>
        </div>
        <Tooltip
          placement="topLeft"
          title={
            <>
              {desc} : {info}
            </>
          }
        >
          <Button type="text" style={{ padding: 0 }}>
            <InfoCircleOutlined />
          </Button>
        </Tooltip>
      </div>
      <div className="card__number">
        <h4 className="total">{info ? info : 0}</h4>
      </div>
    </div>
  );
}

export default DashboardCard;
