import {
  CopyOutlined,
  ExclamationCircleOutlined,
  PauseOutlined,
  RollbackOutlined,
  StopOutlined,
} from '@ant-design/icons';
import { Button, message, Modal } from 'antd';
import 'moment/locale/vi';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import requestApi from '../../../../../api/requestApi';
import { DetailBtn } from '../../../../../components/Buttons';
import { openDrawer } from '../../../../../components/Drawer/slice/drawer';
import { TableMain } from '../../../../../components/Table';
import {
  createRequestColumns,
  LIST_REQUEST_STATUS,
} from '../../../../../constants/requestPage';
import { hasPermission } from '../../../../../utils/hasPermission';
import SearchForm from '../../SearchForm';
import DuplicateForm from './DuplicateForm';

const checkEditable = record =>
  record.status === LIST_REQUEST_STATUS[0].value ||
  record.status === LIST_REQUEST_STATUS[1].value ||
  record.status === LIST_REQUEST_STATUS[4].value;

function ContentRequestTable({
  filter,
  setFilter,
  items,
  loadingTable,
  fetchData,
  totalRecord,
}) {
  const { t } = useTranslation();
  const userInfor = useSelector(state => state.auth.userInfor);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleRequestDetail = async record => {
    navigate(`detail/${record.id}?status=${record.status}`);
  };

  const Filter = () => <SearchForm filter={filter} setFilter={setFilter} />;

  const cols = createRequestColumns();

  const handleUpdateStatusRequest = async (id, status) => {
    try {
      await requestApi.edit(id, { status: status.value });
      fetchData();
      message.success(
        t('request.updateStatusSuccess', {
          statusRequest: status.textEditInform || status.text,
        }),
      );
    } catch (e) {
      message.error(
        t('request.updateStatusFailure', {
          statusRequest: status.textEditInform || status.text,
        }),
      );
    }
  };

  const confirm = (record, status) => {
    Modal.confirm({
      title: t('request.confirmRequest', {
        statusRequest: status.textEditInform
          ? status.textEditInform.toLowerCase()
          : status.text.toLowerCase(),
      }),
      icon: <ExclamationCircleOutlined />,
      content: `${record.id} - ${record.department_title} - ${record.position_title}`,
      okText: t('common.ok'),
      cancelText: t('common.cancel'),
      getContainer: node =>
        node?.parentNode ?? document.querySelector('.table-fullscreen'),
      onOk: () => handleUpdateStatusRequest(record.id, status),
    });
  };

  const actionBtns = [
    {
      permission: record =>
        Number(record.status) !== LIST_REQUEST_STATUS[1].value &&
        Number(record.status) !== LIST_REQUEST_STATUS[4].value,
      component: record => {
        return <DetailBtn onClick={() => handleRequestDetail(record)} />;
      },
    },
    {
      permission: record =>
        !record.isauto &&
        hasPermission(userInfor, 'request', 'add') &&
        !checkEditable(record),
      component: record => {
        return (
          <Button
            icon={<CopyOutlined />}
            onClick={() => {
              dispatch(
                openDrawer({
                  initial: record,
                  modeText: {
                    btn: t('request.duplicate'),
                    title: t('request.duplicate'),
                  },
                }),
              );
            }}
          >
            {t('request.duplicate')}
          </Button>
        );
      },
    },
    'edit',
    'delete',
    {
      permission: record =>
        !record.isauto &&
        hasPermission(userInfor, 'request', 'edit') &&
        record.status === LIST_REQUEST_STATUS[2].value,
      component: record => {
        return (
          <Button
            icon={<PauseOutlined />}
            onClick={() => confirm(record, LIST_REQUEST_STATUS[4])}
          >
            {t('common.pause')}
          </Button>
        );
      },
    },
    {
      permission: record =>
        !record.isauto &&
        hasPermission(userInfor, 'request', 'edit') &&
        record.status === LIST_REQUEST_STATUS[2].value,
      component: record => {
        return (
          <Button
            icon={<StopOutlined />}
            onClick={() => confirm(record, LIST_REQUEST_STATUS[3])}
          >
            {t('common.stop')}
          </Button>
        );
      },
    },
    {
      permission: record =>
        !record.isauto &&
        hasPermission(userInfor, 'request', 'edit') &&
        record.status === LIST_REQUEST_STATUS[4].value,
      component: record => {
        return (
          <Button
            icon={<RollbackOutlined />}
            onClick={() => confirm(record, LIST_REQUEST_STATUS[2])}
          >
            {t('common.rerun')}
          </Button>
        );
      },
    },
  ];
  const disableCheckbox = record => ({
    disabled: record.status !== 0,
  });
  const handleClickRow = record => ({
    onClick() {
      if (
        record.id &&
        (record.status === LIST_REQUEST_STATUS[1].value ||
          record.status === LIST_REQUEST_STATUS[4].value)
      ) {
        navigate(`edit/${record.id}`);
      } else {
        navigate(`detail/${record.id}?status=${record.status}`);
      }
    },
  });

  return (
    <>
      <TableMain
        disableCheckbox={disableCheckbox}
        cols={cols}
        nth
        callback={createRequestColumns}
        titleLabel={t('request.request')}
        items={items}
        title={t('request.request')}
        fetchData={fetchData}
        deleteApi={requestApi.delete}
        getApi={requestApi.getAll}
        deleteManyApi={requestApi.multiDelete}
        deletePermission={
          hasPermission(userInfor, 'request', 'delete') &&
          (record => record.status !== LIST_REQUEST_STATUS[2].value)
        }
        editPermission={
          hasPermission(userInfor, 'request', 'edit') && checkEditable
        }
        editNavigate="edit/:id"
        actionButtons={actionBtns}
        filter={filter}
        Filter={Filter}
        setFilter={setFilter}
        totalRecord={totalRecord}
        loadingTable={loadingTable}
        excelName="request-templates"
        onRow={handleClickRow}
      />
      <DuplicateForm fetchData={fetchData} />
    </>
  );
}

export default memo(ContentRequestTable);
