import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { InputDatePicker } from '../../../components/Form';
import { OVER_YEAR } from '../../../constants';

const disabledDate = date => {
  return date > moment().subtract(OVER_YEAR, 'years');
};

function BirthdayPicker() {
  const { t } = useTranslation();

  return (
    <InputDatePicker
      name="birthday"
      label={t('updateCv.birthday')}
      disabledDate={disabledDate}
      defaultPickerValue={moment().subtract(OVER_YEAR, 'years')}
      showToday={false}
    />
  );
}

export default BirthdayPicker;
