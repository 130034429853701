import { Form } from 'antd';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import requestApi from '../../../../../api/requestApi';
import GeneralDrawer from '../../../../../components/Drawer/GeneralDrawer';
import { duplicateRequest } from '../../handleRequestData';
import DeadlinePicker from '../../RequestForm/DeadlinePicker';
import { useFetchDeadlineDays } from '../../RequestForm/hooks';
import RequestorSelect from '../../RequestForm/RequestorSelect';

function DuplicateForm({ fetchData }) {
  const [form] = Form.useForm();
  const { initial } = useSelector(state => state.drawer);
  const { deadlineDays } = useFetchDeadlineDays();

  const fillData = useCallback(() => {
    form.setFieldsValue({
      ...initial,
      deadline: undefined,
    });
  }, [initial, form]);

  return (
    <GeneralDrawer
      onFinish={async values => {
        const newValues = duplicateRequest(initial, values);
        return await requestApi.create(newValues);
      }}
      fetchData={fetchData}
      height={250}
      form={form}
      fillData={fillData}
    >
      <RequestorSelect />
      <DeadlinePicker deadlineDays={deadlineDays} />
    </GeneralDrawer>
  );
}

export default DuplicateForm;
