import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import NoPermission from '../../../components/NoPermission';
import { Header, TableMain, useTable } from '../../../components/Table';
import CriteriaGroupFilter from './components/CriteriaGroupFilter';
import CriteriaGroupForm from './components/CriteriaGroupForm';
import { cols } from './constants';
import { openDrawer } from '../../../components/Drawer/slice/drawer';
import criteriaGroupApi from '../../../api/criteriaGroupApi';
import { message } from 'antd';
import { useCallback } from 'react';
import CriteriaSetFilter from '../CriteriaSet/components/CriteriaSetFilter';

function CriteriaGroup({ permission }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { items, filter, setFilter, loadingTable, fetchData, totalRecord } =
    useTable({
      getApi: criteriaGroupApi.getAll,
    });

  const title = t('sidebar.criteriaGroup');

  const getDetail = useCallback(async record => {
    await criteriaGroupApi
      .getById(record?.id)
      .then(res => {
        const titleLower = title.toLowerCase();
        dispatch(
          openDrawer({
            initial: res?.data?.data,
            modeText: {
              btn: t('common.edit'),
              title: `${t('common.edit')} ${titleLower}`,
            },
          }),
        );
      })
      .catch(err => {
        message.error(t('common.networkError'));
        return {};
      });
  }, []);

  const handleClickRow = useCallback(
    record => ({
      onClick() {
        getDetail(record);
      },
    }),
    [],
  );

  return (
    <>
      <CriteriaGroupForm
        addApi={criteriaGroupApi.create}
        editApi={criteriaGroupApi.edit}
        deleteElementApi={criteriaGroupApi.deleteElement}
        fetchData={fetchData}
        setFilter={setFilter}
      />
      <Header title={title} addPermission={permission.add} />
      <CriteriaSetFilter filter={filter} setFilter={setFilter} />
      <TableMain
        cols={cols}
        nth
        titleLabel={title}
        items={items}
        title={title}
        fetchData={fetchData}
        deleteApi={criteriaGroupApi.delete}
        getApi={criteriaGroupApi.getAll}
        deleteManyApi={criteriaGroupApi.deleteMany}
        deletePermission={permission.delete}
        editPermission={permission.edit}
        filter={filter}
        handleEdit={getDetail}
        Filter={CriteriaSetFilter}
        setFilter={setFilter}
        totalRecord={totalRecord}
        loadingTable={loadingTable}
        excelName="criteria-set-list"
        onRow={handleClickRow}
      />
    </>
  );
}

export default function CriteriaGroupIndex(props) {
  const permission = useSelector(
    state => state.auth.userInfor.permission.criteria_group,
  );

  return permission.view ? (
    <CriteriaGroup {...props} permission={permission} />
  ) : (
    <NoPermission />
  );
}
