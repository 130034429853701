import React from 'react';
import { GeneralSelect } from '../';
import companyApi from '../../../api/companyApi';

export default function CompanySelect(props) {
  return (
    <GeneralSelect
      {...props}
      valueKey="id"
      contentKey="name"
      api={companyApi.getAll}
    />
  );
}
