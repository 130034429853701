import { Col, Form } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormInput, UserSelect } from '../../../../components/Form';
import DepartmentSelect from '../../../../components/Form/Selects/DepartmentSelect';
import { Filter } from '../../../../components/Table';

function PositionFilter(props) {
  const { isDepartment } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation();

  return (
    <Filter {...props} form={form}>
      {isDepartment ? (
        ''
      ) : (
        <>
          <Col sm={8} xxl={4}>
            <DepartmentSelect
              label={t('position.department')}
              mode="multiple"
              name="parent_id"
            />
          </Col>
          <Col sm={8} xxl={4}>
            <UserSelect
              label={t('position.manager')}
              name="manager_id"
              mode="multiple"
            />
          </Col>
          <Col sm={8} xxl={4}>
            <UserSelect
              label={t('position.requestor')}
              name="requestor"
              mode="multiple"
            />
          </Col>
        </>
      )}
      <Col sm={isDepartment ? 9 : 10} xxl={6}>
        <FormInput
          name="key"
          label={t('position.key')}
          placeholder={t('position.placeholderInput')}
        />
      </Col>
    </Filter>
  );
}

export default React.memo(PositionFilter);
