import { useEffect, useState } from 'react';
import { GET_FULL_LIST_PARAMS } from '../../../constants';

export default function useFetchList({ api }) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchList = async () => {
      try {
        const response = await api(GET_FULL_LIST_PARAMS);
        const data = response.data.data;
        setItems(data);
      } catch (e) {
        console.log('e :', e);
      }
    };
    fetchList();
    //React Hook useEffect has a missing dependency: 'api'
    //eslint-disable-next-line
  }, []);

  return { items, setItems };
}
