import { SettingOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useFetchRelease } from '../../../components/Hooks/FetchApi';
import { DISPLAY_FORMAT } from '../../../global/constants';
import LayoutBreadcrumb from '../../../components/Breadcrumb/LayoutBreadcrumb';
import { breadcrumbNameMap } from './constant';

const View = () => {
  const { items } = useFetchRelease();
  return (
    <Row className="release-view" justify="center">
      <Col sm={20} xl={16} xxl={12} className="release-content-wrapper">
        {items?.map(version => (
          <div className="version" key={version?.version}>
            <span className="number">{version?.version}</span>
            {version.daterelease && (
              <span className="release-date">{`Ngày: ${moment(
                version.daterelease,
              ).format(DISPLAY_FORMAT)}`}</span>
            )}
            <pre className="content">{version?.conent}</pre>
          </div>
        ))}
      </Col>
    </Row>
  );
};
export default function ReleaseView() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <LayoutBreadcrumb
      breadcrumbNameMap={breadcrumbNameMap}
      extra={[
        <Button
          type="primary"
          icon={<SettingOutlined />}
          onClick={() => navigate('/setting/release/config')}
          className="uppercase"
        >
          {t('common.config')}
        </Button>,
      ]}
      component={<View />}
    />
  );
}
