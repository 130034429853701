import { Col, Form } from 'antd';
import moment from 'moment';
import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  CvStatusSelect,
  CvStepSelect,
  LevelSelect,
  PositionSelect,
  RangePicker,
  SourceSelect,
  UserSelect,
} from '../../../components/Form';
import SearchWithSuggestions from '../../../components/Form/SearchWithSuggestions';
import { useFetchUser } from '../../../components/Hooks/FetchApi';
import { Filter as CommonFilter } from '../../../components/Table';
import { hasPermission } from '../../../utils/hasPermission';
import { getHistorySearchRequest } from '../../../api/historySearchApi';

function Filter({ ...props }) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { userInfor } = useSelector(state => state.auth);
  const { items: users } = useFetchUser();
  const [historyData, setHistoryData] = useState([]);

  const handleSearch = values => {
    const dateRange = values.daterange;
    if (dateRange) {
      values.daterange = `${dateRange[0].startOf('day').unix()}-${dateRange[1]
        .endOf('day')
        .unix()}`;
    }
    props.setFilter(prev => ({ ...prev, ...values }));
  };

  const convertDateRangeFromFilter = filter => {
    const dateRange = filter.daterange;
    if (dateRange) {
      let arr = dateRange.split('-');
      arr = arr.map(value => moment.unix(value));
      return { daterange: arr };
    }
    return { daterange: undefined };
  };

  const handleGetHistorySearch = async () => {
    const data = await getHistorySearchRequest('managercv');
    setHistoryData(data);
  };

  const renderItem = title => ({
    value: title,
    label: <span>{title}</span>,
  });

  return (
    <CommonFilter
      {...props}
      form={form}
      submit={handleSearch}
      initialValues={convertDateRangeFromFilter}
    >
      {/* Date Range */}
      <Col sm={8} lg={6} xl={4}>
        <RangePicker name="daterange" label={t('cv.dateCreateTitle')} />
      </Col>
      {/* Step */}
      <Col sm={8} lg={6} xl={4}>
        <CvStepSelect label={t('cv.step')} name="step" mode="multiple" index />
      </Col>
      {/* Status */}
      <Col sm={8} lg={6} xl={4}>
        <CvStatusSelect label={t('cv.status')} name="status" mode="multiple" />
      </Col>
      {/* Progress */}
      {hasPermission(userInfor, 'users', 'view') && (
        <Col sm={8} lg={6} xl={4}>
          <UserSelect
            name="progress"
            label={t('cv.progress')}
            mode="multiple"
            fetchedItems={users}
            storageKey="cv_progress"
          />
        </Col>
      )}
      {/* Position */}
      {hasPermission(userInfor, 'positions', 'view') && (
        <Col sm={8} lg={6} xl={4}>
          <PositionSelect
            label={t('request.position')}
            name="position_id"
            mode="multiple"
            storageKey="cv_positions"
          />
        </Col>
      )}
      {/* Level */}
      {hasPermission(userInfor, 'level', 'view') && (
        <Col sm={8} lg={6} xl={4}>
          <LevelSelect name="level_id" mode="multiple" storageKey="cv_levels" />
        </Col>
      )}
      {/* Assignee */}
      {hasPermission(userInfor, 'users', 'view') && (
        <Col sm={8} lg={6} xl={4}>
          <UserSelect
            name="assignee_id"
            label={t('common.assignee')}
            mode="multiple"
            fetchedItems={users}
            storageKey="cv_assignee"
          />
        </Col>
      )}
      {/* Source */}
      {hasPermission(userInfor, 'source', 'view') && (
        <Col sm={8} lg={6} xl={4}>
          <SourceSelect
            name="source_id"
            mode="multiple"
            label={t('cv.source')}
            storageKey="cv_sources"
          />
        </Col>
      )}
      {/* Keyword */}
      <Col sm={8} lg={6} xl={4}>
        <SearchWithSuggestions
          label={t('common.keyword')}
          placeholder={t('common.keywordPlaceholder')}
          name="keyword"
          history={historyData}
          site="managercv"
          onFocus={handleGetHistorySearch}
          renderItem={renderItem}
        />
      </Col>
    </CommonFilter>
  );
}

export default memo(Filter);
