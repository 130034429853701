import React from 'react';
import { Button as AntdButton } from 'antd';
import './styles.scss';
import {
  CloseOutlined,
  EditOutlined,
  InfoCircleOutlined,
  PlusCircleFilled,
  SaveOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const colorsClassName = {
  white: 'custom-button--white',
  danger: 'custom-button--danger',
  blue: 'custom-button--blue',
};

const definedIcons = {
  detail: <InfoCircleOutlined />,
  add: <PlusCircleFilled />,
  edit: <EditOutlined />,
  save: <SaveOutlined />,
  cancel: <CloseOutlined />,
};

export function Button({
  color,
  icon,
  children,
  className,
  stopPropagation,
  ...rest
}) {
  const colorClass =
    color && colorsClassName?.[color] ? colorsClassName?.[color] : '';

  if (icon && typeof icon === 'string' && definedIcons[icon]) {
    icon = definedIcons[icon];
  }

  return (
    <AntdButton
      {...rest}
      className={`custom-button ${colorClass} ${className}`}
      icon={icon}
      onClick={e => {
        if (stopPropagation) {
          e.stopPropagation();
        }
        if (rest.onClick) {
          rest.onClick();
        }
      }}
    >
      {children}
    </AntdButton>
  );
}

const AddBlueButton = props => <Button {...props} type="primary" icon="add" />;

const EditBlueButton = props => (
  <Button {...props} type="primary" icon="edit" />
);

const SaveBlueButton = props => (
  <Button {...props} type="primary" icon="save" />
);

const CancelWhiteButton = props => (
  <Button {...props} type="ghost" icon="cancel" />
);

const NoBorderButton = props => <Button {...props} type="text" />;

function DetailBtn(props) {
  const { t } = useTranslation();
  return (
    <Button icon="detail" {...props}>
      {t('common.detail')}
    </Button>
  );
}

export {
  AddBlueButton,
  EditBlueButton,
  SaveBlueButton,
  CancelWhiteButton,
  NoBorderButton,
  DetailBtn,
};
