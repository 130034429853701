import { Form, message } from 'antd';
import { useSelector } from 'react-redux';
import GeneralDrawer from '../../../../../components/Drawer/GeneralDrawer';
import { DEFAULT_STATUS } from '../../../../../constants';
import { fieldsCreator } from '../../../../../constants/basicTable';
import { renderFields } from '../../../../../utils/formHandle';
import { useMemo } from 'react';
import { handleGetCommonModalWith } from '../constants';
import useCurrentBreakPoint from '../../../../../hooks/useCurrentBreakPoint';

export default function ModalForm({ i18n, fields, requiredFields, ...props }) {
  const [form] = Form.useForm();

  const { initial, mode } = useSelector(state => state.drawer);
  const { currentBreakpoint } = useCurrentBreakPoint();

  const fillData = () => {
    form.resetFields();
    const init = mode === 'add' ? {} : initial;
    form.setFieldsValue({
      ...init,
      status: initial.status ?? DEFAULT_STATUS,
    });
  };

  const catchCallback = e => {
    if (e?.data?.message?.includes('exists')) {
      message.error(i18n.itemExists);
    }
  };

  const modalWidth = useMemo(() => {
    const modalWidth = handleGetCommonModalWith(currentBreakpoint);
    return modalWidth;
  }, [currentBreakpoint]);

  return (
    <GeneralDrawer
      {...props}
      fillData={fillData}
      form={form}
      modal
      catchCallback={catchCallback}
      fullscreenClassName="table-fullscreen"
      requiredFields={requiredFields || ['title']}
      width={modalWidth}
    >
      {renderFields(
        Array.isArray(fields) && fields.length ? fields : fieldsCreator(i18n),
      )}
    </GeneralDrawer>
  );
}
