import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb, PageHeader } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { CAREERS_LINK } from '../../constants';
import useCurrentBreakPoint from '../../hooks/useCurrentBreakPoint';

const BreadCrumb = ({ breadcrumbNameMap, extra }) => {
  const location = useLocation();
  const path = location.pathname.split('/').filter(i => i);
  const { currentBreakpoint } = useCurrentBreakPoint();

  const extraBreadcrumbItems = (
    <>
      <Breadcrumb.Item className="breadcrumb-item">
        <a
          href={CAREERS_LINK}
          className="breadcrumb-item-link"
          target="_blank"
          rel="noreferrer"
        >
          <HomeOutlined className="breadcrumb-item-icon" />
        </a>
      </Breadcrumb.Item>
      {path.map((_, index) => {
        const url = `/${path.slice(0, index + 1).join('/')}`;
        return (
          breadcrumbNameMap[url] && (
            <Breadcrumb.Item key={url} className="breadcrumb-item">
              <Link
                className="breadcrumb-item-link"
                to={
                  breadcrumbNameMap.params
                    ? `${url}${breadcrumbNameMap.params}`
                    : url
                }
                onClick={e => {
                  if (path.length === index + 1) {
                    e.preventDefault();
                  }
                }}
              >
                {breadcrumbNameMap[url]}
              </Link>
            </Breadcrumb.Item>
          )
        );
      })}
    </>
  );

  return (
    <PageHeader
      className="breadcrumb-header"
      title={<Breadcrumb>{extraBreadcrumbItems}</Breadcrumb>}
      subTitle=""
      style={{
        marginBottom: ['sm', 'md'].includes(currentBreakpoint) ? '32px' : 0,
      }}
      extra={[
        <div className="flex-center" key={1}>
          {extra &&
            extra.map((x, i) => {
              return (
                <div key={i} className={x ? 'breadcrumb-extra-item' : ''}>
                  {x}
                </div>
              );
            })}
        </div>,
      ]}
    />
  );
};

export default BreadCrumb;
