import {
  CalendarOutlined,
  HomeOutlined,
  MailOutlined,
  MobileOutlined,
  SaveOutlined,
  SaveTwoTone,
  StarFilled,
} from '@ant-design/icons';
import { Breadcrumb, Col, Form, Row, Tabs, Tooltip } from 'antd';
import moment from 'moment-timezone';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import levelApi from '../../../api/levelApi';
import { positionApi } from '../../../api/positionApi';
import sourceApi from '../../../api/sourceApi';
import departmentImg from '../../../assets/images/cvManagement/department.svg';
import positionImg from '../../../assets/images/cvManagement/position.svg';
import {
  Button,
  EditBlueButton,
  NoBorderButton,
} from '../../../components/Buttons';
import { useFetchUser, useGetDetail } from '../../../components/Hooks/FetchApi';
import { GENDER } from '../../../constants/cvDetail';
import { DISPLAY_FORMAT } from '../../../global/constants';
import { getStep, updateFavoriteCv } from '../constant';
import { preProcessCv } from '../constant/cvInfo';
import AvatarShowWithZoom from './AvatarShowWithZoom';
import DrawerInfo from './DrawerInfo';
import criteriaGroupApi from '../../../api/criteriaGroupApi';
import { GeneralSelect } from '../../../components/Form';
import criteriaCvData from '../../../api/criteriaCvData';
import '../scss/cvManagement.scss';
import CvInfoCriteria from './CvInfoCriteria';
export default function CvInfo({
  cv,
  setCurrentStep,
  totalHistoryEmail,
  fetchCv,
}) {
  const { t } = useTranslation();
  const { id } = useParams();
  const permission = useSelector(state => state.auth.userInfor.permission.cv);
  const navigate = useNavigate();

  const { step, status } = cv;
  const detailStep = getStep(step, status);
  const { stepValue } = detailStep || {};
  const [isShowMore, setIsShowMore] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const cvInfoRightRef = useRef(null);
  const avatarImageRef = useRef(null);
  const lensRef = useRef(null);
  const avatarZoomResultRef = useRef(null);

  // Begin: get detail information

  const { detail: position } = useGetDetail({
    api: positionApi.getPositionById,
    value: cv.position_id,
  });

  const { detail: department } = useGetDetail({
    api: positionApi.getPositionById,
    value: position.parent_id,
  });

  const { detail: level } = useGetDetail({
    api: levelApi.getLevelById,
    value: cv.level_id,
  });

  const { detail: lastLevel } = useGetDetail({
    api: levelApi.getLevelById,
    value: cv.last_level_id,
  });

  const { detail: source } = useGetDetail({
    api: sourceApi.getById,
    value: cv.source_id,
  });

  const { detail: criteriaData } = useGetDetail({
    api: criteriaCvData.getById,
    value: cv.id,
  });

  const criteriaRefactorData = Object.values(criteriaData || {})
    ?.slice(0, -1)
    .map(item => [
      item.criteria_name,
      item.range_before,
      (item.range_after = item.range_before),
      item.review,
    ]);
  const { items: users } = useFetchUser();

  const getUser = username => {
    if (!username) return null;
    const user = users.find(item => item.username === username);
    if (user) return `${user.fullname} (${username})`;
    return username;
  };

  const reviewer = getUser(cv.reviewer_id);
  const interviewer = getUser(cv.interviewer_id);
  const assignee = getUser(cv.assignee_id);
  const socialLink = cv?.social_link;
  const expectedOnboardDate = cv?.more_select?.onboard;
  const salaryWant = cv?.more_select?.salary_want;
  const salarySuggested = cv?.more_select?.salary_suggested;
  const hrInterviewNote = cv?.hr_notes;
  const techInterviewNote = cv?.tech_notes;

  // End: get detail information

  const cvAfter = useMemo(() => preProcessCv(cv), [cv]);

  const InfoLine = useCallback(({ condition = true, label, content }) => {
    return (
      condition && (
        <div className="cv-info-line">
          <p>{label}</p>
          <div>{content}</div>
        </div>
      )
    );
  }, []);

  const handleOpenLastStep = () => {
    setCurrentStep(stepValue);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleToggleShowMoreContent = () => {
    setIsShowMore(!isShowMore);
  };

  useEffect(() => {
    if (
      avatarImageRef &&
      avatarZoomResultRef &&
      lensRef &&
      cvAfter?.images?.length
    ) {
      function imageZoom() {
        let img, lens, result, cx, cy;
        img = avatarImageRef.current;
        result = avatarZoomResultRef.current;
        lens = lensRef.current;
        result.style.height = `${result.offsetWidth}px`;

        /*calculate the ratio between result DIV and lens:*/
        cx = result.offsetWidth / lens.offsetWidth;
        cy = result.offsetHeight / lens.offsetHeight;
        /*set background properties for the result DIV:*/
        result.style.backgroundImage = "url('" + img.src + "')";
        result.style.backgroundSize =
          img.width * cx + 'px ' + img.height * cy + 'px';
        /*execute a function when someone moves the cursor over the image, or the lens:*/
        img.addEventListener('mouseover', hoverImage);
        lens.addEventListener('mouseover', hoverImage);

        img.addEventListener('mouseleave', hoverOutImage);
        lens.addEventListener('mouseleave', hoverOutImage);

        lens.addEventListener('mousemove', moveLens);
        img.addEventListener('mousemove', moveLens);

        /*and also for touch screens:*/
        lens.addEventListener('touchmove', moveLens);
        img.addEventListener('touchmove', moveLens);

        function hoverImage() {
          result.style.zIndex = 3;
          result.style.opacity = 1;
          // result.style.transform = 'translateX(0)';
        }

        function hoverOutImage() {
          result.style.zIndex = -1;
          result.style.opacity = 0;
          // result.style.transform = 'translateX(-150px)';
        }
        function moveLens(e) {
          let pos, x, y;
          /*prevent any other actions that may occur when moving over the image:*/
          e.preventDefault();
          /*get the cursor's x and y positions:*/
          pos = getCursorPos(e);
          /*calculate the position of the lens:*/
          x = pos.x - lens.offsetWidth / 2;
          y = pos.y - lens.offsetHeight / 2;
          /*prevent the lens from being positioned outside the image:*/

          if (x > img.width - lens.offsetWidth) {
            x = img.width - lens.offsetWidth;
          }
          if (x < 0) {
            x = 0;
          }
          if (y > img.height - lens.offsetHeight) {
            y = img.height - lens.offsetHeight;
          }
          if (y < 0) {
            y = 0;
          }
          /*set the position of the lens:*/
          lens.style.left = x + 'px';
          lens.style.top = y + 'px';
          /*display what the lens "sees":*/
          result.style.backgroundPosition =
            '-' + x * cx + 'px -' + y * cy + 'px';
        }
        function getCursorPos(e) {
          let a,
            x = 0,
            y = 0;
          e = e || window.event;
          /*get the x and y positions of the image:*/
          a = img.getBoundingClientRect();
          /*calculate the cursor's x and y coordinates, relative to the image:*/
          x = e.pageX - a.left;
          y = e.pageY - a.top;
          /*consider any page scrolling:*/
          x = x - window.pageXOffset;
          y = y - window.pageYOffset;
          return { x: x, y: y };
        }
      }
      imageZoom();
    }
  }, [avatarImageRef, avatarZoomResultRef, lensRef, avatar, cvAfter?.images]);

  useEffect(() => {
    form.setFieldsValue({
      id_criteria_group: criteriaData?.criteria_group,
      criterias: criteriaRefactorData,
    });
  }, [criteriaData?.criteria_group]);
  const [form] = Form.useForm();

  return (
    <Row>
      <Col sm={24} lg={10} xl={24} xxl={10} className="cv-left">
        <Row className="cv-avatar-wrapper" justify="center" gutter={[0, 12]}>
          <Col sm={6} lg={16} xl={10} xxl={14}>
            <div className="cv-avatar">
              <AvatarShowWithZoom
                avatarList={cvAfter.images}
                onlyView
                ref={{ avatarRef: avatarImageRef, lensRef: lensRef }}
                avatar={avatar}
                setAvatar={setAvatar}
              />
            </div>
          </Col>
          <Col sm={24}>
            <Row justify="center">
              {detailStep && (
                <Button className="cv-avatar-step" onClick={handleOpenLastStep}>
                  <div className="avatar-step-wrapper">
                    <b style={{ color: detailStep?.stepColor }}>
                      {`${detailStep?.stepTitle} `}
                    </b>
                    <span className="divider">-</span>
                    <b
                      style={{ color: detailStep?.statusColor }}
                    >{` ${detailStep?.statusTitle}`}</b>
                    {totalHistoryEmail > 0 && (
                      <span className="email-icon">
                        <MailOutlined />
                      </span>
                    )}
                  </div>
                </Button>
              )}
              <NoBorderButton
                onClick={() => {
                  if (cv?.id) {
                    updateFavoriteCv(cv.id, cv?.favorite ? 0 : 1, fetchCv);
                  }
                }}
                icon={
                  <StarFilled
                    className={`icon-size ${
                      cv.favorite ? 'icon-color--active' : 'icon-color'
                    }`}
                  />
                }
              />
            </Row>
          </Col>
        </Row>
      </Col>
      <Col sm={24} lg={14} xl={24} xxl={14}>
        <div
          id="avatar-zoom-result"
          className="avatar-zoom-result"
          ref={avatarZoomResultRef}
        ></div>
        {/* Update button */}
        {permission.edit && (
          <EditBlueButton
            type="primary"
            className="edit-detail-btn"
            onClick={() => navigate('/cv/' + id + '/update')}
          >
            {t('common.edit')}
          </EditBlueButton>
        )}
        {/* cv right tab*/}
        <Tabs defaultActiveKey="1" className="cv-right-tabs">
          <Tabs.TabPane
            tab={t('common.personalInfo')}
            key="1"
            className="cv-right-tabs-info"
          >
            <div className="cv-right" ref={cvInfoRightRef}>
              {/* fullName, gender */}
              <InfoLine
                label={t('cv.detail.info')}
                content={
                  <div className="cv-basic-info">
                    {/* FullName */}
                    <span>{cvAfter.fullname}</span>
                    {/* Gender */}
                    {![null, undefined].includes(cvAfter.gender) && (
                      <span className="cv-line-gender">
                        <img
                          src={GENDER[cvAfter.gender].icon}
                          style={{ height: 20 }}
                          alt="gender"
                        />
                        <span style={{ marginLeft: 3 }}>
                          {t(GENDER[cvAfter.gender].text)}
                        </span>
                      </span>
                    )}
                  </div>
                }
              />
              {/* Birthday */}
              <InfoLine
                condition={!!cvAfter.birthday}
                label={t('user.birthday')}
                content={
                  <>
                    <CalendarOutlined className="mr-5" />
                    {cvAfter.birthday}
                  </>
                }
              />

              {/* Mobile */}
              <InfoLine
                condition={!!cvAfter.mobile}
                label={t('cvDetail.mobile')}
                content={
                  <>
                    <MobileOutlined className="mr-5" />
                    {cvAfter.mobile}
                  </>
                }
              />
              {/* Email */}
              <InfoLine
                condition={!!cvAfter.email}
                label={t('cvDetail.email')}
                content={
                  <>
                    <MailOutlined className="mr-5" />
                    {cvAfter.email}
                  </>
                }
              />
              {/* Social media */}
              <InfoLine
                condition={!!socialLink}
                label={t('common.socialLink')}
                content={
                  <a
                    href={socialLink}
                    target="_blank"
                    rel="noreferrer"
                    className="underline"
                  >
                    {t('common.showLink')}
                  </a>
                }
              />
              {/* Address */}
              <InfoLine
                condition={!!cvAfter.address}
                label={t('cvDetail.address')}
                content={
                  <>
                    <HomeOutlined className="mr-5" />
                    {cvAfter.address}
                  </>
                }
              />
              {/* Link Cv */}
              <InfoLine
                label={t('cv.detail.CV')}
                content={
                  cvAfter.linkcv ? (
                    <a
                      href={cvAfter.linkcv}
                      target="_blank"
                      rel="noreferrer"
                      className="underline"
                    >
                      {t('cv.detail.views')}
                    </a>
                  ) : (
                    t('cvDetail.noCv')
                  )
                }
              />
              {/* Source */}
              <InfoLine
                label={t('cvDetail.source')}
                content={source.title}
                condition={!!source.title}
              />
              {/* Position and department */}
              <InfoLine
                label={t('cv.detail.position')}
                condition={!!(position.title || department.title)}
                content={
                  <Breadcrumb>
                    <Breadcrumb.Item>
                      <img src={departmentImg} alt="department" />
                      <span style={{ marginRight: 8 }}>{position.title}</span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
                      <img src={positionImg} alt="position" />
                      <span>{department.title}</span>
                    </Breadcrumb.Item>
                  </Breadcrumb>
                }
              />
              {/* Level at first */}
              <InfoLine
                condition={!!level.title}
                label={t('cvDetail.firstLevel')}
                content={<div className="level-badge">{level.title}</div>}
              />
              {/* Level after interview */}
              <InfoLine
                condition={!!lastLevel.title}
                label={t('cvDetail.afterInterviewLevel')}
                content={
                  <div
                    className="level-badge"
                    style={{ background: 'var(--ant-primary-color)' }}
                  >
                    {lastLevel.title}
                  </div>
                }
              />
              {/* Interview Date */}
              <InfoLine
                condition={!!cvAfter.momentOnboard}
                label={t('cvDetail.interviewDate')}
                content={
                  <>
                    <CalendarOutlined className="mr-5" />
                    {cvAfter.momentOnboard?.format(DISPLAY_FORMAT)}
                  </>
                }
              />
              {/*Expected Onboard Date*/}
              <InfoLine
                condition={!!expectedOnboardDate}
                label={t('hrinterview.onboard')}
                content={
                  <>
                    <CalendarOutlined className="mr-5" />
                    {expectedOnboardDate &&
                      moment(expectedOnboardDate).format(DISPLAY_FORMAT)}
                  </>
                }
              />
              {/* Onboard Date */}
              <InfoLine
                condition={!!cvAfter.momentOnboard}
                label={t('cvDetail.onboard')}
                content={
                  <>
                    <CalendarOutlined className="mr-5" />
                    {cvAfter.momentOnboard?.format(DISPLAY_FORMAT)}
                  </>
                }
              />
              {/* Salary want */}
              <InfoLine
                condition={!!salaryWant}
                label={t('hrinterview.wishSalary')}
                content={salaryWant}
              />
              {/* Tech Note */}
              <DrawerInfo
                label={t('cvDetail.techComment')}
                content={techInterviewNote}
                condition={!!techInterviewNote}
              />
              {/* Salary Suggested */}
              <InfoLine
                condition={!!salarySuggested}
                label={t('techinterview.salarySuggested')}
                content={salarySuggested}
              />
              {/* HR Note */}
              <DrawerInfo
                label={t('cvDetail.hrComment')}
                content={hrInterviewNote}
                condition={!!hrInterviewNote}
              />
              {/* Description */}
              <DrawerInfo
                condition={!!cvAfter.description}
                label={t('cv.detail.note')}
                content={cvAfter.description}
              />
              {/* Reviewer */}
              <InfoLine
                condition={!!reviewer}
                label={t('updateCv.reviewer')}
                content={reviewer}
              />
              {/* Interviewer */}
              <InfoLine
                condition={!!interviewer}
                label={t('updateCv.interviewer')}
                content={interviewer}
              />
              {/* Assignee */}
              <InfoLine
                condition={!!assignee}
                label={t('common.assignee')}
                content={assignee}
              />
              <div
                className={`cv-info-more-content ${
                  !isShowMore ? 'hidden' : ''
                }`}
              ></div>
              <div className="show-more-btn-wrapper">
                <span
                  className="showmore-btn"
                  onClick={handleToggleShowMoreContent}
                >
                  {isShowMore ? t('cvDetail.hide') : t('cvDetail.more')}
                </span>
              </div>
            </div>
          </Tabs.TabPane>

          <Tabs.TabPane
            tab={t('sidebar.criteria') + ' ĐÁNH GIÁ'}
            key="2"
            className="cv-right-tabs-info"
          >
            <div className="cv-right" ref={cvInfoRightRef}>
              <Form
                form={form}
                onFinish={value => console.log('AAAAAAAAAAAAAAAAAAA', value)}
              >
                <GeneralSelect
                  label={t('sidebar.criteriaGroup')}
                  name="id_criteria_group"
                  valueKey="id"
                  contentKey="name"
                  api={criteriaGroupApi.getAll}
                  style={{ marginBottom: 20 }}
                  disabled={true}
                />
                <Form.List name="criterias">
                  {fields => (
                    <>
                      {fields?.map(field => (
                        <CvInfoCriteria field={field} form={form} />
                      ))}
                    </>
                  )}
                </Form.List>
              </Form>
            </div>
            <Row justify="end">
              <Col xl={6}>
                <Tooltip title="Lưu tiêu chí" placement="top">
                  <Button
                    onClick={form.submit}
                    type="primary"
                    icon={<SaveOutlined />}
                    style={{ marginTop: '20px', marginLeft: '26px' }}
                  >
                    Lưu
                  </Button>
                </Tooltip>
              </Col>
            </Row>
          </Tabs.TabPane>
        </Tabs>
      </Col>
    </Row>
  );
}
