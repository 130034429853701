import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import NoPermission from '../../../components/NoPermission';
import { Header, TableMain, useTable } from '../../../components/Table';
import CriteriaFilter from './components/CriteriaFilter';
import CriteriaForm from './components/CriteriaForm';
import { cols } from './constants';
import { openDrawer } from '../../../components/Drawer/slice/drawer';
import criteriaApi from '../../../api/criteriaApi';
import { message } from 'antd';
import { useCallback } from 'react';

function Criteria({ permission }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { items, filter, setFilter, loadingTable, fetchData, totalRecord } =
    useTable({
      getApi: criteriaApi.getAll,
    });

  const title = t('sidebar.criteria');

  const getDetail = useCallback(async record => {
    await criteriaApi
      .getById(record.id)
      .then(res => {
        console.log('🚀 ~ file: index.jsx:27 ~ getDetail ~ res:', res);
        const titleLower = title.toLowerCase();
        dispatch(
          openDrawer({
            initial: res.data.data,
            modeText: {
              btn: t('common.edit'),
              title: `${t('common.edit')} ${titleLower}`,
            },
          }),
        );
      })
      .catch(err => {
        message.error(t('common.networkError'));
        return {};
      });
  }, []);

  const handleClickRow = useCallback(
    record => ({
      onClick() {
        getDetail(record);
      },
    }),
    [],
  );

  return (
    <>
      <CriteriaForm
        addApi={criteriaApi.create}
        editApi={criteriaApi.edit}
        deleteElementApi={criteriaApi.deleteElement}
        fetchData={fetchData}
        setFilter={setFilter}
      />
      <Header title={title} addPermission={permission.add} />
      <CriteriaFilter filter={filter} setFilter={setFilter} />
      <TableMain
        cols={cols}
        nth
        titleLabel={title}
        items={items}
        title={title}
        fetchData={fetchData}
        deleteApi={criteriaApi.delete}
        getApi={criteriaApi.getAll}
        deleteManyApi={criteriaApi.deleteMany}
        deletePermission={permission.delete}
        editPermission={permission.edit}
        filter={filter}
        handleEdit={getDetail}
        Filter={CriteriaFilter}
        setFilter={setFilter}
        totalRecord={totalRecord}
        loadingTable={loadingTable}
        excelName="criteria-list"
        onRow={handleClickRow}
      />
    </>
  );
}

export default function CriteriaIndex(props) {
  const permission = useSelector(
    state => state.auth.userInfor.permission.criteria,
  );

  return permission.view ? (
    <Criteria {...props} permission={permission} />
  ) : (
    <NoPermission />
  );
}
