import moment from 'moment';
import { DISPLAY_FORMAT } from '../../../global/constants';
import i18n from '../../../translation/i18n';

export const cols = [
  {
    type: 'id',
    sorter: undefined,
  },
  {
    title: i18n.t('cvDetail.history'),
    type: 'all',
    width: 150,
    dataIndex: 'datecreate',
    sorter: undefined,
    render: e => moment.unix(e).format(DISPLAY_FORMAT),
  },
  {
    title: i18n.t('cv.description'),
    sorter: undefined,
    type: 'description',
  },
];
