import { Grid } from 'antd';
import { useEffect } from 'react';
import { useState } from 'react';
const { useBreakpoint } = Grid;

export default function useCurrentBreakPoint() {
  const screens = useBreakpoint();
  const [currentBreakpoint, setCurrentBreakpoint] = useState('sm');

  useEffect(() => {
    if (screens) {
      const handleGetCurrentBreakPoint = () => {
        const returnBreakpoint = Object.entries(screens).findLast(
          screen => !!screen[1],
        );
        return returnBreakpoint?.[0] || currentBreakpoint;
      };
      setCurrentBreakpoint(handleGetCurrentBreakPoint);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screens]);

  return { currentBreakpoint };
}
