import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import './editor.scss';

export default function Editor_(props) {
  const {
    value,
    onChange: setValue,
    initialValue,
    height = 640,
    menubar = true,
  } = props;
  const editorRef = useRef(null);

  const onChangeEditor = (e1, e2) => {
    setValue(e1);
  };

  return (
    <>
      <Editor
        apiKey={process.env.REACT_APP_TINY_EDITOR_KEY}
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={initialValue}
        value={value}
        onEditorChange={onChangeEditor}
        selector="textarea"
        init={{
          height,
          menubar,
          plugins: [
            'advlist',
            'code',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'preview',
            'anchor',
            'searchreplace',
            'visualblocks',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'help',
            'wordcount',
          ],
          toolbar:
            'undo redo | casechange blocks | bold italic backcolor | ' +
            'alignleft aligncenter alignright alignjustify | ' +
            'bullist numlist outdent indent | removeformat | code table help',
          content_style:
            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        }}
      />
    </>
  );
}
