import { message } from 'antd';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import commonSettingApi from '../../../../../api/commonSettingApi';
import { positionApi } from '../../../../../api/positionApi';
import { PARAMS_GET_ALL } from '../../../../../constants/requestPage';

export function useFetchDefaultField() {
  const [listPosition, setListPosition] = useState([]);

  const fetchListPosition = async () => {
    try {
      const response = await positionApi.getAll(PARAMS_GET_ALL);
      setListPosition(response.data.data);
    } catch (error) {
      if (!error.status) {
        message.error(t('request.lostConnect'));
      } else {
        message.error(t('request.failToFetchListTypeWork'));
      }
    }
  };

  useEffect(() => {
    fetchListPosition();
  }, []);
  return {
    listPosition,
  };
}

export function useFetchDeadlineDays() {
  const { t } = useTranslation();
  const [deadlineDays, setDeadlineDays] = useState([]);

  useEffect(() => {
    const fetchDeadlineDays = async () => {
      try {
        const response = await commonSettingApi.getById(1); // 1 is default id of common setting
        setDeadlineDays(response.data.data);
      } catch (error) {
        if (!error.status) {
          message.error(t('request.lostConnect'));
        } else {
          message.error(t('request.failToFetchListTypeWork'));
        }
      }
    };
    fetchDeadlineDays();
  }, [t]);

  return {
    deadlineDays,
  };
}
