import { Collapse, Form, message, Spin, Typography } from 'antd';
import { forEach, map } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import redemptionApi from '../../../api/redemption';
import BreadcrumbForm from '../../../components/Breadcrumb/BreadcrumbForm';
import NoPermission from '../../../components/NoPermission';
import { hasPermission } from '../../../utils/hasPermission';
import { RedemptionInput, TableCell } from './components';
const { useForm, Item } = Form;

function RedemptionPoint() {
  const { t } = useTranslation();
  const { userInfor } = useSelector(state => state.auth);

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [form] = useForm();
  const hasEditPerm = useMemo(
    () => hasPermission(userInfor, 'position_point', 'add'),
    [userInfor],
  );

  const initData = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await redemptionApi.get();
      data.data && setData(data.data);
    } catch (error) {
      console.log('error :', error);
    }
    setLoading(false);
  }, []);

  return hasPermission(userInfor, 'position_point', 'view') ? (
    <BreadcrumbForm
      className="redemption-point-form"
      breadcrumbNameMap={t('sidebar.redemption')}
      extra={hasEditPerm ? ['submit'] : []}
      form={form}
      onFinish={redemptionApi.edit}
      getApi={() => {}}
      fillData={initData}
      afterFinish={() => {}}
      messageSuccess={`${t('common.edit')} ${t(
        'sidebar.redemption',
      ).toLocaleLowerCase()} ${t('common.success')}`}
      catchCallback={err => {
        message.error(err?.data?.message || err?.message);
      }}
    >
      <Spin spinning={loading}>
        <Collapse
          // defaultActiveKey={Object.keys(data)}
          className="redemption-point-form__body"
          expandIconPosition="right"
          bordered={false}
        >
          {map(data, (value, label) => {
            if (value && typeof value === 'object') {
              const tableHeads = [];
              const tableRows = [];
              forEach(value, (point, level) => {
                tableHeads.push(
                  <TableCell key={level} isHeading>
                    {level}
                  </TableCell>,
                );
                tableRows.push(
                  <TableCell key={level}>
                    {hasEditPerm ? (
                      <Item
                        noStyle
                        name={[label, level]}
                        initialValue={point}
                        rules={[
                          { required: true },
                          {
                            validator: (_, value) => {
                              if (!value) {
                                return Promise.reject('');
                              }
                              return Promise.resolve('');
                            },
                          },
                        ]}
                        normalize={value => {
                          const regex = /^(\d+|\d+.\d{1,4})$/;
                          if (value && !regex.test(value)) {
                            const stringVal = value + '';
                            const result = +stringVal.slice(
                              0,
                              stringVal.length - 1,
                            );
                            return result;
                          }

                          return value;
                        }}
                      >
                        <RedemptionInput />
                      </Item>
                    ) : (
                      point
                    )}
                  </TableCell>,
                );
              });

              return (
                <Collapse.Panel
                  header={
                    <Typography.Title level={5}>{label}</Typography.Title>
                  }
                  key={label}
                >
                  <div className="ant-table-content">
                    <table>
                      <thead className="ant-table-thead">
                        <tr>{tableHeads}</tr>
                      </thead>
                      <tbody className="ant-table-tbody">
                        <tr className="ant-table-row">{tableRows}</tr>
                      </tbody>
                    </table>
                  </div>
                </Collapse.Panel>
              );
            } else {
              return null;
            }
          })}
        </Collapse>
      </Spin>
    </BreadcrumbForm>
  ) : (
    <NoPermission />
  );
}

export default RedemptionPoint;
