import { createSlice } from '@reduxjs/toolkit';

const dataDetailPlan = createSlice({
  name: 'plan',
  initialState: {
    defaultValueForm: {},
  },
  reducers: {
    setDefaultForm: (state, action) => {
      state.defaultValueForm = action.payload;
    },
  },
});
const { reducer, actions } = dataDetailPlan;
export const { setDefaultForm } = actions;
export default reducer;
