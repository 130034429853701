import { Col, Form } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormInput, RoleSelect } from '../../../../components/Form';
import { Filter } from '../../../../components/Table';

export default function UserFilter(props) {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  return (
    <Filter {...props} form={form}>
      <Col sm={8} md={6} xl={4}>
        <RoleSelect name="role_id" label={t('sidebar.role')} mode="multiple" />
      </Col>
      <Col sm={8} md={6}>
        <FormInput
          label={t('common.keyword')}
          keywordPlaceholder
          placeholder={t('common.keywordPlaceholder')}
          name="keyword"
        />
      </Col>
    </Filter>
  );
}
