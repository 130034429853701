import BreadCrumb from '.';
import { Header } from '../Table';

export default function LayoutBreadcrumb({
  breadcrumbNameMap,
  extra,
  component,
  className,
}) {
  return (
    <div className={className}>
      {breadcrumbNameMap && typeof breadcrumbNameMap === 'object' ? (
        <BreadCrumb breadcrumbNameMap={breadcrumbNameMap} extra={extra} />
      ) : (
        <Header title={breadcrumbNameMap} buttons={extra} />
      )}
      {component && <div style={{ padding: '0 26px' }}>{component}</div>}
    </div>
  );
}
