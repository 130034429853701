import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { debounce } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cvApi from '../../../api/cvApi';

export default function useCheckExist({ form, sendDataToServer, cv }) {
  const { t } = useTranslation();

  const [isExistsBasicInfo, setIsExistsBasicInfo] = useState(null);

  const checkExits = async (field, message) => {
    let obj = {};
    let value = null;
    if (Array.isArray(field)) {
      field.forEach(item => {
        obj[item] = form.getFieldValue(item);
      });
      const res = await cvApi.getAll(obj);
      if (res.data.total > 0) return message || true;
    } else {
      value = form.getFieldValue(field);
      if (value) {
        const res = await cvApi.getAll({ [field]: value });
        if (res.data.total > 0) return value;
      }
    }
    return false;
  };

  const isValidField = field => {
    return form.getFieldValue(field) && form.getFieldError(field).length === 0;
  };

  const checkEqualInitialValue = field => {
    const value = form.getFieldValue(field);
    const initial = cv[field];
    if (!value || !initial) return false;
    return value === initial;
  };

  const checkIsEqualInitialForUpdateCv = () => {
    return (
      checkEqualInitialValue('position_id') &&
      checkEqualInitialValue('fullname') &&
      checkEqualInitialValue('level_id')
    );
  };

  const onBlurBasicInfo = async () => {
    if (cv) {
      if (checkIsEqualInitialForUpdateCv()) {
        return setIsExistsBasicInfo(false);
      }
    }
    if (
      isValidField('position_id') &&
      isValidField('fullname') &&
      isValidField('level_id')
    ) {
      setIsExistsBasicInfo(null);
      const result = await checkExits(
        ['position_id', 'fullname', 'level_id'],
        `${form.getFieldValue('fullname')} ${t('cv.existInfo')}`,
      );
      setIsExistsBasicInfo(result);
    }
  };

  function confirmWithDuplicateBasicInfo(content, values) {
    Modal.confirm({
      title: t('updateCv.existsWarning'),
      content: content,
      icon: <ExclamationCircleOutlined />,
      okText: t('common.ok'),
      cancelText: t('typework.cancelConfirm'),
      onOk: () => {
        sendDataToServer(values);
      },
    });
  }

  function confirmDuplicatePhoneEmail(content) {
    Modal.warning({
      title: t('updateCv.existsWarningPhoneEmail'),
      content: content,
      okText: t('common.close'),
    });
  }

  const finishWithCheckExists = async values => {
    const basicInfoCondition =
      values.fullname &&
      values.level_id &&
      values.position_id &&
      (cv ? !checkIsEqualInitialForUpdateCv() : true);

    const arr = [];
    let email = null,
      mobile = null,
      basicInfo = null;

    if (basicInfoCondition) {
      basicInfo =
        isExistsBasicInfo !== null
          ? isExistsBasicInfo
          : await checkExits(
              ['position_id', 'fullname', 'level_id'],
              `${form.getFieldValue('fullname')} ${t('cv.existInfo')}`,
            );
    }
    console.log(email);
    if (email) arr.push(email);
    if (mobile) arr.push(mobile);
    if (basicInfo) arr.push(basicInfo);
    if (arr.length > 0) confirmWithDuplicateBasicInfo(arr.join(', '), values);
    else sendDataToServer(values);
  };

  const handleCheckFieldsExistOnchange = debounce(async field => {
    if (isValidField(field)) {
      const fieldExist = await checkExits(field);
      const isUpdate = !!cv;
      if (isUpdate) {
        if (fieldExist && cv[field] !== form.getFieldValue(field)) {
          confirmDuplicatePhoneEmail(fieldExist);
        }
      } else {
        if (fieldExist) {
          confirmDuplicatePhoneEmail(fieldExist);
        }
      }
    }
  }, 500);

  return {
    onBlurBasicInfo,
    finishWithCheckExists,
    handleCheckFieldsExistOnchange,
  };
}
