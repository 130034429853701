import { Col, DatePicker, Form, Row } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import withStepFrame from './withStepFrame';
import HRReviewApi from '../../../api/HRReviewApi';
import { onChangeSalary } from '../utils';
import moment from 'moment-timezone';
import { DISPLAY_FORMAT } from '../../../global/constants';
import { DATE_FORMAT_BACKEND } from '../../../constants';

function HrReview(props) {
  const {
    form,
    Frame,
    FormInput,
    Notes,
    Status,
    setConfig,
    step,
    status,
    isFailed,
  } = props;
  const { t } = useTranslation();

  useEffect(() => {
    setConfig({
      api: HRReviewApi.getById,
      submitApi: HRReviewApi.post,
      fillData: item => {
        return {
          onboard: item?.onboard
            ? moment(item?.onboard, DATE_FORMAT_BACKEND)
            : undefined,
        };
      },
      submitObject: values => {
        return values;
      },
    });
    //React Hook useEffect has a missing dependency: 'setConfig'
    //eslint-disable-next-line
  }, []);

  const disabledDate = dateToChoose => {
    return dateToChoose < moment().subtract(1, 'day').endOf('day');
  };

  return (
    <Frame>
      <Row gutter={20} align="stretch">
        <Col span={12}>
          <FormInput
            name="salary_want"
            label={t('hrinterview.wishSalary')}
            onChange={e => onChangeSalary(e, form, 'salary_want')}
            placeholder={t('hrinterview.currentSalaryPlaceholder')}
          />
          <Form.Item
            name="onboard"
            label={t('hrinterview.onboard')}
            rules={[
              {
                validator(_, value) {
                  if (!value) return Promise.resolve();
                  if (
                    moment().isBefore(value) ||
                    moment().format(DISPLAY_FORMAT) ===
                      value.format(DISPLAY_FORMAT)
                  )
                    return Promise.resolve();
                  else
                    return Promise.reject(
                      new Error(t('hrinterview.futureTime')),
                    );
                },
              },
            ]}
          >
            <DatePicker
              disabled={isFailed}
              format={DISPLAY_FORMAT}
              disabledDate={disabledDate}
              className="w-100"
              placeholder={t('hrinterview.onboardPlaceholder')}
            />
          </Form.Item>
          <FormInput
            name="social_link"
            label={t('common.socialLink')}
            placeholder={t('common.socialLinkPlaceHolder')}
            addonAfter={
              <span
                aria-disabled="true"
                className="add-on-input-text-content"
                onClick={() => {
                  const link = form.getFieldValue('social_link');
                  if (link) {
                    window.open(link, '_blank');
                  }
                }}
              >
                {t('common.showLink')}
              </span>
            }
          />
        </Col>
        <Col span={12}>
          <Notes />
          <Status step={step} status={status} />
        </Col>
      </Row>
    </Frame>
  );
}

export default withStepFrame(HrReview);
