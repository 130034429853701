import { CRITERIA_GROUP_URL } from '../constants/api';
import axiosClient from './axiosClient';

const criteriaGroupApi = {
  getAll(params) {
    const url = CRITERIA_GROUP_URL;
    return axiosClient.get(url, { params });
  },
  getById(criteriaGroupId) {
    const url = CRITERIA_GROUP_URL + `/${criteriaGroupId}`;
    return axiosClient.get(url);
  },
  create(data) {
    const url = CRITERIA_GROUP_URL;
    return axiosClient.post(url, data);
  },
  edit(criteriaGroupId, data) {
    const url = CRITERIA_GROUP_URL + `/${criteriaGroupId}`;
    return axiosClient.put(url, data);
  },
  delete() {},
  deleteMany() {},
};
export default criteriaGroupApi;
