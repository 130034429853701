import { Drawer } from 'antd';
import { t } from 'i18next';
import { useState } from 'react';

const DrawerInfo = ({ condition = true, label, content }) => {
  const [showHis, setShowHis] = useState();

  return (
    <>
      {condition && (
        <div className="cv-info-line">
          <p>{label}</p>
          <span
            onClick={() => {
              setShowHis(true);
            }}
            className="cv-showmore underline"
          >
            {t('role.view')}
          </span>
          <Drawer
            title={label}
            placement="right"
            onClose={() => {
              setShowHis(false);
            }}
            style={{ boxSizing: 'border-box' }}
            width="20%"
            visible={showHis}
          >
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </Drawer>
        </div>
      )}
    </>
  );
};

export default DrawerInfo;
