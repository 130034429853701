import React, { useEffect, useState } from 'react';
import { Calendar, Col, Row } from 'antd';
import './styles.scss';
import { isEqual } from 'lodash';
import Header from './components/Header';
import {
  enDaysLabels,
  getFirstDateAndLastDateOnThePanel,
  MAX_EVENTS_ON_CELL,
} from './constants';
import moment from 'moment';
import useHandleData from './hooks/useHandleData';
import Detail from './components/Detail';
import CalendarEventForm from '../../components/MsCalendar/CalendarEventForm';
import { DATE_FORMAT_FRONTEND_USE } from '../../components/MsCalendar/constants';

export default function Reminder() {
  const [dates, setDates] = useState(null);
  const [forceCallApi, setForceCallApi] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    moment().format(DATE_FORMAT_FRONTEND_USE),
  );
  const { data } = useHandleData({ params: dates, forceCallApi });

  const handleOnPanelChange = (date, check = true) => {
    const { firstDate, lastDate } = getFirstDateAndLastDateOnThePanel(date);
    const newState = {
      startdatetime: firstDate.toISOString(),
      enddatetime: lastDate.toISOString(),
    };
    if (!isEqual(dates, newState) || !check) {
      setDates(newState);
    }
  };

  useEffect(() => {
    handleOnPanelChange(moment(selectedDate, DATE_FORMAT_FRONTEND_USE));
    //eslint-disable-next-line
  }, [selectedDate]);

  const fetchData = () => {
    handleOnPanelChange(moment(selectedDate, DATE_FORMAT_FRONTEND_USE), false);
  };

  const getMaxCharacterOnCell = () => {
    const cell = document.querySelector(
      '.ant-picker-cell-inner.ant-picker-calendar-date',
    );
    const width = cell.offsetWidth;
    return Math.ceil((width - 80) / 6);
  };

  useEffect(() => {
    // Edit day labels to english
    const reminderDays = document.querySelector('.reminder thead tr');
    const htmlArr = enDaysLabels.map(day => `<th>${day}</th>`);
    reminderDays.innerHTML = htmlArr.join('');
    // default date to call api
    handleOnPanelChange(moment());
    //eslint-disable-next-line
  }, []);

  const renderDateCell = date => {
    const arr = data?.[date.format(DATE_FORMAT_FRONTEND_USE)];
    if (!arr) return null;

    const count = [];
    arr.forEach(event => {
      if (event.hrtoolData) {
        const position = event.hrtoolData?.position;
        const pair = count.find(arr => arr[0] === position);
        if (!pair) {
          count.push([position, 1]);
        } else {
          pair[1]++;
        }
      }
    });
    count.sort((item1, item2) => item2[1] - item1[1]);
    const elements = count.slice(0, MAX_EVENTS_ON_CELL).map(item => (
      <li key={item[0]}>
        <span className="reminder-cell-list--icon"></span>
        <span>{`${
          item[0] > getMaxCharacterOnCell()
            ? item[0].slice(0, getMaxCharacterOnCell()) + '...'
            : item[0]
        } (${item[1]})`}</span>
      </li>
    ));

    if (count.length > MAX_EVENTS_ON_CELL) {
      elements.push(
        <span key={100000} className="reminder-cell-list-more">
          ...
        </span>,
      );
    }

    return <ul className="reminder-cell-list">{elements}</ul>;
  };

  const handleSelectDate = date => {
    setSelectedDate(date.format(DATE_FORMAT_FRONTEND_USE));
  };

  return (
    <div className="reminder">
      <CalendarEventForm fetchData={fetchData} />
      <Header
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        setForceCallApi={setForceCallApi}
      />
      <Row>
        <Col span={17}>
          <div className="reminder-calendar">
            <Calendar
              headerRender={props => {
                return null;
              }}
              onPanelChange={handleOnPanelChange}
              dateCellRender={renderDateCell}
              onSelect={handleSelectDate}
              value={moment(selectedDate, DATE_FORMAT_FRONTEND_USE)}
            />
          </div>
        </Col>
        <Col span={7}>
          <Detail selectedDate={selectedDate} data={data} />
        </Col>
      </Row>
    </div>
  );
}
