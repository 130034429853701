import React from 'react';
import Template from './Template';
import { useTranslation } from 'react-i18next';
import companyApi from '../../../api/companyApi';
import { companyCols } from './Template/constants';
import { FormInput } from '../../../components/Form';
import Status from '../components/Status';
import i18next from 'i18next';

export const fieldsCreator = [
  {
    component: FormInput,
    props: {
      name: 'name',
      label: i18next.t('sidebar.company'),
      placeholder: i18next.t('companySetting.namePlaceHolder'),
      required: true,
      max: 200,
      min: 3,
    },
  },
  {
    component: FormInput,
    props: {
      textArea: true,
      name: 'description',
      label: i18next.t('typework.descriptionColumn'),
      max: 5000,
      placeholder: i18next.t('typework.descriptionPlaceholder'),
      rows: 8,
    },
  },
  {
    component: Status,
  },
];

export default function Company() {
  const { t } = useTranslation();

  const api = {
    getAll: companyApi.getAll,
    add: companyApi.create,
    remove: companyApi.delete,
    removeMany: companyApi.deleteMany,
    edit: companyApi.edit,
  };

  const i18n = {
    // Header
    mainTitle: t('sidebar.company'),
    // Add
    addSuccessfully: t('company.createSuccessfully'),
    // Edit
    editSuccessfully: t('company.editSuccessfully'),
    // other
    itemExists: t('company.companyExists'),
  };

  const config = {
    // Excel
    excelSheet: 'companyList',
    excelName: 'companyList.xlsx',
    // Permission
    permissionStateName: 'company',
  };
  return (
    <Template
      api={api}
      i18n={i18n}
      config={config}
      columns={companyCols}
      fields={fieldsCreator}
      requiredFields={['name']}
    />
  );
}
