import { Layout, Menu } from 'antd';
import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DEFAULT_SELECTED_MENU_SIDEBAR } from '../../constants/languagePage';
import { LIST_ROUTES } from '../../constants/listRoute';
import { setIsSidebarOpened } from '../../layouts/layoutSlide';
import { GET_FULL_LIST_PARAMS } from '../../constants';
import levelApi from '../../api/levelApi';
import useCurrentBreakPoint from '../../hooks/useCurrentBreakPoint';
import { hasPermission } from '../../utils/hasPermission';

const { SubMenu } = Menu;
const { Sider } = Layout;

const sidebarT = key => `sidebar.${key}`;

function Sidebar(props) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { currentBreakpoint } = useCurrentBreakPoint();

  const [selectedKey, setSelectedKey] = useState('');
  const { userInfor } = useSelector(state => state.auth);
  const defaultOpenKeys = useMemo(
    () => pathname.split('/').slice(1),
    [pathname],
  );

  useEffect(() => {
    if (pathname.startsWith('/plan')) {
      setSelectedKey(`/plan`);
    } else if (pathname.startsWith('/cv')) {
      setSelectedKey(`/cv`);
    } else if (pathname.startsWith('/request')) {
      setSelectedKey(`/request`);
    } else if (pathname.startsWith('/email-history')) {
      setSelectedKey(`/email-history`);
    } else if (pathname.startsWith('/setting/email')) {
      setSelectedKey('/setting/email');
    } else if (pathname.startsWith('/setting/release')) {
      setSelectedKey('/setting/release');
    } else {
      setSelectedKey(pathname);
    }

    //Add document.title based on sidebar title
    const matchRoute = LIST_ROUTES.find(route =>
      pathname.startsWith(route.path),
    );
    if (matchRoute) {
      let documentTitle = t(sidebarT(matchRoute.title));
      if (matchRoute.child?.length > 0) {
        const matchChild = matchRoute.child.find(
          route => route.path === pathname,
        );
        if (matchChild) {
          documentTitle += ` - ${t(sidebarT(matchChild.title))}`;
        }
      }
      document.title = documentTitle;
    }
  }, [pathname, t]);

  const closeSidebar = e => {
    if (e.target === document.querySelector('.sidebar')) {
      dispatch(setIsSidebarOpened(false));
    }
  };

  useEffect(() => {
    if (hasPermission(userInfor, 'level', 'menu')) {
      const getListLevelLength = async () => {
        try {
          const res = await levelApi.getLevel(GET_FULL_LIST_PARAMS);
          const result = res.data.data;
          window.localStorage.setItem('levelLength', result?.length);
        } catch (e) {
          console.log('e :', e);
        }
      };
      getListLevelLength();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Sider
      collapsible={!['sm', 'md'].includes(currentBreakpoint)}
      collapsed={props.collapsed}
      onCollapse={props.handleToggleCollapse}
      className="sidebar"
      onClick={closeSidebar}
    >
      {userInfor.permission && (
        <Menu
          mode="inline"
          className={`${props.collapsed && 'collapsed'} sidebar__menu`}
          defaultOpenKeys={defaultOpenKeys}
          selectedKeys={[
            pathname.length > 1 ? selectedKey : DEFAULT_SELECTED_MENU_SIDEBAR,
          ]}
        >
          {LIST_ROUTES.map(route => {
            const { path, icon, child, title, role } = route;
            let checkRole = false;
            if (
              (role && userInfor.permission[role]?.menu) ||
              role === undefined
            ) {
              checkRole = true;
            }
            if (Array.isArray(role)) {
              role.map(e => {
                if (userInfor.permission[e]?.menu) {
                  checkRole = true;
                }
                return e;
              });
            }

            if (checkRole)
              return child.length ? (
                <SubMenu
                  className="sidebar__subMenu"
                  key={title}
                  icon={icon}
                  title={t(sidebarT(title))}
                >
                  {child.map(item => {
                    if (
                      !item.role ||
                      (item.role && userInfor.permission[item.role]?.menu)
                    ) {
                      return (
                        <Menu.Item
                          icon={item.icon}
                          key={item.path}
                          onClick={() => dispatch(setIsSidebarOpened(false))}
                        >
                          <Link to={item.path}>{t(sidebarT(item.title))}</Link>
                        </Menu.Item>
                      );
                    } else return null;
                  })}
                </SubMenu>
              ) : (
                <Menu.Item
                  icon={icon}
                  key={path}
                  onClick={() => dispatch(setIsSidebarOpened(false))}
                >
                  <Link to={path}>{t(sidebarT(title))}</Link>
                </Menu.Item>
              );
            else return null;
          })}
        </Menu>
      )}
    </Sider>
  );
}

export default Sidebar;
