import { useState } from 'react';
import { useEffect } from 'react';
import { useCallMsApi } from '../../../components/MsCalendar';
import moment from 'moment';
import 'moment-timezone';
import {
  DATE_FORMAT_FRONTEND_USE,
  eventTag,
} from '../../../components/MsCalendar/constants';
import { VN_TIMEZONE } from '../../../global/constants';

export default function useHandleData({ params, forceCallApi }) {
  const [data, setData] = useState(null);
  const { api: getAll, isTokenReady } = useCallMsApi({
    type: 'getAll',
    params,
  });

  const handleData = data => {
    const result = {};
    data.forEach(event => {
      const day = event?.start?.dateTime;
      const des = event?.body?.content;
      if (day) {
        const value = moment
          .utc(day)
          .tz(VN_TIMEZONE)
          .format(DATE_FORMAT_FRONTEND_USE);
        // convert item
        const item = {
          ...event,
          startDate: value,
        };
        // read data from hrtool
        const str = des.slice(
          des.indexOf(eventTag.open) + eventTag.open.length,
          des.indexOf(eventTag.close),
        );

        try {
          const hrtoolData = JSON.parse(str);
          item.hrtoolData = hrtoolData;
        } catch (e) {}

        const thatDayArr = result?.[value];
        if (item?.hrtoolData) {
          if (!thatDayArr) {
            result[value] = [item];
          } else {
            result[value].push(item);
          }
        }
      }
    });
    return result;
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await getAll();
      const result = handleData(response.data.value);
      setData(result);
    };
    if (isTokenReady) {
      fetchData();
    } else {
      setData(null);
    }
    //eslint-disable-next-line
  }, [isTokenReady, params, forceCallApi]);

  return { data };
}
