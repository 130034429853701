import { LoginOutlined, LogoutOutlined } from '@ant-design/icons';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { loginRequest } from './authConfig';

export function MsLoginAndLogoutBtn({ LoginBtn, LogoutBtn }) {
  const { instance } = useMsal();

  const { t } = useTranslation();

  const isAuthenticated = useIsAuthenticated();

  const handleLogin = async () => {
    try {
      await instance.loginPopup(loginRequest);
    } catch (e) {
      console.log('e :', e);
    }
  };

  const handleLogout = async () => {
    try {
      await instance.logoutPopup();
    } catch (e) {
      console.log('e :', e);
    }
  };

  const Login = LoginBtn ? (
    <LoginBtn onClick={handleLogin} />
  ) : (
    <Button icon={<LoginOutlined />} onClick={handleLogin}>
      {t('common.login')}
    </Button>
  );

  const Logout = LogoutBtn ? (
    <LogoutBtn onClick={handleLogout} />
  ) : (
    <Button icon={<LogoutOutlined />} onClick={handleLogout}>
      {t('common.logout')}
    </Button>
  );

  return isAuthenticated ? Logout : Login;
}
