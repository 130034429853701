import { PlusCircleFilled } from '@ant-design/icons';
import { Button } from 'antd';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import releaseApi from '../../../api/releaseApi';
import { openDrawer } from '../../../components/Drawer/slice/drawer';
import { TableMain, useTable } from '../../../components/Table';
import LayoutBreadcrumb from '../../../components/Breadcrumb/LayoutBreadcrumb';
import ReleaseForm from './components/ReleaseForm';
import { breadcrumbNameMap, cols } from './constant';
import './styles.scss';

const Table = () => {
  const { t } = useTranslation();

  const { items, filter, setFilter, loadingTable, fetchData, totalRecord } =
    useTable({
      getApi: releaseApi.getAll,
    });
  return (
    <>
      <ReleaseForm
        addApi={releaseApi.add}
        editApi={releaseApi.edit}
        fetchData={fetchData}
        setFilter={setFilter}
      />
      <TableMain
        cols={cols}
        items={items}
        fetchData={fetchData}
        deleteApi={releaseApi.delete}
        getApi={releaseApi.getAll}
        title={t('sidebar.release')}
        filter={filter}
        setFilter={setFilter}
        totalRecord={totalRecord}
        loadingTable={loadingTable}
        excelName="version-list"
      />
    </>
  );
};

function ReleaseConfig() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const title = `${t('common.create')} ${t(
    'sidebar.release',
  ).lowerFirstLetter()}`;

  const handleAdd = () => {
    dispatch(
      openDrawer({
        modeText: {
          btn: `${t('common.create')}`,
          title,
        },
      }),
    );
  };
  return (
    <LayoutBreadcrumb
      breadcrumbNameMap={breadcrumbNameMap}
      className={'release-config'}
      extra={[
        <Button
          type="primary"
          icon={<PlusCircleFilled />}
          onClick={handleAdd}
          className="uppercase"
        >
          {title}
        </Button>,
      ]}
      component={<Table />}
    />
  );
}

export default memo(ReleaseConfig);
