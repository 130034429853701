import { Col, Form } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormInput } from '../../../../../components/Form';
import { Filter } from '../../../../../components/Table';

export default function BasicTableFilter(props) {
  const [form] = Form.useForm();
  const { t } = useTranslation('translation', {
    keyPrefix: 'common',
  });

  return (
    <Filter {...props} form={form} hasClean={false}>
      <Col sm={16} lg={8} xxl={6}>
        <FormInput
          label={t('keyword')}
          placeholder={t('keywordPlaceholder')}
          name="keyword"
          allowClear
        />
      </Col>
    </Filter>
  );
}
