import React from 'react';

function DashBoardIcon() {
  return (
    <span className="ant-menu-item-icon anticon">
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.25 11.6666H10.5V5.24996C10.5 5.09525 10.4386 4.94688 10.3292 4.83748C10.2198 4.72808 10.0714 4.66663 9.91669 4.66663H8.16669V1.74996C8.16669 1.59525 8.10523 1.44688 7.99583 1.33748C7.88644 1.22808 7.73806 1.16663 7.58335 1.16663H4.08335C3.92864 1.16663 3.78027 1.22808 3.67088 1.33748C3.56148 1.44688 3.50002 1.59525 3.50002 1.74996V11.6666H1.75002C1.59531 11.6666 1.44694 11.7281 1.33754 11.8375C1.22815 11.9469 1.16669 12.0953 1.16669 12.25C1.16669 12.4047 1.22815 12.553 1.33754 12.6624C1.44694 12.7718 1.59531 12.8333 1.75002 12.8333H12.25C12.4047 12.8333 12.5531 12.7718 12.6625 12.6624C12.7719 12.553 12.8334 12.4047 12.8334 12.25C12.8334 12.0953 12.7719 11.9469 12.6625 11.8375C12.5531 11.7281 12.4047 11.6666 12.25 11.6666ZM7.00002 11.6666H4.66669V2.33329H7.00002V11.6666ZM9.33335 11.6666H8.16669V5.83329H9.33335V11.6666Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
}

export default DashBoardIcon;
