import { createSlice } from '@reduxjs/toolkit';
import i18n from '../../../translation/i18n';

const initialState = {
  isOpened: false,
  modeText: {
    title: '',
    btn: '',
  },
  mode: null,
  initial: {},
};

export const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    openDrawer(state, { payload }) {
      state.isOpened = true;
      if (payload?.initial) {
        state.mode = 'edit';
        state.initial = payload.initial;
      } else {
        state.mode = 'add';
      }
      if (payload?.modeText) {
        state.modeText = payload.modeText;
      }
    },
    closeDrawer(state) {
      state.isOpened = initialState.isOpened;
      state.initial = {};
      // state.mode = null;
    },
    setIsOpenedDrawer(state, action) {
      state.isOpened = action.payload;
    },
    setModeTextDrawer(state, action) {
      state.modeText = action.payload;
      if (action.payload?.btn?.includes(i18n.t('common.create')))
        state.mode = 'add';
      if (action.payload?.btn?.includes(i18n.t('common.edit')))
        state.mode = 'edit';
    },

    setInitialDrawer(state, action) {
      state.initial = action.payload;
    },
  },
});

export const {
  openDrawer,
  closeDrawer,
  setIsOpenedDrawer,
  setModeTextDrawer,
  setInitialDrawer,
} = drawerSlice.actions;

export default drawerSlice.reducer;
