import { Steps } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CV_STATUS_DEFAULT_VALUE, CV_STEP } from '../../../constants';
import { getStep } from '../constant';

const { Step } = Steps;

export default function StepFlow({ cv, stepState }) {
  const { t } = useTranslation();
  const { currentStep, setCurrentStep } = stepState;

  const { step, status } = cv;
  const detailStep = getStep(step, status);
  const { stepValue, statusValue } = detailStep || {};

  useEffect(() => {
    if (stepValue) {
      setCurrentStep(stepValue);
      // scroll to last step
      const element = document.querySelector('.last-step');
      element?.scrollIntoView();
    }
    // React Hook useEffect has a missing dependency: 'setCurrentStep'
    //eslint-disable-next-line
  }, [stepValue]);

  const onClickStep = value => {
    value = value + 1;
    setCurrentStep(value);
  };

  return (
    <div className="step-flow">
      <Steps
        labelPlacement="vertical"
        size="small"
        className="steps"
        current={stepValue - 1}
      >
        {CV_STEP.map((step, i) => {
          if (i === 0) return null;
          return (
            <Step
              title={t(`cv.${step.title}`)}
              key={i}
              disabled={i > stepValue}
              onStepClick={onClickStep}
              icon={
                <div
                  className={`step-circle ${i > stepValue ? 'disabled' : ''}`}
                >
                  {i}
                </div>
              }
              className={`${
                i === stepValue &&
                statusValue === CV_STATUS_DEFAULT_VALUE.FAILED
                  ? 'failed'
                  : ''
              } ${i === currentStep ? 'active-show' : ''} ${
                i === stepValue ? 'last-step' : ''
              }`}
            />
          );
        })}
      </Steps>
    </div>
  );
}
