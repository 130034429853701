import { pick } from 'lodash';

const convertToNumberFormat = s => (s + '').replace(/\./g, '');

const convertToVietNamMoneyFormat = str => {
  str += '';
  const len = str.length;
  let newStr = '';
  for (let i = len - 1; i >= 0; i--) {
    if ((i - len) % 3 === 0 && i !== 0) newStr = `.${str[i]}` + newStr;
    else newStr = str[i] + newStr;
  }
  return newStr;
};

const convertToMoneyFormat = s => {
  if (/[0-9]{1} $/.test(s)) return s;
  s = convertToNumberFormat(s.replace(/ /g, ''));
  if (/^\d+$/.test(s)) return convertToVietNamMoneyFormat(s);
  if (/^\d+-\d+$/.test(s)) {
    const moneys = s.split('-');
    return (
      convertToVietNamMoneyFormat(moneys[0]) +
      ' - ' +
      convertToVietNamMoneyFormat(moneys[1])
    );
  }

  return false;
};

export const onChangeSalary = (e, form, field) => {
  let newStr = e.target.value;
  let str = convertToMoneyFormat(newStr);
  if (str) newStr = str;
  form.setFieldsValue({
    [field]: newStr,
  });
};

export const checkFormHasValue = (formValues, fieldsCheck) => {
  const formValuesToCheck = pick(formValues, fieldsCheck);
  let returnValue = false;
  fieldsCheck?.forEach(item => {
    if (formValuesToCheck?.[item]) {
      returnValue = true;
    }
  });
  return returnValue;
};
