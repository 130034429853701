import i18n from '../translation/i18n';
import moment from 'moment';
import { Tooltip } from 'antd';
import { DISPLAY_FORMAT } from '../global/constants';
import { levelsObjToString } from '../utils/formatData';

export const DETAIL_TITLE_FORM = i18n.t('request.titleDetailForm');
export const DEFAULT_PAGENUMBER = 1;
export const DEFAULT_MAX_LENGTH_DESCRIPTION = 5000;
export const LIST_ASSESSMENT = [
  {
    id: 1,
    value: 0,
    title: i18n.t('request.easyAssessment'),
  },
  {
    id: 2,
    value: 1,
    title: i18n.t('request.mediumAssessment'),
  },
  {
    id: 3,
    value: 2,
    title: i18n.t('request.hardAssessment'),
  },
  {
    id: 4,
    value: 3,
    title: i18n.t('request.veryHardAssessment'),
  },
];
export const DEFAULT_PRIORITY = [
  {
    value: 0,
    title: i18n.t('request.low'),
  },
  {
    value: 1,
    title: i18n.t('request.medium'),
  },
  {
    value: 2,
    title: i18n.t('request.mediumPLus'),
  },
  {
    value: 3,
    title: i18n.t('request.hight'),
  },
];

//value: represent for status value in BE (confirm BE for more information)
export const LIST_REQUEST_STATUS = [
  {
    id: 1,
    value: 0,
    color: '#1890ff',
    text: i18n.t('request.newStatus'),
  },
  {
    id: 2,
    value: 1,
    color: '#FF4D4F',
    text: i18n.t('request.rejectStatus'),
  },
  {
    id: 3,
    value: 2,
    color: '#15a512d9',
    text: i18n.t('request.activeStatus'),
    textEditInform: i18n.t('common.rerun'),
  },
  {
    id: 4,
    value: 3,
    color: '#ff4d4f',
    text: i18n.t('request.stoppedStatus'),
    textEditInform: i18n.t('common.stop'),
  },
  {
    id: 5,
    value: 5,
    color: '#faad14',
    text: i18n.t('common.pause'),
  },
];
export const PARAMS_GET_ALL = {
  status: 1,
  limit: 0,
};
export const MAX_QUANTITY_TARGET = 99;
export const QUANTITY_RULES = [
  () => ({
    validator(_, value) {
      if (value < 1) {
        return Promise.reject(i18n.t('request.quantityMustGreaterZero'));
      }
      if (value > MAX_QUANTITY_TARGET) {
        return Promise.reject(
          i18n.t('request.quantityMax', { maxQuantity: MAX_QUANTITY_TARGET }),
        );
      }
      return Promise.resolve();
    },
  }),
];

export const filterOption = (input, option) => {
  input = input.trim().toLowerCase();
  return option.children?.toLowerCase()?.includes(input);
};
export const checkStringNotAllow = event => {
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
};
export const createRequestColumns = (isExported = false) => {
  const cols = [
    {
      type: 'id',
      sorter: undefined,
    },
    {
      dataIndex: 'company_name',
      type: 'title',
      title: i18n.t('sidebar.company'),
      width: 150,
      sorter: undefined,
    },
    {
      dataIndex: 'department_title',
      type: 'title',
      title: i18n.t('request.department'),
      width: 150,
      sorter: undefined,
    },

    {
      title: i18n.t('request.position'),
      dataIndex: 'position_title',
      type: 'title',
      width: 150,
      sorter: undefined,
    },
    {
      title: i18n.t('request.dateCreated'),
      dataIndex: 'datecreate',
      type: 'all',
      width: 100,
      render: datecreate => {
        return moment(datecreate * 1000).format(DISPLAY_FORMAT);
      },
    },
    {
      title: i18n.t('request.requestor'),
      dataIndex: 'requestor_id',
      width: 100,
      type: 'allWithoutSort',
    },
    {
      title: i18n.t('request.totalCv'),
      dataIndex: 'target',
      width: 75,
      type: 'all',
    },
    {
      title: i18n.t('request.level'),
      dataIndex: 'levels',
      type: 'title',
      sorter: undefined,
      render: level => {
        const levels = levelsObjToString(level) ?? '-';
        const levelsArray = levels?.split(',');
        const allLevelLength = +window.localStorage.getItem('levelLength');
        return !isExported ? (
          <Tooltip placement="top" title={levels}>
            <div className="text-truncate">
              {levelsArray?.length === allLevelLength
                ? i18n.t('common.allLevel')
                : levels}
            </div>
          </Tooltip>
        ) : (
          levels
        );
      },
    },
    {
      title: i18n.t('request.language'),
      dataIndex: 'languages',
      width: '8%',
      type: 'title',
      sorter: undefined,
      render: languages => {
        const transformedLang =
          (languages && JSON.parse(languages).join(', ')) ?? '-';
        return !isExported ? (
          <div className="text-truncate">{transformedLang}</div>
        ) : (
          transformedLang
        );
      },
    },
    {
      title: i18n.t('request.priority'),
      dataIndex: 'priority',
      width: 100,
      render: priority => {
        return DEFAULT_PRIORITY[priority]?.title ?? '-';
      },
      type: 'all',
    },
    {
      title: i18n.t('request.rate'),
      dataIndex: 'assessment',
      width: 100,
      render: assessment => {
        return LIST_ASSESSMENT[assessment]?.title ?? '-';
      },
      type: 'all',
    },
    {
      title: i18n.t('request.status'),
      dataIndex: 'status',
      width: 100,
      render: (status, record) => {
        const { color, text } = record.isauto
          ? {}
          : LIST_REQUEST_STATUS.find(item => item.value === status) || {};
        return !isExported ? <span style={{ color }}>{text}</span> : text || '';
      },
      type: 'allWithoutSort',
    },
    {
      title: i18n.t('request.deadline'),
      dataIndex: 'deadline',
      width: 100,
      render: deadline => {
        return deadline ? moment(deadline).format(DISPLAY_FORMAT) : '';
      },
      type: 'all',
    },
    {
      type: 'action',
      fixed: 'right',
    },
  ];

  const exportCols = isExported
    ? isExported?.map((col, i) => {
        return { ...col, ...cols[i] };
      })
    : [];

  return isExported ? exportCols : cols;
};
