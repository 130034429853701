import i18next from 'i18next';

export const cols = [
  { type: 'id', sorter: undefined, align: 'center' },
  { type: 'title' },
  {
    type: 'tooltip',
    dataIndex: 'description',
    sorter: undefined,
  },
  { type: 'status' },
  {
    type: 'action',
  },
];

export const companyCols = [
  { type: 'id', sorter: undefined, align: 'center' },
  {
    title: i18next.t('sidebar.company'),
    type: 'title',
    dataIndex: 'name',
  },
  {
    type: 'tooltip',
    dataIndex: 'description',
    sorter: undefined,
  },
  { type: 'status' },
  {
    type: 'action',
  },
];

export const handleGetCommonModalWith = currentBreakpoint => {
  if (currentBreakpoint === 'sm') {
    return '80%';
  } else if (currentBreakpoint === 'md') {
    return '50%';
  } else {
    return '30%';
  }
};
