import React, { useMemo } from 'react';

import { FilterOutlined } from '@ant-design/icons';
import { Drawer, Table } from 'antd';
import { map, omit } from 'lodash';
import moment from 'moment';
import { default as queryString } from 'query-string';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BreadCrumb from '../../../components/Breadcrumb';
import { createDashboardColumns } from '../../../components/Dashboard/common';
import NoPermission from '../../../components/NoPermission';
import { TableMain, useTable } from '../../../components/Table';
import { DEFAULT_PAGENUMBER } from '../../../constants/requestPage';
import { API_RESPONSE_DATE_FORMAT } from '../../../global/constants';
import { createSummary } from '../../../utils/changeDataStatistic';
import { getThisMonth } from '../../../utils/getPreviousMonth';
import Charts from '../components/Charts';
import Summary from '../components/Summary';

function StatisticLayout({
  filterForm: FilterForm,
  getApi,
  title = 'request',
}) {
  const thisMonth = useMemo(() => getThisMonth(true), []);
  const isCv = title === 'cv';

  const {
    filter,
    setFilter,
    outSideItems,
    items,
    loadingTable,
    fetchData,
    totalRecord,
  } = useTable({
    getApi,
    defaultFilter: {
      from: thisMonth[0].format(API_RESPONSE_DATE_FORMAT),
      to: thisMonth[1].format(API_RESPONSE_DATE_FORMAT),
    },
  });

  const navigate = useNavigate();

  const cols = createDashboardColumns(undefined, !isCv);
  //const cols = createDashboardColumns(undefined, !isCv)
  const { userInfor } = useSelector(state => state.auth);

  const { t } = useTranslation();
  const [filterDrawer, setFilterDrawer] = useState(false);
  const checkRoleView = role => {
    if (!userInfor.permission || !userInfor.permission[role]) return false;
    return userInfor.permission[role]?.view;
  };
  const { summary, summaryCol, dataExport } = useMemo(() => {
    const summary = { ...outSideItems?.summary, point: outSideItems?.point };
    const summaryCol = createSummary(summary, isCv);
    return {
      summary,
      summaryCol,
      dataExport: outSideItems?.data?.length
        ? [...outSideItems.data, summaryCol]
        : [],
    };
  }, [outSideItems, isCv]);

  const breadcrumb = useMemo(
    () => ({
      [`/statistic/${title}`]: `${t('statistic.statistic')} ${t(
        `statistic.${title}`,
      )}`,
    }),
    [t, title],
  );

  const onDoubleClickRow = record => {
    const query = queryString.stringify({ request_id: record.id });
    navigate(`/cv?${query}`);
  };

  const filterProps = useMemo(() => {
    return {
      filter: {
        ...filter,
        date: [moment(filter.from), moment(filter.to)],
      },
      setFilter: values => {
        const newValue = omit(values, ['date']);
        setFilter({
          ...newValue,
          from: values.date
            ? moment(values.date[0]).format(API_RESPONSE_DATE_FORMAT)
            : moment().startOf('month').format(API_RESPONSE_DATE_FORMAT),
          to: values.date
            ? moment(values.date[1]).format(API_RESPONSE_DATE_FORMAT)
            : moment().endOf('month').format(API_RESPONSE_DATE_FORMAT),
          page: DEFAULT_PAGENUMBER,
        });

        if (filterDrawer) {
          setFilterDrawer(false);
        }
      },
    };
  }, [filter, filterDrawer, setFilter]);

  const clonedFilterForm = useMemo(() => {
    if (FilterForm) {
      return <FilterForm {...filterProps} />;
    } else return null;
  }, [filterProps, FilterForm]);

  const tableTitle = t('statistic.report_recruitment');

  return checkRoleView('dashboard') ? (
    <main className="statistic">
      <BreadCrumb
        breadcrumbNameMap={breadcrumb}
        extra={[
          <FilterOutlined
            style={{ fontSize: 22 }}
            onClick={() => setFilterDrawer(true)}
            className="mobile-filter-btn"
          />,
        ]}
      />
      {/* Filter for responsive */}
      <Drawer
        title="Bộ lọc"
        placement="right"
        onClose={() => setFilterDrawer(false)}
        visible={filterDrawer}
        width="90%"
        getContainer={() => document.querySelector('.table-fullscreen')}
      >
        {clonedFilterForm}
      </Drawer>

      <div className="statistic__body">
        {clonedFilterForm}
        <Summary summary={summary} isCv={isCv} />
        <Charts data={outSideItems} isCv={isCv} />

        {/* <Request /> */}
        <TableMain
          tableTitle={tableTitle}
          removeDeleteMany
          cols={cols}
          callback={columns => createDashboardColumns(columns, !isCv)}
          dataExport={dataExport}
          items={items}
          title={t('statistic.statistic')}
          fetchData={fetchData}
          {...filterProps}
          Filter={FilterForm}
          totalRecord={totalRecord}
          loadingTable={loadingTable}
          excelName={tableTitle.replace(/\s/g, '-')}
          scroll={{
            x: '100vw',
            y: 450,
          }}
          disablePagination
          onRow={record => {
            return {
              onDoubleClick: () => onDoubleClickRow(record),
            };
          }}
          summary={() => {
            if (items?.length) {
              let i = 0;
              return (
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={3} index={i++}>
                    {t('statistic.total')}
                  </Table.Summary.Cell>
                  {map(summaryCol, value => (
                    <td index={i++}>{value}</td>
                  ))}
                </Table.Summary.Row>
              );
            }
            return null;
          }}
        />
      </div>
    </main>
  ) : (
    <NoPermission />
  );
}

export default StatisticLayout;
