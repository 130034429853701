import React from 'react';
import Template from './Template';
import levelApi from '../../../api/levelApi';
import { useTranslation } from 'react-i18next';
import { cols } from './Template/constants';
import { useMemo } from 'react';
import { fieldsCreator } from '../../../constants/basicTable';
import { FormInput } from '../../../components/Form';

export default function Level() {
  const { t } = useTranslation();
  const columns = useMemo(() => {
    const columns = [...cols];
    columns.splice(columns.length - 1, 0, {
      title: t('common.rank'),
      dataIndex: 'rank',
      type: 'all',
    });
    return columns;
  }, [t]);

  const i18n = useMemo(
    () => ({
      // Header
      mainTitle: t('level.title'),
      addBtn: t('level.create-level'),
      // Table
      titleColumn: t('level.level'),
      // Form
      formAddTitle: t('level.create-level'),
      formEditTitle: t('level.levelTitleEdit'),
      titlePlaceholder: t('level.titlePlaceholder'),
      // Add
      addSuccessfully: t('level.createSuccessfully'),
      // Edit
      editSuccessfully: t('level.editSuccessfully'),
      // other
      itemExists: t('level.itemExists'),
    }),
    [t],
  );

  const fields = useMemo(() => {
    const levelFields = fieldsCreator(i18n);
    levelFields.splice(1, 0, {
      component: FormInput,
      props: {
        name: 'rank',
        label: t('common.rank'),
        max: 4,
        required: true,
        minNumber: 0,
        placeholder: t('level.rankPlaceholder'),
        inputNumber: true,
        inputType: 'number',
      },
    });
    return levelFields;
  }, [i18n, t]);

  const api = {
    getAll: levelApi.getLevel,
    add: levelApi.postLevel,
    remove: levelApi.deleteLevel,
    removeMany: levelApi.deleteMany,
    edit: levelApi.updateLevel,
  };

  const config = {
    // Excel
    excelSheet: 'levelList',
    excelName: 'levelList.xlsx',
    // Permission
    permissionStateName: 'level',
  };
  return (
    <Template
      api={api}
      i18n={i18n}
      config={config}
      columns={columns}
      fields={fields}
    />
  );
}
