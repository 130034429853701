import { message } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { settingUserApi, userPermissionApi } from '../../../api/settingUserApi';
import NoPermission from '../../../components/NoPermission';
import Search from './SearchForm';

import { useTranslation } from 'react-i18next';
import { Header, TableMain, useTable } from '../../../components/Table';
import { hasPermission } from '../../../utils/hasPermission';
import ModalForm from './components/ModalForm';
import { cols } from './constants';
import { openDrawer } from '../../../components/Drawer/slice/drawer';

function Role() {
  const { userInfor } = useSelector(state => state.auth);
  const { t } = useTranslation();
  const [permissionApi, setPermissionApi] = useState([]);
  const dispatch = useDispatch();
  const title = t('sidebar.role');
  const fetchPermission = async () => {
    try {
      const res = await userPermissionApi.getAll({
        status: 1,
        limit: 0,
      });
      setPermissionApi(res.data.data);
    } catch (e) {
      message.error(e.message);
    }
  };

  useEffect(() => {
    fetchPermission();
  }, []);

  const { items, filter, setFilter, loadingTable, fetchData, totalRecord } =
    useTable({
      getApi: settingUserApi.getAll,
    });

  const handleClickRow = record => ({
    onClick() {
      const titleLower = title.toLowerCase();
      dispatch(
        openDrawer({
          initial: record,
          modeText: {
            btn: t('common.edit'),
            title: `${t('common.edit')} ${titleLower}`,
          },
        }),
      );
    },
  });

  return userInfor.permission.role.view ? (
    <div>
      <Header title={title} addPermission={userInfor.permission?.role?.add} />
      <ModalForm
        permissionApi={permissionApi}
        addApi={settingUserApi.create}
        editApi={settingUserApi.edit}
        setFilter={setFilter}
        fetchData={fetchData}
      />
      <Search setFilter={setFilter} filter={filter} />
      <TableMain
        cols={cols}
        titleLabel={title}
        nth
        items={items}
        title={title}
        fetchData={fetchData}
        deleteApi={settingUserApi.delete}
        getApi={settingUserApi.getAll}
        deleteManyApi={settingUserApi.multiDelete}
        deletePermission={hasPermission(userInfor, 'role', 'delete')}
        editPermission={hasPermission(userInfor, 'role', 'edit')}
        filter={filter}
        Filter={Search}
        setFilter={setFilter}
        totalRecord={totalRecord}
        loadingTable={loadingTable}
        excelName="role-templates"
        onRow={handleClickRow}
      />
    </div>
  ) : (
    <NoPermission />
  );
}

export default Role;
