import { Form } from 'antd';
import React from 'react';
import TextArea from 'antd/lib/input/TextArea';

const CvInfoReview = ({ name, isReview }) => {
  return (
    <>
      {isReview && (
        <Form.Item label="Review" noStyle name={[name, 3]}>
          <TextArea
            visible={Number.isInteger(name)}
            showCount
            maxLength={100}
            style={{
              height: 60,
              width: 430,
              marginTop: 20,
            }}
            placeholder="Nhập review...."
          />
        </Form.Item>
      )}
    </>
  );
};

export default CvInfoReview;
