import React from 'react';
import { Button, DatePicker } from 'antd';

import monthLocale from 'antd/es/date-picker/locale/en_US';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import moment from 'moment';
import { MsLoginAndLogoutBtn } from '../../../components/MsCalendar';
import { DATE_FORMAT_FRONTEND_USE } from '../../../components/MsCalendar/constants';
import { MONTH_YEAR } from '../../../global/constants';
import { useTranslation } from 'react-i18next';

export default function Header({
  selectedDate,
  setSelectedDate,
  setForceCallApi,
}) {
  const { t } = useTranslation();

  const selectedDateMoment = moment(selectedDate, DATE_FORMAT_FRONTEND_USE);
  const setSelectedDateFromMoment = date =>
    setSelectedDate(date.format(DATE_FORMAT_FRONTEND_USE));

  const onChangeMonth = e => {
    setSelectedDateFromMoment(e);
    if (
      moment().format(DATE_FORMAT_FRONTEND_USE) ===
      e.format(DATE_FORMAT_FRONTEND_USE)
    ) {
    }
  };

  const moveMonth = type => {
    const factor = type === 'plus' ? 1 : -1;
    const newValue = selectedDateMoment.clone().add(factor, 'months');
    setSelectedDateFromMoment(newValue);
  };

  const toToday = () => {
    const date = moment();

    setSelectedDateFromMoment(date);
    if (
      date.month() === moment(selectedDate, DATE_FORMAT_FRONTEND_USE).month()
    ) {
      setForceCallApi(state => !state);
    }
  };

  return (
    <div className="reminder-header">
      <h1>{t('reminder.header')}</h1>

      <Button className="today" onClick={toToday}>
        {t('time.today')}
      </Button>

      <LeftOutlined className="icon" onClick={() => moveMonth('subtract')} />

      <DatePicker
        onChange={onChangeMonth}
        allowClear={false}
        picker="month"
        className="month-picker"
        suffixIcon={null}
        format={MONTH_YEAR}
        locale={monthLocale}
        value={selectedDateMoment}
      />

      <RightOutlined className="icon" onClick={() => moveMonth('plus')} />

      <MsLoginAndLogoutBtn />
    </div>
  );
}
