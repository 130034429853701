import i18n from '../../../translation/i18n';

export const cols = [
  { type: 'id', sorter: undefined, align: 'center' },
  {
    title: i18n.t('sidebar.role'),
    dataIndex: 'title',
    width: '70%',
    type: 'title',
  },

  {
    type: 'status',
    width: '10%',
  },
  {
    type: 'action',
  },
];
