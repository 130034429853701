import { Switch } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

function RedemptionSwitch(props) {
  const { t } = useTranslation();
  return (
    <div className="redemption-switch">
      <span className="redemption-switch__desc">
        {t('position.redemptionDesc')}
      </span>
      <Switch {...props} />
    </div>
  );
}

export default RedemptionSwitch;
