import { useEffect, useState } from 'react';

export default function useGetDetail({ api, value }) {
  const [detail, setDetail] = useState({});

  useEffect(() => {
    if (value) {
      const getDetail = async () => {
        try {
          const response = await api(value);
          const data = response.data.data;
          setDetail(data);
        } catch (e) {
          console.log('e :', e);
        }
      };
      getDetail();
    }
    //React Hook useEffect has a missing dependency: 'api'
    //eslint-disable-next-line
  }, [value]);

  return { detail, setDetail };
}
