import moment from 'moment-timezone';
import { API_RESPONSE_DATE_FORMAT, DISPLAY_FORMAT } from '../global/constants';

const FORMAT_INFO = {
  1: { format: 'YYYY', reg: /^\d{4}$/, postFormat: '$0-00-00' },
  2: { format: 'DD/MM', reg: /^\d{1,2}\/\d{1,2}$/, postFormat: '0000-$1-$0' },
  3: {
    format: DISPLAY_FORMAT,
    reg: /^\d{1,2}\/\d{1,2}\/\d{4}$/,
  },
};

export const INVALID_DATE = 'invalidDate';

export const formatPostDate = value => {
  const dateComponents = value?.split('/');
  let postValue = INVALID_DATE;
  if (Array.isArray(dateComponents) && value) {
    const { format, reg, postFormat } =
      FORMAT_INFO[dateComponents.length] ?? {};
    const dateValue = moment(value, format);
    if (reg?.test?.(value) && dateValue.isValid()) {
      if (postFormat) {
        postValue = postFormat;
        dateComponents.forEach((value, i) => {
          postValue = postValue.replace('$' + i, value);
        });
      } else {
        postValue = dateValue.format(API_RESPONSE_DATE_FORMAT);
      }
    }
    return postValue;
  }
  return undefined;
};

export const convertToDisplayDate = dateComponents =>
  dateComponents.reduce((prev, curr) => `${curr}/${prev}`);

export const apiDateToDisplayDate = apiResponseDate => {
  let inputDate;
  if (apiResponseDate) {
    const dateComponents = apiResponseDate.split(/[- ]/);
    if (dateComponents[0] === '0000') {
      inputDate = convertToDisplayDate(dateComponents.slice(1));
    } else if (dateComponents[1] === '00' && dateComponents[2] === '00') {
      inputDate = dateComponents[0];
    } else {
      inputDate = convertToDisplayDate(dateComponents);
    }
  }
  return inputDate;
};
