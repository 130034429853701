import { CRITERIA_URL, CRITERIA_ELEMENT_URL } from '../constants/api';
import axiosClient from './axiosClient';

const criteriaApi = {
  getAll(params) {
    const url = CRITERIA_URL;
    return axiosClient.get(url, { params });
  },
  getById(companyId) {
    const url = CRITERIA_URL + `/${companyId}`;
    return axiosClient.get(url);
  },
  create(data) {
    const url = CRITERIA_URL;
    const criteria_elements = data.criteria_elements;
    let newCriteriaData = {};
    criteria_elements?.forEach((item, index) => {
      newCriteriaData[index.toString()] = item;
    });
    const dataCreate = {
      ...data,
      criteria_elements: newCriteriaData,
    };
    return axiosClient.post(url, dataCreate);
  },
  edit(criteriaId, data) {
    const url = CRITERIA_URL + `/${criteriaId}`;
    return axiosClient.put(url, data);
  },
  deleteElement(criteriaElementId) {
    const url = CRITERIA_ELEMENT_URL + `/${criteriaElementId}`;
    return axiosClient.delete(url);
  },
  delete() {},
  deleteMany() {},
};
export default criteriaApi;
