import { Col, Row } from 'antd';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import probationApi from '../../../api/thuViec';
import { FormDatePicker } from '../../../components/Form';
import { FORMAT_DATEPICKER_L } from '../../../constants';
import { API_RESPONSE_DATE_FORMAT } from '../../../global/constants';
import withStepFrame from './withStepFrame';
import { statusProbationLabel } from '../constant/stepStatusLabel';

function Probation(props) {
  const { Frame, Notes, Status, setConfig, isFailed } = props;
  const { t } = useTranslation();

  useEffect(() => {
    setConfig({
      api: probationApi.getById,
      submitApi: probationApi.post,
      requiredFields: ['todate'],
      submitProps: {
        reasonName: 'notes',
      },
      fillData: values => {
        values.todate = values.todate
          ? moment(values?.todate, API_RESPONSE_DATE_FORMAT)
          : undefined;
        return values;
      },
      submitFailedObject: values => {
        return {
          notes: values.notes,
          status: 0,
        };
      },
      submitObject: values => {
        if (values.todate) {
          values.todate = values.todate.format(API_RESPONSE_DATE_FORMAT);
        }
        return values;
      },
    });
    //React Hook useEffect has a missing dependency: 'setConfig'
    //eslint-disable-next-line
  }, []);

  return (
    <Frame>
      <Row gutter={20}>
        <Col span={14}>
          <FormDatePicker
            name="todate"
            label={t('probation.day')}
            disabled={isFailed}
            format={FORMAT_DATEPICKER_L}
            required
          />
          <Status disabled={false} statusLabel={statusProbationLabel} />
          <Notes name="notes" label={t('review.review')} />
        </Col>
      </Row>
    </Frame>
  );
}

export default withStepFrame(Probation);
