import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DashboardCard from '../../../../components/Dashboard/Card';
import { calcPercent } from '../../../../utils/formatData';
function Summary({ summary, isCv = false }) {
  const { target, total_cv, onboard_cv, point, offer_success, offer_cv } =
    summary;
  const { t } = useTranslation();

  const dataCard = useMemo(() => {
    const data = [
      {
        info: target ?? '',
        desc: t('statistic.amount_recruit'),
        subDesc: t('statistic.position'),
      },
      {
        info: total_cv ?? '',
        desc: t('statistic.number_of_cv'),
        subDesc: t('statistic.letter'),
      },
      {
        info: onboard_cv ?? '',
        desc: t('statistic.applicants'),
        subDesc: t('statistic.candidate'),
      },
      {
        info: calcPercent(offer_success, offer_cv),
        desc: t('statistic.ratio_success'),
        subDesc: t('statistic.percent'),
      },
    ];
    if (isCv) {
      data.push({
        titleClassName: 'total',
        info: (
          <>
            <span className="total-point">{point ?? 0}</span>/
            <span
              style={{
                fontSize: '1.5em',
                position: 'relative',
                top: '.15em',
              }}
            >
              &#8734;
            </span>
          </>
        ),
        desc: t('statistic.total'),
        subDesc: t('statistic.point'),
      });
    }
    return data;
    // eslint-disable-next-line
  }, [isCv, t, summary]);

  return (
    <div className="summary">
      {dataCard.map((cardProps, id) => (
        <DashboardCard {...cardProps} key={id} />
      ))}
    </div>
  );
}

export default memo(Summary);
