import i18next from 'i18next';

export const statusHrInterviewLabel = [
  {
    value: 2,
    label: i18next.t(`hrinterview.successStatus`),
  },
  {
    value: 0,
    label: i18next.t(`hrinterview.failStatus`),
  },
  {
    value: 1,
    label: i18next.t(`hrinterview.pendingStatus`),
  },
  {
    value: 3,
    label: i18next.t(`hrinterview.absentStatus`),
  },
  {
    value: 4,
    label: i18next.t('hrinterview.refuseStatus'),
  },
];

export const statusPreInterviewLabel = [
  {
    value: 2,
    label: i18next.t('hrinterview.successStatus'),
  },
  {
    value: 0,
    label: i18next.t('hrinterview.failStatus'),
  },
  {
    value: 1,
    label: i18next.t('hrinterview.pendingStatus'),
  },
  {
    value: 4,
    label: i18next.t('hrinterview.refuseStatus'),
  },
];

export const statusTechInterviewLabel = [
  {
    value: 2,
    label: i18next.t('hrinterview.successStatus'),
  },
  {
    value: 0,
    label: i18next.t('hrinterview.failStatus'),
  },
  {
    value: 1,
    label: i18next.t('hrinterview.pendingStatus'),
  },
];

export const statusOnboardLabel = [
  {
    value: 2,
    label: i18next.t('onboard.WentWork'),
  },
  {
    value: 0,
    label: i18next.t('onboard.NotToWork'),
  },
  {
    value: 1,
    label: i18next.t('onboard.pending'),
  },
  {
    value: 3,
    label: i18next.t('onboard.Postpone'),
  },
];

export const statusProbationLabel = [
  {
    value: 2,
    label: i18next.t('review.success'),
  },
  {
    value: 0,
    label: i18next.t('review.fail'),
  },
  {
    value: 3,
    label: i18next.t('probation.extend'),
  },
  {
    value: 1,
    label: i18next.t('probation.inprogressing'),
  },
];
