import { Form, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import requestApi from '../../../api/requestApi';
import { LIST_REQUEST_STATUS } from '../../../constants/requestPage';
import { handleRequestData } from './handleRequestData';
import LayoutBreadcrumb from '../../../components/Breadcrumb/LayoutBreadcrumb';
import RequestForm from './RequestForm';
import BtnGroupSubmit from './Table/components/BtnGroupSubmit';
import useFetchDetailRequest from './useFetchDetailRequest';
export default function EditRequest() {
  const { t } = useTranslation();
  const breadcrumbNameMap = {
    '/request': t('sidebar.request'),
    '/request/edit': t('request.titleEditForm'),
  };
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname.split('/').filter(x => x);
  const { detailRequest: requestFormInfo } = useFetchDetailRequest(pathname[2]);
  const handleEditRequest = async values => {
    const newValues = await handleRequestData(values, requestFormInfo);
    if (requestFormInfo.status === LIST_REQUEST_STATUS[4].value) {
      newValues.status = LIST_REQUEST_STATUS[2].value;
    }
    try {
      await requestApi.edit(requestFormInfo.id, {
        ...newValues,
      });
      message.success(t('request.editRequestSuccess'));
      navigate('/request');
    } catch (error) {
      if (!error.status) {
        message.error(t('request.lostConnect'));
      } else {
        message.error(t('request.editRequestFail'));
      }
    }
  };
  return (
    <Form
      form={form}
      layout="vertical"
      name="form_in_modal"
      className="request__form"
      onFinish={handleEditRequest}
    >
      <LayoutBreadcrumb
        breadcrumbNameMap={breadcrumbNameMap}
        extra={[<BtnGroupSubmit form={form} />]}
        component={
          <RequestForm titleForm={t('request.titleEditForm')} form={form} />
        }
      />
    </Form>
  );
}
