import { Radio } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormItem } from '../../../components/Form';

const LIST_STATUS = [
  {
    id: 1,
    value: 0,
    title: 'locking',
  },
  {
    id: 2,
    value: 1,
    title: 'active',
  },
];

export default function Status({ handlChangeStatus }) {
  const { t } = useTranslation();
  return (
    <FormItem name="status" label={t('typework.statusColumn')}>
      <Radio.Group
        onChange={e => {
          handlChangeStatus && handlChangeStatus(e);
        }}
      >
        {LIST_STATUS.map(status => (
          <Radio key={status.id} value={status.value}>
            {t(`typework.${status.title}`)}
          </Radio>
        ))}
      </Radio.Group>
    </FormItem>
  );
}
