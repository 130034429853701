import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { positionApi } from '../../../api/positionApi';
import { openDrawer } from '../../../components/Drawer/slice/drawer';
import NoPermission from '../../../components/NoPermission';
import { Header, TableMain, useTable } from '../../../components/Table';
import { createPositionColumns } from '../../../constants/position';
import { hasPermission } from '../../../utils/hasPermission';
import PositionFilter from './components/Filter';
import ModalForm from './components/ModalForm';
function Position() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userInfor } = useSelector(state => state.auth);
  const title = t('position.position');
  const { items, filter, setFilter, loadingTable, fetchData, totalRecord } =
    useTable({
      getApi: positionApi.getAll,
    });

  const handleClickRow = record => ({
    onClick() {
      const titleLower = title.toLowerCase();
      dispatch(
        openDrawer({
          initial: record,
          modeText: {
            btn: t('common.edit'),
            title: `${t('common.edit')} ${titleLower}`,
          },
        }),
      );
    },
  });

  return (
    <div className="position">
      {hasPermission(userInfor, 'positions', 'view') ? (
        <>
          <Header
            title={title}
            addPermission={hasPermission(userInfor, 'positions', 'add')}
          />
          <PositionFilter listParams={filter} setFilter={setFilter} />
          <TableMain
            cols={createPositionColumns()}
            callback={createPositionColumns}
            titleLabel={title}
            nth
            items={items}
            title={title}
            fetchData={fetchData}
            deleteApi={positionApi.delete}
            getApi={positionApi.getAll}
            deleteManyApi={positionApi.multiDelete}
            deletePermission={hasPermission(userInfor, 'positions', 'delete')}
            editPermission={hasPermission(userInfor, 'positions', 'edit')}
            filter={filter}
            Filter={PositionFilter}
            setFilter={setFilter}
            totalRecord={totalRecord}
            loadingTable={loadingTable}
            excelName="position-templates"
            onRow={handleClickRow}
          />
          <ModalForm
            addApi={positionApi.postPosition}
            editApi={positionApi.edit}
            fetchData={fetchData}
            setFilter={setFilter}
          />
        </>
      ) : (
        <NoPermission />
      )}
    </div>
  );
}

export default Position;
