/**
 * @example "hello".capFirstLetter() return "Hello"
 * @return capitalize first letter
 */
//eslint-disable-next-line no-extend-native
String.prototype.capFirstLetter = function () {
  return this.length ? this[0].toLocaleUpperCase() + this.slice(1) : this;
};
//eslint-disable-next-line no-extend-native
String.prototype.lowerFirstLetter = function () {
  return this.length ? this[0].toLocaleLowerCase() + this.slice(1) : this;
};
