import { Slider, Tooltip } from 'antd';
import React from 'react';
import './style.scss';

const MarkGuide = () => {
  const marks = {
    0: {
      style: {
        color: '#ff0000',
      },
      label: <strong>0</strong>,
    },
    50: {
      style: {
        color: '#ff0000',
      },
      label: <strong>50</strong>,
    },
    70: {
      style: {
        color: '#ffa500',
      },
      label: <strong>70</strong>,
    },
    80: {
      style: {
        color: '#ffa500',
      },
      label: <strong>80</strong>,
    },
    100: {
      style: {
        color: '#ffa500',
      },
      label: <strong style={{ marginRight: '20px' }}>100</strong>,
    },
  };

  return (
    <>
      <div style={{ marginBottom: '60px' }}></div>
      <Slider
        // disabled='true'
        className="slider-example"
        min={0}
        max={100}
        step={1}
        defaultValue={100}
        style={{
          width: 510,
          marginBottom: 60,
        }}
        marks={marks}
        railStyle={{
          backgroundColor: 'orange',
        }}
      />
      <ul class="range-title">
        <li id="range-title-belowAve">Yếu</li>

        <li id="range-title-average">
          <Tooltip placement="top" title="Trung bình">
            Trung bình
          </Tooltip>
        </li>

        <li id="range-title-good">Khá</li>
        <li id="range-title-excellent">
          {'Tốt' +
            '\xa0\xa0\xa0\xa0\xa0\xa0\xa0' +
            '-' +
            '\xa0\xa0\xa0\xa0\xa0\xa0' +
            'Rất tốt'}
        </li>

        {/* <li id="range-title-veryExcel"> <Tooltip placement="top" title="Rất tốt">Rất tốt </Tooltip></li> */}
      </ul>
    </>
  );
};

export default MarkGuide;
