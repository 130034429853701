export const safeTrim = value =>
  typeof value === 'string' ? value.trim() : value;

export function removeWhiteSpaces(obj) {
  const newObj = Object.keys(obj).reduce((acc, cur) => {
    acc[cur] = safeTrim(obj[cur]);
    return acc;
  }, {});

  return newObj;
}

export const safelyParse = (jsonData, fallbackData) => {
  try {
    return JSON.parse(jsonData) ?? fallbackData;
  } catch (error) {
    return fallbackData;
  }
};

export const preHandlePostData = (data, acceptKeys) => {
  const newData = {};
  Object.keys(data).forEach(key => {
    const shouldTakeThisValue = Array.isArray(acceptKeys)
      ? acceptKeys.includes(key)
      : true;
    const value = data[key];
    if (shouldTakeThisValue && !(value === null || value === undefined)) {
      newData[key] = safeTrim(value);
    }
  });
  return newData;
};
