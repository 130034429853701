import { SaveOutlined } from '@ant-design/icons';
import { Form, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { CancelWhiteButton } from '../../components/Buttons';
import LayoutBreadcrumb from './LayoutBreadcrumb';
import { SubmitBtn } from '../Form';

export default function BreadcrumbForm({
  afterFinish,
  breadcrumbNameMap,
  onFinish,
  extra = ['submit', 'cancel'],
  form,
  requiredFields,
  cancelTitle,
  getApi,
  addApi,
  editApi,
  fillData,
  submitCondition,
  disabledSubmit,
  children,
  catchCallback,
  className,
  messageSuccess,
  ...props
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const [submitLoading, setSubmitLoading] = useState(false);

  const handleBack = () => {
    if (typeof afterFinish === 'function') {
      afterFinish();
    } else {
      navigate(-1);
    }
  };

  const definedButtons = {
    submit: (
      <SubmitBtn
        form={form}
        loading={submitLoading}
        requiredFields={requiredFields}
        icon={<SaveOutlined />}
        condition={submitCondition}
        disabled={disabledSubmit}
      >
        {t('common.save')}
      </SubmitBtn>
    ),
    cancel: (
      <CancelWhiteButton onClick={handleBack} className="uppercase">
        {cancelTitle || t('common.cancel')}
      </CancelWhiteButton>
    ),
  };

  extra = extra.map(item => {
    if (typeof item === 'string') return definedButtons[item];
    return item;
  });

  // Get data
  useEffect(() => {
    const initData = async () => {
      if (id) {
        try {
          const res = await getApi(id);
          fillData(res.data.data);
        } catch (e) {
          console.log('e :', e);
        }
      } else {
        fillData({});
      }
    };
    initData();
    //eslint-disable-next-line
  }, []);

  const onFinishDefault = async values => {
    if (!id && addApi) {
      await addApi(values);
    }
    if (editApi && id) {
      await editApi(id, values);
    }
  };

  const onFinishForm = async values => {
    setSubmitLoading(true);
    try {
      for (let key in values) {
        if (typeof values[key] === 'string') values[key] = values[key].trim();
      }
      const finish = onFinish || onFinishDefault;
      await finish(values);
      setSubmitLoading(false);

      let text = '';
      if (breadcrumbNameMap) {
        for (let key in breadcrumbNameMap) {
          const value = breadcrumbNameMap[key];
          if (id) {
            if (/[0-9]/.test(key)) {
              text = value;
            }
          } else {
            if (!/[0-9]/.test(key)) {
              text = value;
            }
          }
        }
      }
      message.success(messageSuccess || `${text} ${t('common.success')}`);
      handleBack();
    } catch (e) {
      setSubmitLoading(false);
      if (catchCallback) catchCallback(e);
      console.log(e);
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinishForm}
      className={`standard-form ${className ?? ''}`}
      {...props}
    >
      <LayoutBreadcrumb
        breadcrumbNameMap={breadcrumbNameMap}
        extra={extra}
        component={children}
      />
    </Form>
  );
}
