import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useState } from 'react';
import { useEffect } from 'react';
import { createAxiosClient } from '../../api/axiosClient';
import { graphConfig, loginRequest } from './authConfig';

const useCallMsApi = ({ type, params } = {}) => {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [token, setToken] = useState('');
  const [isTokenReady, setIsTokenReady] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        const response = await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        });
        setToken(response.accessToken);
        setIsTokenReady(true);
      })();
    } else {
      setIsTokenReady(false);
    }
    //React Hook useEffect has missing dependencies: 'accounts' and 'instance'
    //eslint-disable-next-line
  }, [isAuthenticated]);

  const axiosWithToken = () => {
    return createAxiosClient(graphConfig.graphMeEndpoint, token, 'reminder');
  };

  const whichCase = type => {
    if (token) {
      switch (type) {
        case 'getAll':
          return () =>
            axiosWithToken().get('calendarview', {
              params: { ...params, $top: 1000 },
            });
        case 'edit':
          return data => {
            const id = data.id;
            delete data.id;
            return axiosWithToken().patch('events/' + id, data);
          };
        case 'add':
          return data => {
            return axiosWithToken().post('events', data);
          };
        default:
          return;
      }
    }
    return null;
  };

  return { api: whichCase(type), isTokenReady };
};

export { useCallMsApi };
