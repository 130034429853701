import { Select } from 'antd';
import { memo } from 'react';
import { FormItem } from '../../../components/Form';
import { PHYSIOGNOMY_LEVEL } from '../../../constants';

const SelectLevel = ({ name, label, disabled = false, required = false }) => {
  const lowerLabel = label.toLowerCase();
  const placeholder = `Điểm ${lowerLabel}`;
  return (
    <FormItem
      name={name}
      label={label}
      requiredLabel={placeholder}
      required={required}
      type="select"
    >
      <Select
        placeholder={placeholder}
        disabled={disabled}
        options={PHYSIOGNOMY_LEVEL}
        allowClear
      />
    </FormItem>
  );
};

export default memo(SelectLevel);
