export const cols = [];
export const requiredFields = [
  'parent_id',
  'title',
  'manager_id',
  'requestor',
  'key',
  'rank',
  'levels',
  'user_cvs',
];
