import enUS from 'antd/lib/locale/en_US';
import viVN from 'antd/lib/locale/vi_VN';
import i18n from '../translation/i18n';

// table config
export const PAGE_SIZE_OPTIONS = ['10', '20', '50'];
export const DEFAULT_FILTER = { page: 1, limit: 10, keyword: '' };
export const GET_FULL_LIST_PARAMS = {
  limit: 0,
  status: 1,
};
export const CONFIG_PAGINATION = {
  showQuickJumper: false,
  showSizeChanger: true,
  defaultCurrent: 1,
  pageSize: DEFAULT_FILTER.limit,
  total: 0,
  pageSizeOptions: PAGE_SIZE_OPTIONS,
};

// date format
export const BACKEND_ONBOARD_CV_DETAIL_FORMAT = 'YYYY MM DD';
export const DATE_TIME_FORMAT = 'HH:mm - DD/MM/YYYY';
export const DATE_FORMAT_BACKEND = 'YYYY/MM/DD';
export const FORMAT_DATEPICKER_L = 'L';

// CV config
export const CV_STATUS_DEFAULT = [
  { title: 'Failed', color: '#c20000', id: 0 },
  { title: 'Pending', color: '#a5b802', id: 1 },
  { title: 'Pass', color: '#0ea800', id: 2 },
];

export const CV_STATUS_DEFAULT_EXTENDED = [
  ...CV_STATUS_DEFAULT,
  { title: 'Refuse', color: '#c20000', id: 4 },
  { title: 'Others', color: '#000861', id: 3 },
];

export const CV_STATUS_DEFAULT_REFUSE = [
  ...CV_STATUS_DEFAULT,
  {},
  { title: 'Refuse', color: '#c20000', id: 4 },
];

export const CV_STATUS_DEFAULT_VALUE = {
  FAILED: 0,
  PENDING: 1,
  PASS: 2,
  OTHER: 3,
  REFUSE: 4,
};

export const CV_STATUS_INTERVIEW = [
  ...CV_STATUS_DEFAULT,
  { title: 'Absent', color: '#000861' },
  { title: 'Refuse', color: '#c20000', id: 4 },
];

export const CV_STATUS_ON_BOARD = [
  { title: 'NotToWork', color: '#c20000' },
  { title: 'Pending', color: '#a5b802' },
  { title: 'WentWork', color: '#0ea800' },
  { title: 'Postpone', color: '#b72073' },
];

export const CV_STATUS_THU_VIEC = [
  { title: 'Failed', color: '#c20000' },
  { title: 'Inprogress', color: '#a5b802' },
  { title: 'Pass', color: '#0ea800' },
  { title: 'Extend', color: '#000861' },
];

export const CV_STEP = [
  { title: 'STEP_NEW', color: '#f56c42', status: CV_STATUS_DEFAULT, id: 0 },
  {
    title: 'STEP_HR_REVIEW',
    color: '#f5a142',
    status: CV_STATUS_DEFAULT,
    id: 1,
  },
  {
    title: 'STEP_NHAN_TUONG_1',
    color: '#8aa127',
    status: CV_STATUS_DEFAULT,
    id: 2,
  },
  {
    title: 'STEP_CV_REVIEW',
    color: '#f5d742',
    status: CV_STATUS_DEFAULT,
    id: 3,
  },
  {
    title: 'STEP_NHAN_TUONG_2',
    color: '#1eb0a1',
    status: CV_STATUS_DEFAULT,
    id: 4,
  },
  {
    title: 'STEP_TO_INTERVIEW',
    color: '#99cf25',
    status: CV_STATUS_DEFAULT_REFUSE,
    id: 5,
  },
  {
    title: 'STEP_INTERVIEW',
    color: '#23b01e',
    status: CV_STATUS_INTERVIEW,
    id: 6,
  },
  {
    title: 'STEP_PRE_OFFER',
    color: '#1e7fb0',
    status: CV_STATUS_DEFAULT_REFUSE,
    id: 7,
  },
  {
    title: 'STEP_OFFER',
    color: '#1e4cb0',
    status: CV_STATUS_DEFAULT_REFUSE,
    id: 8,
  },
  {
    title: 'STEP_ON_BOARD',
    color: '#421eb0',
    status: CV_STATUS_ON_BOARD,
    id: 9,
  },
  {
    title: 'STEP_THU_VIEC',
    color: '#b01eab',
    status: CV_STATUS_THU_VIEC,
    id: 10,
  },
];

export const LIST_ASSESSMENT = [
  {
    id: 1,
    value: 0,
    title: i18n.t('plan.easy'),
  },
  {
    id: 2,
    value: 1,
    title: i18n.t('plan.medium'),
  },
  {
    id: 3,
    value: 2,
    title: i18n.t('plan.hard'),
  },
  {
    id: 4,
    value: 3,
    title: i18n.t('plan.very_hard'),
  },
];

// language config
export const LABELSRADAR = [
  i18n.t('plan.total'),
  i18n.t('plan.pass_review'),
  i18n.t('plan.pass_interview'),
  i18n.t('plan.onboard'),
];
export const DEFAULT_STYLE_EXCEL = {
  fontName: 'Times New Roman',
  h: 'left',
  v: 'left',
};
export const locales = {
  en: enUS,
  vi: viVN,
};

//other config
export const DEFAULT_STATUS = 1;
export const DEFAULT_LIMIT_RECORDS_EXPORT = 500;
export const LINK_MAX_LENGTH = 2100;
export const RECENTLY_SELECT_LENGTH_MAX = 5;
export const POINT_LADDER = '10';
export const CHILDREN_NUMBER = 3;
export const CAREERS_LINK = 'https://careers.ossigroup.net/jobs-list/';
export const OVER_YEAR = 18;
export const PHYSIOGNOMY_LEVEL = [
  {
    value: 'A+',
    label: 'A+',
  },
  {
    value: 'A',
    label: 'A',
  },
  {
    value: 'A-',
    label: 'A-',
  },
  {
    value: 'B+',
    label: 'B+',
  },
  {
    value: 'B',
    label: 'B',
  },
  {
    value: 'B-',
    label: 'B-',
  },
  {
    value: 'C+',
    label: 'C+',
  },
  {
    value: 'C',
    label: 'C',
  },
  {
    value: 'C-',
    label: 'C-',
  },
  {
    value: 'D+',
    label: 'D+',
  },
  {
    value: 'D',
    label: 'D',
  },
  {
    value: 'D-',
    label: 'D-',
  },
  {
    value: 'E+',
    label: 'E+',
  },
  {
    value: 'E',
    label: 'E',
  },
  {
    value: 'E-',
    label: 'E-',
  },
];
