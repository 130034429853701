import { POINT_URL } from '../constants/api';
import axiosClient from './axiosClient';

const redemptionApi = {
  get(params) {
    return axiosClient.get(POINT_URL, { params });
  },
  edit(values) {
    return axiosClient.post(POINT_URL, values);
  },
};

export default redemptionApi;
