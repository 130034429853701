import { Table } from 'antd';
import { Excel } from 'antd-table-saveas-excel';
import React from 'react';
import { DEFAULT_STYLE_EXCEL } from '../../../../constants';
import ChartLayout from '../ChartLayout';
import './small-table.scss';

function SmallTable({ title, columns = [], data = [], loading = false }) {
  return (
    <ChartLayout
      chartTitle={title}
      onExport={() => {
        const excel = new Excel();
        // set font family to excel file
        excel
          .setTBodyStyle(DEFAULT_STYLE_EXCEL)
          .setTHeadStyle(DEFAULT_STYLE_EXCEL);

        const newColumns = columns.map(({ width, ...col }) => col);
        excel
          .addSheet(title)
          .addColumns(newColumns)
          .addDataSource(data)
          .saveAs(`${title.replace(/\s+/g, '-')}.xlsx`);
      }}
    >
      <Table
        className="small-table"
        bordered
        pagination={false}
        loading={loading}
        columns={columns}
        dataSource={data}
      />
    </ChartLayout>
  );
}

export default SmallTable;
