import { PLAN_URL } from '../constants/api';
import axiosClient from './axiosClient';
const planApi = {
  getAll(params) {
    const url = PLAN_URL;
    return axiosClient.get(url, { params });
  },
  getById(id, params) {
    const url = `${PLAN_URL}/${id}`;
    return axiosClient.get(url, { params });
  },
  fixPlan(id, data) {
    const url = `${PLAN_URL}/${id}`;
    return axiosClient.put(url, data);
  },
};
export default planApi;
