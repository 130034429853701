import { MinusCircleOutlined, PlusCircleFilled } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Slider, Tooltip } from 'antd';
import React from 'react';
import { GeneralSelect } from '../../../../../../components/Form';
import criteriaGroupApi from '../../../../../../api/criteriaGroupApi';
import { useTranslation } from 'react-i18next';
import criteriaApi from '../../../../../../api/criteriaApi';
import { useDispatch } from 'react-redux';
import { openDrawer } from '../../../../../../components/Drawer/slice/drawer';
import CriteriaForm from '../../../../../Settings/Criteria/components/CriteriaForm';
import { useTable } from '../../../../../../components/Table';
const CriteriaRequestForms = ({
  form,
  isDisabled,
  toggle,
  criteriaByGroup,
  idCriteriaGroup,
  setToggle,
  criteriaTooltip,
}) => {
  const title = 'Tiêu chí mới';
  const titleLower = typeof title === 'string' ? title.toLowerCase() : title;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const onAdd = () => {
    dispatch(
      openDrawer({
        modeText: {
          btn: `${t('common.create')}`,
          title: `${t('common.create')} ${titleLower}`,
        },
      }),
    );
  };
  // const { setFilter, fetchData } = useTable({
  //   getApi: criteriaApi.getAll,
  // });

  const marks = {
    0: '0',
    50: '50',
    100: {
      style: {
        color: '#f50',
      },
      label: <strong>100</strong>,
    },
  };

  return (
    <>
      <Form.List name="criterias">
        {(fields, { add, remove }) => (
          <>
            <div
              style={{
                maxHeight: '620px',
                overflowY: 'scroll',
                paddingRight: '4px',
                paddingBottom: '10px',
                marginBottom: '10px',
              }}
            >
              {fields.map(field => (
                <Row
                  gutter={{ sm: 12, xl: 24 }}
                  align="middle"
                  style={{ marginTop: 20 }}
                >
                  {/* tiêu chí */}
                  {criteriaTooltip ? (
                    <Tooltip
                      placement="top"
                      title="Bạn cần chọn nhóm tiêu chí trước"
                    >
                      <Col sm={24} xl={8}>
                        <GeneralSelect
                          name={[field.name, 0]}
                          valueKey="id"
                          contentKey="name"
                          style={{ marginBottom: 20 }}
                          idCriteria={idCriteriaGroup}
                          fetchedItems={criteriaByGroup}
                          onChange={() =>
                            field.name === fields.length - 1 && add()
                          }
                        />
                      </Col>
                    </Tooltip>
                  ) : (
                    <Col sm={24} xl={8}>
                      <GeneralSelect
                        name={[field.name, 0]}
                        valueKey="id"
                        contentKey="name"
                        style={{ marginBottom: 20 }}
                        idCriteria={idCriteriaGroup}
                        fetchedItems={criteriaByGroup}
                        onChange={() =>
                          field.name === fields.length - 1 && add()
                        }
                      />
                    </Col>
                  )}

                  <Col sm={20} xl={13}>
                    {/* điểm */}
                    <Form.Item {...field} name={[field.name, 1]}>
                      <Slider
                        min={0}
                        max={100}
                        step={1}
                        defaultValue={50}
                        style={{
                          width: 240,
                          marginBottom: 10,
                        }}
                        name={`criteria[${field.name}].points`}
                        marks={marks}
                      />
                    </Form.Item>
                  </Col>

                  <Col sm={4} xl={3}>
                    <Tooltip placement="top" title="Xóa tiêu chí">
                      <MinusCircleOutlined
                        onClick={() => remove(field.name)}
                        style={{
                          verticalAlign: 'top',
                        }}
                      />
                    </Tooltip>
                  </Col>
                </Row>
              ))}
            </div>

            <Row
              gutter={{ sm: 12, xl: 24 }}
              align="middle"
              style={{ marginTop: 27 }}
            >
              <Col sm={24} xl={10}>
                <Form.Item>
                  <Button
                    onClick={onAdd}
                    type="primary"
                    icon={<PlusCircleFilled />}
                    className="uppercase"
                    style={{ width: '100%' }}
                  >
                    {`${t('common.create')} ${title}`}
                  </Button>
                  <CriteriaForm
                    setToggle={setToggle}
                    toggle={toggle}
                    addApi={criteriaApi.create}
                    editApi={criteriaApi.edit}
                    deleteElementApi={criteriaApi.deleteElement}
                    // fetchData={() => {}}
                    // setFilter={() => {}}
                    idCriteria={idCriteriaGroup}
                  />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Form.List>
    </>
  );
};

export default CriteriaRequestForms;
