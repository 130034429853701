import { Col, Form } from 'antd';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CvStatusSelect,
  CvStepSelect,
  FormInput,
} from '../../../../components/Form';
import Filter from '../../../../components/Table/Filter';

function MailFilter(props) {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  return (
    <Filter {...props} form={form}>
      <Col sm={12} md={6} xxl={4}>
        <CvStepSelect label={t('cv.step')} name="cv_step" mode="multiple" />
      </Col>
      <Col sm={12} md={6} xxl={4}>
        <CvStatusSelect
          label={t('cv.status')}
          name="cv_status"
          mode="multiple"
        />
      </Col>
      <Col sm={14} md={8} xxl={6}>
        <FormInput
          label={t('common.keyword')}
          placeholder={t('common.keywordPlaceholder')}
          name="keyword"
        />
      </Col>
    </Filter>
  );
}

export default memo(MailFilter);
