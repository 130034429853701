import { AutoComplete } from 'antd';
import { memo, useEffect, useState } from 'react';
import { useFetchHistoryData } from '../../Hooks/FetchApi';
import FormItem from '../FormItem';

const SearchWithSuggestions = memo(props => {
  const { form, label, name, className, site, renderItem, ...inputProps } =
    props;
  const [options, setOptions] = useState([]);
  const { handleGetHistorySearch, handleClearData, historyData } =
    useFetchHistoryData({ site });

  const formItemProps = {
    label,
    form,
    name,
    className,
  };

  useEffect(() => {
    if (renderItem) {
      setOptions(historyData.map(item => renderItem(item.keyword)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historyData]);

  const onInputSearch = value => {
    if (renderItem) {
      const itemsInCludeValue = historyData.filter(item =>
        item.keyword.toLocaleLowerCase().includes(value.toLocaleLowerCase()),
      );
      setOptions(itemsInCludeValue.map(item => renderItem(item.keyword)));
    }
  };

  return (
    <FormItem {...formItemProps}>
      <AutoComplete
        {...inputProps}
        options={options}
        className="search-suggestions"
        onFocus={handleGetHistorySearch}
        onSearch={onInputSearch}
        onBlur={handleClearData}
      />
    </FormItem>
  );
});

export default SearchWithSuggestions;
