import { Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/images/login/logoHivetech.png';
import LoginBg from './LoginBg';

function LoginLayout({ children }) {
  const { t } = useTranslation();

  return (
    <div className="login">
      <LoginBg />
      <img className="logo" src={logo} alt="HiveTech Logo" />
      <div className="login-form">
        <Typography.Title level={1}>{t('login.header')}</Typography.Title>
        <div className="login-form__content">{children}</div>
      </div>
    </div>
  );
}

export default LoginLayout;
