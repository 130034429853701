import { FormItem } from '../';
import { Editor } from '../../CustomBase';

export default function FormEditor(props) {
  const {
    disabled,
    height,
    hasSuggestions,
    initialValue,
    formItemClassName,
    menubar,
    ...formItemProps
  } = props;
  const editorProps = {
    disabled,
    height,
    hasSuggestions,
    initialValue,
    menubar,
  };

  return (
    <FormItem
      {...formItemProps}
      className={`${formItemClassName} form-item-editor`}
    >
      <Editor {...editorProps} />
    </FormItem>
  );
}
