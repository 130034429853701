import { Drawer, Form, message, Modal } from 'antd';
import { isFunction } from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_FILTER } from '../../constants';
import { SubmitBtn } from '../Form';
import { closeDrawer } from './slice/drawer';
import './styles.scss';

const PopUp = memo(
  ({
    children,
    modal,
    title,
    onClose,
    visible,
    bodyStyle,
    getContainer,
    width,
    className,
    ...props
  }) => {
    return modal ? (
      <Modal
        title={title}
        visible={visible}
        onCancel={onClose}
        footer={null}
        centered
        className={`addCv custom-modal ${className ?? ''}`}
        bodyStyle={bodyStyle}
        width={width || '70%'}
        getContainer={getContainer}
        {...props}
      >
        {children}
      </Modal>
    ) : (
      <Drawer
        title={title}
        placement="right"
        onClose={onClose}
        visible={visible}
        forceRender
        width={width || '20%'}
        zIndex={2000}
        className={className}
        getContainer={getContainer}
        {...props}
      >
        {children}
      </Drawer>
    );
  },
);

export default function GeneralDrawer({
  form,
  FormContent,
  children,
  onFinish,
  fetchData,
  fillData,
  addApi,
  setFilter,
  editApi,
  idKey,
  catchCallback,
  transformValues,
  height = 750,
  width = '30%',
  modal = true,
  fullscreenClassName,
  requiredFields = [],
  className,
  idCriteria,
  toggle,
  setToggle,
}) {
  const bodyStyle = {};
  if (height) {
    bodyStyle.minHeight = height;
  }
  const { isOpened, modeText, mode, initial } = useSelector(
    state => state.drawer,
  );
  const { isFullscreen } = useSelector(state => state.common);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [loadingBtn, setLoadingBtn] = useState(false);

  useEffect(() => {
    if (fillData) fillData();
    //React Hook useEffect has a missing dependency: 'fillData'
    //eslint-disable-next-line
  }, [isOpened]);

  const onFinishDefault = async values => {
    if (mode === 'add' && addApi) {
      await addApi({ ...values, id_criteria_group: idCriteria });
      //await addApi(values);
    }
    const id = initial?.[idKey] || initial?.id;
    if (mode === 'edit' && editApi && id) {
      await editApi(id, values);
    }
  };

  const onFinishForm = async values => {
    setLoadingBtn(true);
    try {
      for (let key in values) {
        if (typeof values[key] === 'string') values[key] = values[key].trim();
      }
      if (transformValues) values = transformValues(values);
      const finish = onFinish || onFinishDefault;
      await finish(values);
      if (mode === 'add' && setFilter) setFilter(DEFAULT_FILTER); //li do gay lôi
      // if (fetchData) {
      //   fetchData();
      // }
      setLoadingBtn(false);
      dispatch(closeDrawer());
      message.success(`${modeText.title} ${t('common.success')}`);
      setToggle(!toggle);
    } catch (e) {
      setLoadingBtn(false);
      if (catchCallback) catchCallback(e);
      console.log(e);
    }
  };

  return (
    <PopUp
      modal={modal}
      title={modeText.title}
      placement="right"
      onClose={() => dispatch(closeDrawer())}
      visible={isOpened}
      bodyStyle={bodyStyle}
      // forceRender
      width={width}
      zIndex={2000}
      className={className}
      getContainer={
        isFullscreen
          ? document.querySelector(`.${fullscreenClassName}`)
          : document.body
      }
    >
      <Form
        form={form}
        layout="vertical"
        className="d-flex standard-form"
        onFinish={onFinishForm}
        style={{ flexDirection: 'column', minHeight: 'inherit' }}
      >
        <div className="content-wrapper">
          {children}
          {isFunction(FormContent) && <FormContent />}
        </div>
        <SubmitBtn
          style={{ marginTop: 'auto', marginBottom: 0 }}
          form={form}
          loading={loadingBtn}
          requiredFields={requiredFields}
          create={mode === 'add'}
        >
          {modeText.btn}
        </SubmitBtn>
      </Form>
    </PopUp>
  );
}
