import { Tooltip } from 'antd';
import i18n from '../../translation/i18n';
import {
  calcPercent,
  convertDateFormat,
  displayPercent,
  getPriority,
  levelsObjToString,
} from '../../utils/formatData';

export const DEFAULT_SUMMARY_COL = {
  priority: '',
  target: 0,
  total_cv: 0,
  interview_cv: 0,
  pass_cv: 0,
  offer_cv: 0,
  offer_success: 0,
  ratio_onboard_offer: 0,
  ratio_offer_request: 0,
  ratio_onboard_request: 0,
  deadline: '',
  fail_job: 0,
  rest: 0,
};

export const createDashboardColumns = (isExport = false, isRequest = true) => {
  const cols = [
    {
      title: i18n.t('role.department'),
      dataIndex: 'department_title',
      onCell: () => ({
        style: {
          overflowWrap: 'unset',
        },
      }),
      summary: true,
      type: 'allWithoutSort',
      fixed: 'left',
    },
    {
      title: i18n.t('statistic.position'),
      dataIndex: 'positions_title',
      summary: true,
      type: 'allWithoutSort',
      ellipsis: true,
      fixed: 'left',
      render: value => (
        <Tooltip placement="top" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: i18n.t('statistic.level'),
      dataIndex: isRequest ? 'levels' : 'level_title',
      type: 'allWithoutSort',
      fixed: 'left',
      render: levels => {
        if (!isRequest) {
          return levels;
        }
        const value = levelsObjToString(levels);
        return value ? (
          !isExport ? (
            <Tooltip placement="top" title={value}>
              {value.slice(0, 14)}...
            </Tooltip>
          ) : (
            value
          )
        ) : (
          '-'
        );
      },
    },
    {
      title: i18n.t('statistic.number_of_cv'),
      dataIndex: 'total_cv',
      type: 'allWithoutSort',
    },
    {
      title: i18n.t('statistic.number_of_cv_joined_interview'),
      dataIndex: 'interview_cv',
      type: 'allWithoutSort',
    },
    {
      title: i18n.t('statistic.number_of_cv_passed_interview'),
      dataIndex: 'pass_cv',
      type: 'allWithoutSort',
    },
    {
      title: i18n.t('statistic.number_of_uv_offered'),
      dataIndex: 'offer_cv',
      type: 'allWithoutSort',
    },
    {
      title: i18n.t('statistic.offer_successfully'),
      dataIndex: 'offer_success',
      type: 'allWithoutSort',
    },
    {
      title: i18n.t('statistic.ratio_onboard_offer'),
      dataIndex: 'ratio_onboard_offer',
      render(_, item) {
        return displayPercent(item.onboard_cv, item.offer_cv);
      },
      type: 'allWithoutSort',
    },
    {
      title: i18n.t('request.deadline'),
      dataIndex: 'deadline',
      render: convertDateFormat,
      type: 'allWithoutSort',
    },
    {
      title: i18n.t('statistic.number_of_failed_people'),
      dataIndex: 'fail_job',
      type: 'allWithoutSort',
    },
    {
      title: i18n.t('statistic.list_of_uv_work'),
      dataIndex: 'employees',
      ellipsis: true,
      render: text => {
        const value = text && isRequest ? text.replace(/[[\]"]/g, '') : text;
        return !isExport ? (
          <Tooltip placement="top" title={value}>
            {value}
          </Tooltip>
        ) : (
          value
        );
      },
      type: 'allWithoutSort',
    },
  ];
  if (isRequest) {
    cols.splice(
      3,
      0,
      {
        title: i18n.t('statistic.prioritize'),
        dataIndex: 'priority',
        render: getPriority,
        type: 'allWithoutSort',
      },
      {
        title: i18n.t('statistic.number_of_request'),
        dataIndex: 'target',
        type: 'allWithoutSort',
      },
    );
    cols.splice(
      11,
      0,
      {
        title: i18n.t('statistic.ratio_offer_request'),
        dataIndex: 'ratio_offer_request',
        render(_, item) {
          return calcPercent(item.offer_cv, item.target);
        },
        type: 'allWithoutSort',
      },
      {
        title: i18n.t('statistic.ratio_onboard_request'),
        dataIndex: 'ratio_onboard_request',
        type: 'allWithoutSort',

        render(_, item) {
          return displayPercent(item.onboard_cv, item.target);
        },
      },
    );
    cols.splice(cols.length - 1, 0, {
      title: i18n.t('statistic.rest'),
      dataIndex: 'rest',
      type: 'allWithoutSort',

      render(_, row) {
        const minus = row.target - row.onboard_cv;
        const value = !minus || minus < 0 ? 0 : minus;
        return value;
      },
    });
  }
  const exportCols = isExport
    ? isExport?.map((col, i) => {
        return { ...col, ...cols[i] };
      })
    : [];

  return isExport ? exportCols : cols;
};
