import { ClearOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Form, Row, Tooltip } from 'antd';
import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DEFAULT_FILTER } from '../../../constants';
import { changeFilter } from '../../../utils/changeFilterStatistic';
import './style.scss';

export default memo(function Filter({
  form,
  FormContent,
  setFilter,
  filter,
  submit,
  defaultFilter,
  initialValues = () => undefined,
  hasClean = true,
  children,
}) {
  const { isFullscreen } = useSelector(state => state.common);
  const { t } = useTranslation();

  useEffect(() => {
    form?.setFieldsValue({
      ...(isFullscreen ? changeFilter(filter) : filter),
      ...initialValues(filter),
    });
    //React Hook useEffect has missing dependencies: 'initialValues' and 'isFullscreen'
    //eslint-disable-next-line
  }, [filter, form]);

  const handleSearch = values => {
    setFilter({ ...filter, ...values });
  };

  return (
    <div className="table-filter">
      <Form form={form} onFinish={submit || handleSearch} layout="vertical">
        <Row className="field-row" gutter={[10, 10]}>
          {FormContent && <FormContent />}
          {children}
          {hasClean && (
            <Tooltip title={t('common.clearFilter')}>
              <Button
                className="cleaner"
                onClick={() => {
                  form.resetFields();
                  const newFilter = {
                    ...DEFAULT_FILTER,
                    ...defaultFilter,
                    orderby: filter.orderby,
                  };
                  if (filter.orderby) newFilter.orderby = filter.orderby;
                  setFilter(newFilter);
                }}
                icon={<ClearOutlined />}
              />
            </Tooltip>
          )}
          <Button type="primary" htmlType="submit" icon={<SearchOutlined />} />
        </Row>
      </Form>
    </div>
  );
});
