import { DoubleRightOutlined, PlusCircleFilled } from '@ant-design/icons';
import { Button, Drawer } from 'antd';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import requestApi from '../../api/requestApi';
import NoPermission from '../../components/NoPermission';
import { useTable } from '../../components/Table';
import { CAREERS_LINK } from '../../constants';
import { DEFAULT_PAGENUMBER } from '../../constants/requestPage';
import { hasPermission } from '../../utils/hasPermission';
import LayoutBreadcrumb from '../../components/Breadcrumb/LayoutBreadcrumb';
import SearchForm from './components/SearchForm';
import ContentRequestTable from './components/Table/components/TableContent';

function Request() {
  const { userInfor } = useSelector(state => state.auth);
  const { t } = useTranslation();
  const [visibleFilterDrawer, setVisibleFilterDrawer] = useState(false);
  const navigate = useNavigate();
  const breadcrumbNameMap = {
    '/request': t('sidebar.request'),
    '/request/add': t('request.createRequest'),
  };

  const { items, filter, setFilter, loadingTable, fetchData, totalRecord } =
    useTable({
      getApi: requestApi.getAll,
    });

  const handleFilter = (values, type) => {
    setFilter({
      ...values,
      page: DEFAULT_PAGENUMBER,
    });
    if (type === 'request-mobile') setVisibleFilterDrawer(false);
  };

  return hasPermission(userInfor, 'request', 'view') ? (
    <section className="request">
      <main className="plan">
        <LayoutBreadcrumb
          breadcrumbNameMap={breadcrumbNameMap}
          extra={[
            <Button
              key="1"
              type="primary"
              className="uppercase"
              onClick={() => {
                window.open(CAREERS_LINK, '_blank');
              }}
            >
              {t('request.jobList')}
              <DoubleRightOutlined />
            </Button>,
            hasPermission(userInfor, 'request', 'add') && (
              <Button
                key="2"
                type="primary"
                className="uppercase"
                onClick={() => navigate('/request/add')}
                icon={<PlusCircleFilled />}
              >
                {t('request.createRequest')}
              </Button>
            ),
          ]}
          component={
            <>
              <SearchForm
                setFilter={value => handleFilter(value, 'request-desktop')}
                filter={filter}
              />
              <ContentRequestTable
                items={items}
                filter={filter}
                setFilter={setFilter}
                loadingTable={loadingTable}
                fetchData={fetchData}
                totalRecord={totalRecord}
              />
            </>
          }
        />
      </main>
      {/* Filter for mobile */}
      <Drawer
        title={t('common.filter')}
        placement="right"
        onClose={() => setVisibleFilterDrawer(false)}
        visible={visibleFilterDrawer}
        width="90%"
        className="request-drawer"
      >
        <SearchForm
          setFilter={value => handleFilter(value, 'request-mobile')}
          filter={filter}
        />
      </Drawer>
    </section>
  ) : (
    <NoPermission />
  );
}

export default memo(Request);
