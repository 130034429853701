import i18next from 'i18next';

export const cols = [
  { type: 'id', sorter: undefined, align: 'center' },
  {
    title: i18next.t('criteriaGroup.name'),
    type: 'title',
    dataIndex: 'name',
  },
  {
    type: 'tooltip',
    dataIndex: 'description',
    sorter: undefined,
  },
  {
    type: 'status',
  },
  { type: 'action', width: 100 },
];
