import { Col, Row } from 'antd';
import React, { useEffect } from 'react';
import CVReviewApi from '../../../api/CVReviewApi';
import withStepFrame from './withStepFrame';

function CvReview(props) {
  const { Frame, Notes, Status, setConfig, step, status } = props;

  useEffect(() => {
    setConfig({
      api: CVReviewApi.getById,
      submitApi: CVReviewApi.post,
      submitObject: values => {
        return values;
      },
    });
    //React Hook useEffect has a missing dependency: 'setConfig'
    //eslint-disable-next-line
  }, []);
  return (
    <Frame>
      <Row gutter={20}>
        <Col span={12}>
          <Notes />
        </Col>
        <Col span={12}>
          <Status step={step} status={status} />
        </Col>
      </Row>
    </Frame>
  );
}

export default withStepFrame(CvReview);
