import { Col, Form, Select } from 'antd';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  LevelSelect,
  PositionSelect,
  UserSelect,
} from '../../../components/Form';
import PrioritySelect from '../../../components/Form/Selects/PrioritySelect';
import {
  filterOption,
  LIST_REQUEST_STATUS,
} from '../../../constants/requestPage';
import { Filter } from '../../../components/Table';
import SearchWithSuggestions from '../../../components/Form/SearchWithSuggestions';
import CompanySelect from '../../../components/Form/Selects/CompanySelect';

const { Option } = Select;

function SearchForm(props) {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const renderItem = title => ({
    value: title,
    label: <span>{title}</span>,
  });

  return (
    <Filter {...props} form={form}>
      <Col sm={8} lg={6} xl={4}>
        <CompanySelect
          label={t('sidebar.company')}
          name="company_id"
          mode="multiple"
        />
      </Col>
      <Col sm={8} lg={6} xl={4}>
        <PositionSelect
          label={t('request.position')}
          name="position_id"
          mode="multiple"
        />
      </Col>
      <Col sm={8} lg={6} xl={4}>
        <UserSelect
          label={t('request.requestor')}
          name="requestor_id"
          mode="multiple"
        />
      </Col>
      <Col sm={8} lg={6} xl={4}>
        <LevelSelect name="level_id" mode="multiple" />
      </Col>
      <Col sm={8} lg={6} xl={4}>
        <PrioritySelect
          label={t('request.priority')}
          name="priority"
          mode="multiple"
        />
      </Col>
      <Col sm={8} lg={6} xl={4}>
        <Form.Item name="status" label={t('request.status')}>
          <Select
            mode="multiple"
            className="request__select--input"
            showArrow
            getPopupContainer={trigger => trigger.parentNode}
            maxTagCount="responsive"
            placeholder={t('request.placeholderForRequestForm')}
            filterOption={filterOption}
          >
            {LIST_REQUEST_STATUS.map(req => (
              <Option key={req.id} value={req.value}>
                {req.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>

      <Col sm={8} lg={6} xl={4}>
        <SearchWithSuggestions
          label={t('request.search')}
          placeholder={t('request.requestSearchPlaceholder')}
          name="keyword"
          site="request"
          renderItem={renderItem}
        />
      </Col>
    </Filter>
  );
}

export default memo(SearchForm);
