import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { UserSelect } from '../../../../components/Form';
import { hasPermission } from '../../../../utils/hasPermission';

function RequestorSelect({ name = 'requestor_id', disabled, ...props }) {
  const userInfor = useSelector(state => state.auth.userInfor);
  const { t } = useTranslation();

  return (
    <UserSelect
      name={name}
      label={t('request.requestor')}
      disabled={!hasPermission(userInfor, 'request', 'all') || disabled}
      required
      {...props}
    />
  );
}

export default RequestorSelect;
