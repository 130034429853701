import { Form, message } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import requestApi from '../../../api/requestApi';
import { handleRequestData } from './handleRequestData';
import LayoutBreadcrumb from '../../../components/Breadcrumb/LayoutBreadcrumb';
import RequestForm from './RequestForm';
import BtnGroupSubmit from './Table/components/BtnGroupSubmit';
export default function AddRequest() {
  const { t } = useTranslation();
  const breadcrumbNameMap = {
    '/request': t('sidebar.request'),
    '/request/add': t('request.createRequest'),
  };

  const [form] = Form.useForm();
  const navigate = useNavigate();

  const handleCreateRequest = useCallback(
    async values => {
      const newValues = await handleRequestData(values);
      try {
        await requestApi.create(newValues);
        message.success(t('request.createRequestSuccess'));
        form.resetFields();
        navigate('/request');
      } catch (error) {
        if (!error.status) {
          message.error(t('request.lostConnect'));
        } else {
          message.error(t('request.failToCreateRequest'));
        }
      }
    },
    [t, form, navigate],
  );

  return (
    <Form
      form={form}
      layout="vertical"
      name="form_in_modal"
      className="request__form"
      onFinish={handleCreateRequest}
    >
      <LayoutBreadcrumb
        breadcrumbNameMap={breadcrumbNameMap}
        extra={[<BtnGroupSubmit form={form} />]}
        component={
          <RequestForm titleForm={t('request.createRequest')} form={form} />
        }
      />
    </Form>
  );
}
