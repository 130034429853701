import {
  DASHBOARD_API,
  DASHBOARD_CV_URL,
  LEVEL_API,
  POSITION_API,
} from '../constants/api';
import axiosClient from './axiosClient';

const dashboardApi = {
  getDashBoardCV(params) {
    return axiosClient.get(DASHBOARD_CV_URL, { params });
  },
  getAll(params) {
    const url = DASHBOARD_API;
    return axiosClient.get(url, { params });
  },
  getDataTable(params) {
    const url = DASHBOARD_API;
    return axiosClient.get(url, { params });
  },
  getFilterPositions(params) {
    const url = POSITION_API;

    return axiosClient.get(url, { params });
  },
  getLevelFilter(params) {
    const url = LEVEL_API;

    return axiosClient.get(url, { params });
  },
};
export default dashboardApi;
