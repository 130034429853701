import { Col, Row } from 'antd';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PreOfferCV from '../../../api/preOffer';
import { statusPreInterviewLabel } from '../constant/stepStatusLabel';
import withStepFrame from './withStepFrame';

function PreOffer(props) {
  const { form, Frame, Notes, Status, setConfig, step, status } = props;
  const { id } = useParams();

  useEffect(() => {
    setConfig({
      api: PreOfferCV.getById,
      submitApi: PreOfferCV.post,
      submitObject: values => {
        return values;
      },
    });
    //React Hook useEffect has missing dependencies: 'checkList' and 'setConfig'
    //eslint-disable-next-line
  }, [form, id]);

  return (
    <Frame>
      <Row gutter={20}>
        <Col span={12}>
          <Notes />
        </Col>
        <Col span={12}>
          <Status
            step={step}
            status={status}
            statusLabel={statusPreInterviewLabel}
          />
        </Col>
      </Row>
    </Frame>
  );
}

export default withStepFrame(PreOffer);
