import { CalendarOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import cvApi from '../../../api/cvApi';
import { openDrawer } from '../../../components/Drawer/slice/drawer';
import { BoxShadow } from '../../../components/Layout';
import CalendarEventForm from '../../../components/MsCalendar/CalendarEventForm';
import { MsLoginAndLogoutBtn } from '../../../components/MsCalendar/MsLoginAndLogoutBtn';
import { breadcrumbsCv } from '../../../constants/newAddCv';
import LayoutBreadcrumb from '../../../components/Breadcrumb/LayoutBreadcrumb';
import { getStep } from '../constant';
import useFetchEmail from '../hooks/useFetchEmail';
import '../scss/cvDetail.scss';
import CvReview from '../steps/CvReview';
import HrReview from '../steps/HrReview';
import Interview from '../steps/Interview';
import Offer from '../steps/Offer';
import Onboard from '../steps/Onboard';
import Physiognomy1 from '../steps/Physiognomy1';
import Physiognomy2 from '../steps/Physiognomy2';
import PreInterview from '../steps/PreInterview';
import PreOffer from '../steps/PreOffer';
import Probation from '../steps/Probation';
import CvInfo from './CvInfo';
import HistoryTable from './HistoryTable';
import StepFlow from './StepFlow';

export default function Detail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfor } = useSelector(state => state.auth);
  const permission = userInfor?.permission?.cv;
  const { t } = useTranslation();

  const [cv, setCv] = useState({});
  const [reload, setReload] = useState(false);
  const [showHis, setShowHis] = useState(false);

  const [currentStep, setCurrentStep] = useState(null);

  const { step, status } = cv;
  const detailStep = getStep(step, status);
  const { stepValue, statusValue } = detailStep || {};

  const fetchCv = async () => {
    try {
      const res = await cvApi.getById(id);
      setCv(res.data.data);
    } catch (e) {
      console.log('e :', e);
    }
  };

  useEffect(() => {
    fetchCv();
    //eslint-disable-next-line
  }, [reload]);

  const { emailTemplate, totalHistoryEmail } = useFetchEmail(
    id,
    cv?.step,
    cv?.status,
  );

  const handleOpenSendEmail = () => {
    navigate(`/cv/${cv.id}/sendEmail`);
  };
  // End: email handle

  const ReminderBtn = props => (
    <Button {...props} icon={<CalendarOutlined />}>
      {t('reminder.reminderBtn')}
    </Button>
  );
  const handleAddReminder = () => {
    dispatch(
      openDrawer({
        modeText: {
          btn: t('common.add'),
          title: t('reminder.addReminder'),
        },
      }),
    );
  };

  const WhichStep = useMemo(() => {
    const props = {
      currentStep,
      cv,
      reload: () => setReload(prev => !prev),
      reloadValue: reload,
      step: stepValue,
      status: statusValue,
      isDecision: permission.decision,
    };

    switch (currentStep) {
      case 1:
        return <HrReview {...props} />;
      case 2:
        return <Physiognomy1 {...props} />;
      case 3:
        return <CvReview {...props} />;
      case 4:
        return <Physiognomy2 {...props} />;
      case 5:
        return <PreInterview {...props} />;
      case 6:
        return <Interview {...props} />;
      case 7:
        return <PreOffer {...props} />;
      case 8:
        return <Offer {...props} />;
      case 9:
        return <Onboard {...props} />;
      case 10:
        return <Probation {...props} />;
      default:
        return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, reload, step, status, permission.decision]);

  return (
    <LayoutBreadcrumb
      breadcrumbNameMap={{
        ...breadcrumbsCv,
        [`/cv/${cv.id}`]: `${cv.fullname || ''} (${id})`,
      }}
      extra={[
        <MsLoginAndLogoutBtn
          LoginBtn={props => <ReminderBtn {...props} />}
          LogoutBtn={props => (
            <ReminderBtn
              {...props}
              onClick={handleAddReminder}
              loading={Object.keys(cv).length === 0}
            />
          )}
        />,
        emailTemplate?.isauto === 0 && (
          <Button type="primary" onClick={handleOpenSendEmail}>
            <SendOutlined />
            {t('updateCv.send_mail')}
          </Button>
        ),
      ]}
      component={
        <div className="cv-detail-temp">
          {/* Mount reminder popup */}
          <CalendarEventForm cv={cv} />
          <div className="cv-detail">
            {/* Begin: step flow */}
            <BoxShadow>
              <StepFlow
                cv={cv}
                stepState={{
                  currentStep,
                  setCurrentStep,
                }}
              />
            </BoxShadow>
            {/* Begin: Information */}
            <Row gutter={[12, 12]} className="main-cv-content-container">
              <Col sm={24} xl={12}>
                <div className="cv-information">
                  <BoxShadow>
                    <CvInfo
                      cv={cv}
                      totalHistoryEmail={totalHistoryEmail}
                      setCurrentStep={setCurrentStep}
                      fetchCv={fetchCv}
                    />
                  </BoxShadow>
                </div>
              </Col>
              <Col sm={24} xl={12} className="step-input-wrap ">
                <BoxShadow>
                  <div className="step-main">{WhichStep}</div>
                </BoxShadow>
              </Col>
            </Row>
            <Button
              className="uppercase show-history-btn"
              onClick={() => {
                setShowHis(true);
              }}
            >
              {t('cvDetail.showModifiedHistory')}
            </Button>
            {/* Begin: history table*/}
            <Drawer
              title={t('cvDetail.modifiedHistory')}
              placement="bottom"
              onClose={() => {
                setShowHis(false);
              }}
              style={{ boxSizing: 'border-box' }}
              height="38%"
              visible={showHis}
            >
              <HistoryTable justReload={reload} />
            </Drawer>
          </div>
        </div>
      }
    />
  );
}
