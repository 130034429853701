import { LoadingOutlined } from '@ant-design/icons';
import { Form, message, Modal, Table, Upload } from 'antd';
import 'moment/locale/en-au';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import importApi from '../../../api/importApi';
import requestApi from '../../../api/requestApi';
import ImportImage from '../../../assets/images/cvManagement/import.svg';
import { GeneralSelect } from '../../../components/Form';
function Import({ visible, fullScreen, setVisible, fetchData }) {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(0);
  const [valid, setValid] = useState(0);
  const [fileList, setFileList] = useState([]);
  const [requests, setRequests] = useState([]);
  const [fileErrorOnFrontEnd, setFileErrorOnFrontEnd] = useState(null);
  const ref = useRef();

  // Fetch requests list
  useEffect(() => {
    const getRequests = async () => {
      form.resetFields();
      const res = await requestApi.getAll({ limit: 20 });
      const requests = res.data.data.map(request => {
        return {
          ...request,
          content: `Requestor: ${request.requestor_id} - Number: ${request.target} - Deadline: ${request.month}/${request.year}`,
        };
      });
      setRequests(requests);
    };
    if (visible) {
      getRequests();
    }
  }, [visible, form]);

  const onClose = () => {
    form.resetFields();
    setVisible(false);
    setValid(null);
    setFileErrorOnFrontEnd(null);
    setError(null);
    setFileList([]);
  };

  function beforeUpload(file) {
    const request = form.getFieldValue('request_id');
    if (!request) {
      setFileErrorOnFrontEnd(null);
      form.submit();
    } else {
      const isExcel =
        file.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        file.type === 'application/pdf';
      if (!isExcel) {
        setFileList([]);
        setFileErrorOnFrontEnd(t('importCv.invalidFile'));
      } else {
        setFileList([file]);
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        setFileErrorOnFrontEnd(t('importCv.invalidFile'));
      }
      return isExcel && isLt2M;
    }
  }

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };
  const handleImport = async file => {
    const request = form.getFieldValue('request_id');
    if (request) {
      setLoading(true);
      try {
        const resp = await importApi.postFile(file, request);
        setLoading(false);
        fetchData();
        if (resp.data?.error?.length > 0) {
          setError(resp.data.error);
          setValid(resp.data.data);
        } else {
          setValid(resp.data.data);
          setError(null);
          message.success(t('importCv.importSuccessfully'));
        }
        form.resetFields();
        setFileList([]);
        setFileErrorOnFrontEnd(null);
      } catch (error) {
        setLoading(false);

        if (!error.status) {
          message.error(t('request.lostConnect'));
        } else {
          message.error(t('cv.fail_to_import'));
        }
      }
    }
  };

  const columns = [
    {
      title: t('importCv.row'),
      dataIndex: 'row',
      align: 'left',
    },
    {
      title: t('common.fullName'),
      dataIndex: 'fullname',
      align: 'left',
    },
    {
      title: t('common.description'),
      dataIndex: 'message',
      align: 'left',
    },
  ];

  const uploadButton = (
    <div className="upload">
      <div
        style={{
          color: 'rgba(0, 0, 0, 0.5)',
          textAlign: 'center',
          flexGrow: 1,
        }}
      >
        {t('importCv.dragText')}
      </div>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '40px',
          }}
        >
          <img
            style={{ width: '30px', height: '27px' }}
            src={ImportImage}
            alt="import"
          />
        </div>
      )}
    </div>
  );
  const handleRemoveFile = file => {
    setFileList([]);
  };
  return (
    <Modal
      title={t('cv.import')}
      className="import-modal"
      width={600}
      visible={visible}
      onCancel={onClose}
      footer={false}
      getContainer={
        fullScreen ? document.querySelector('.full-screen') : document.body
      }
    >
      <Form form={form} layout="vertical" className="standard-form">
        <GeneralSelect
          name="request_id"
          label={t('updateCv.request')}
          required
          valueKey="id"
          contentKey="content"
          fetchedItems={requests}
        />
        <Upload
          name="cv"
          listType="text"
          className="file-uploader"
          showUploadList={true}
          beforeUpload={beforeUpload}
          accept=".xlsx"
          ref={ref}
          action={handleImport}
          customRequest={dummyRequest}
          maxCount={1}
          fileList={fileList}
          onRemove={handleRemoveFile}
        >
          {uploadButton}
        </Upload>
        <p className="download-link">
          <a href="/CV-Import.xlsx" download>
            {t('importCv.download')}
          </a>{' '}
          {t('importCv.template')}
        </p>
        <p className="note">
          {' '}
          <i>{t('importCv.noteLines')}</i>
        </p>
        {error?.length || valid ? (
          <div className="preview">
            <h3>{t('importCv.result')}</h3>
            <p>
              {t('importCv.valid')} {`(${valid})`}
            </p>
            <p>
              {t('importCv.invalid')} {`(${error?.length || 0})`}
            </p>
            {error?.length > 0 && (
              <Table
                className="table-drawer"
                rowKey="row"
                columns={columns}
                dataSource={error}
                bordered
                pagination={false}
                scrollToFirstRowOnChange={true}
              />
            )}
          </div>
        ) : (
          ''
        )}
        {fileErrorOnFrontEnd && !error && (
          <div className="preview">
            <h3>{t('importCv.result')}</h3>
            <p style={{ marginTop: 20 }} className="text-center">
              {fileErrorOnFrontEnd}
            </p>
          </div>
        )}
      </Form>
    </Modal>
  );
}

export default Import;
