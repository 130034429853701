import { Col, Form, Row, Slider, Tooltip } from 'antd';
import React from 'react';
import { GeneralSelect } from '../../../components/Form';
import { CommentOutlined } from '@ant-design/icons';
import CvInfoReview from './CvInfoReview';
import { useState } from 'react';

const CvInfoCriteria = ({ field, form }) => {
  const [isReview, setReview] = useState(false);
  return (
    <>
      <Row
        key={field.name}
        gutter={{ sm: 12, xl: 20 }}
        align="middle"
        style={{ marginTop: 20 }}
      >
        <Col sm={24} xl={7}>
          <GeneralSelect
            name={[field.name, 0]}
            valueKey="id"
            contentKey="name"
            disabled
          />
        </Col>

        <Col sm={20} xl={13}>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              const thisField = getFieldValue(['criterias', field.name]);

              return (
                <Slider
                  className="custom-slider"
                  style={{
                    '--slider-left-2':
                      thisField[2] > thisField[1]
                        ? `${thisField[1]}%`
                        : `${thisField[2]}%`,
                    '--width':
                      thisField[2] > thisField[1]
                        ? `${thisField[2] - thisField[1]}%`
                        : `${thisField[1] - thisField[2]}%`,
                  }}
                  marks={{
                    [thisField[1]]: {
                      style: {
                        color: '#fb9122',
                        fontWeight: 'bold',
                      },
                      label: (
                        <strong>
                          <Tooltip placement="top" title="Điểm yêu cầu">
                            {thisField[1]}{' '}
                          </Tooltip>
                        </strong>
                      ),
                    },
                    [thisField[2]]: {
                      style: {
                        color: `${
                          thisField[2] < thisField[1] ? '#ff5c5c' : '#a7e13e'
                        }`,
                        fontWeight: 'bold',
                      },
                      label: (
                        <strong>
                          {' '}
                          <Tooltip placement="top" title="Điểm đánh giá">
                            {thisField[2]}{' '}
                          </Tooltip>
                        </strong>
                      ),
                    },
                  }}
                  defaultValue={[thisField[1], thisField[1]]}
                  onChange={value => {
                    const criteriasFieldValue = form.getFieldValue('criterias');
                    form.getFieldValue('criterias')[field.name][2] = value;

                    form.setFieldsValue({
                      criterias: criteriasFieldValue,
                    });
                  }}
                  trackStyle={{
                    backgroundColor:
                      thisField[2] < thisField[1] ? '#db0624' : '#a7e13e',
                  }}
                  railStyle={{
                    backgroundColor: 'grey',
                  }}
                />
              );
            }}
          </Form.Item>
        </Col>

        <Col sm={4} xl={4}>
          <Tooltip placement="right" title="Review">
            <CommentOutlined
              style={{
                fontSize: 20,
                color: '#fb9122',
                marginBottom: '20px',
              }}
              onClick={() => {
                setReview(!isReview);
              }}
            />
          </Tooltip>
        </Col>
        <Col sm={24} xl={24}>
          <CvInfoReview name={field.name} isReview={isReview} />
        </Col>
      </Row>
    </>
  );
};

export default CvInfoCriteria;
