import { EditOutlined, EllipsisOutlined } from '@ant-design/icons';
import { Button, message, Popover } from 'antd';
import moment from 'moment';
import qs from 'query-string';
import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import planApi from '../../../api/planApi';
import NoPermission from '../../../components/NoPermission';
import { TableMain, useTable } from '../../../components/Table';
import { createPlanDetailColumn } from '../../../constants/planPage';
import { hasPermission } from '../../../utils/hasPermission';
import LayoutBreadcrumb from '../../../components/Breadcrumb/LayoutBreadcrumb';
import { setDefaultForm } from '../reducer/plan-reducer';
import FilterForm from './FilterForm';
function PlanDetail() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const timeParams = qs.parse(location.search);
  const { filter, setFilter, items, loadingTable, fetchData, totalRecord } =
    useTable({
      getApi: planApi.getAll,
    });
  const newItems = items?.filter(item => item.status !== 4);
  useEffect(() => {
    setFilter({
      ...filter,
      month: timeParams.month || moment().format('MM'),
      year: timeParams.year || moment().format('YYYY'),
    });
    // warning filter and setFilter
    //eslint-disable-next-line
  }, [timeParams.month]);
  const { userInfor } = useSelector(state => state.auth);
  const checkRoleView = role => {
    if (!userInfor.permission || !userInfor.permission[role]) return false;
    return userInfor.permission[role]?.view;
  };

  const checkRoleEdit = role => {
    if (!userInfor.permission || !userInfor.permission[role]) return false;
    return userInfor.permission[role]?.edit;
  };
  const ContentAction = ({ item }) => {
    // add config to show message in fullscreen mode
    message.config({
      getContainer: node => {
        return node
          ? document.body
          : document.querySelector('.request__fullscreen');
      },
      duration: 2,
    });
    return (
      <div className="action-content">
        <Button
          icon={<EditOutlined />}
          onClick={() => handleNavigateToPlanEdit(item)}
          disabled={!checkRoleEdit('plan')}
        >
          {t('plan.edit')}
        </Button>
      </div>
    );
  };
  const breadcrumbNameMap = {
    '/plan': t('sidebar.plan'),
    '/plan/detail': `${t('plan.detail')} ${
      timeParams.month || moment().format('MM')
    }/${timeParams.year || moment().format('YYYY')}`,
    'plan/detail/edit': 'Chinh sua ke hoach',
  };

  const handleNavigateToPlanEdit = item => {
    if (item.id) {
      navigate(`edit/${item.id}?month=${item.month}&year=${item.year}`);
    }
    dispatch(setDefaultForm(item));
  };

  const cols = [
    ...createPlanDetailColumn(),
    {
      title: t('plan.action'),
      dataIndex: 'action',
      show: true,
      fixed: 'right',
      align: 'center',
      width: 100,
      render: (_, record) => {
        return (
          <div
            className="action-container"
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <Popover
              zIndex={999}
              content={<ContentAction item={record} />}
              trigger={'hover'}
              placement="bottom"
              getPopupContainer={() =>
                document.querySelector('.table-fullscreen')
              }
              overlayClassName="overlayActionContent"
            >
              <span className="action-icon">
                <EllipsisOutlined />
              </span>
            </Popover>
          </div>
        );
      },
      type: 'action',
    },
  ];
  const Filter = () => <FilterForm filter={filter} setFilter={setFilter} />;

  const handleClickRow = record => ({
    onClick() {
      handleNavigateToPlanEdit(record);
    },
  });

  return (
    <>
      {checkRoleView('plan') ? (
        <main className="plan">
          <LayoutBreadcrumb
            breadcrumbNameMap={breadcrumbNameMap}
            component={
              <>
                <FilterForm filter={filter} setFilter={setFilter} />
                <TableMain
                  className="plan__table"
                  removeDeleteMany
                  cols={cols}
                  callback={createPlanDetailColumn}
                  items={newItems}
                  title={t('plan.plan')}
                  fetchData={fetchData}
                  getApi={planApi.getAll}
                  editPermission={hasPermission(userInfor, 'plan', 'edit')}
                  filter={filter}
                  Filter={Filter}
                  setFilter={setFilter}
                  totalRecord={totalRecord}
                  loadingTable={loadingTable}
                  excelName="plan-templates"
                  onRow={handleClickRow}
                />
              </>
            }
          />
        </main>
      ) : (
        <NoPermission />
      )}
    </>
  );
}

export default memo(PlanDetail);
