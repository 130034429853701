import moment from 'moment-timezone';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormDatePicker } from '../../../../components/Form';
import { DISPLAY_FORMAT } from '../../../../global/constants';

function DeadlinePicker({ disabled, deadlineDays, ...props }) {
  const { t } = useTranslation();
  return (
    <FormDatePicker
      name="deadline"
      label={t('request.deadline')}
      hasFeedback={!disabled}
      required
      disabled={disabled}
      format={DISPLAY_FORMAT}
      disabledDate={current => {
        if (!deadlineDays) {
          return false;
        }
        const { daybefore, dayafter } = deadlineDays;
        const earlyDate = current <= moment().add(daybefore - 1, 'days');
        const lateDate = current >= moment().add(dayafter - 1, 'days');
        return earlyDate || lateDate;
      }}
      picker="date"
      {...props}
    />
  );
}

export default DeadlinePicker;
