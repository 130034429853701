import { logoImg, newWordImage, theWayImage } from './base64Image';

export const emailSignatureHtml = `<div class="elementToProof" style="font-family: Calibri,Arial,Helvetica,sans-serif; font-size: 12pt; color: rgb(0,0,0);">
<p>&nbsp;</p>
<p>&nbsp;</p>
<table style="font-family: 'Times New Roman';">
<tbody>
<tr>
<td class="elementToProof">
<table style="width: 550px; display: inline-block;" width="100%">
<tbody>
<tr>
<td>
<table style="max-width: 100%;" width="550">
<tbody>
<tr>
<td style="vertical-align: top; width: 10px;" rowspan="3" valign="top" width="10"><img class="x_x_EmojiInsert" style="border-radius: 4px; width: 120px; height: 120px; max-width: initial;" src="${logoImg}" alt="logo" width="120" height="120" data-imagetype="AttachmentByCid" data-custom="AAMkADNmNjkyZTE3LWRiYjQtNDFmOC05NmFmLTk3ZTAxYmJlZjliNwBGAAAAAADjTEvnNRxBRoTycp%2FPR6U5BwB74VD%2BDql5T5QFCyoJTbUIAAAAAAEPAAB74VD%2BDql5T5QFCyoJTbUIAACslOuFAAABEgAQAJZgQG2xdthMvqy26zyI6zY%3D" data-outlook-trace="F:2|T:2"></td>
<td style="vertical-align: top; width: 420px;" valign="top" width="420">
<table style="width: 449.156px;">
<tbody>
<tr>
<td style="padding-bottom: 5px; width: 449.156px;">
<table style="line-height: 1.6; font-family: Arial; font-size: 11px; color: rgb(78,75,76); padding-left: 2px; font-weight: normal;" align="left">
<tbody>
<tr>
<td style="padding-left: 0"><span style="color: rgb(192,92,1); margin: 0px; font-size: 12px; font-weight: bold;">Team HR of HiveTech .,JSC</span></td>
</tr>
</tbody>
</table>
<table style="line-height: 1.6; font-family: Arial; font-size: 11px; color: rgb(78,75,76); padding-left: 2px; font-weight: bold;" align="right">
<tbody>
<tr>
<td>&nbsp;</td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr>
<td style="padding: 5px 0px 8px; border-top: 1px solid rgb(192,92,1); border-bottom: 1px solid rgb(192,92,1); width: 449.156px;">
<table style="line-height: 1.6; font-family: Arial; font-size: 11px; color: rgb(78,75,76); padding-left: 2px; font-weight: normal;" align="left">
<tbody>
<tr>
<td><span style="font-weight: bold; font-size: 12px; color: rgb(192,92,1);">Address&nbsp;</span>&nbsp;<span style="font-size: 12px;">98 Ho&agrave;ng Quốc Việt, Cầu Giấy, H&agrave; Nội</span>&nbsp;</td>
</tr>
</tbody>
</table>
<table style="line-height: 1.6; font-family: Arial; font-size: 11px; color: rgb(78,75,76); padding-left: 2px; font-weight: normal;" align="left">
<tbody>
<tr>
<td><span style="font-weight: bold; font-size: 12px; color: rgb(192,92,1);">Phone&nbsp;</span>&nbsp;<a style="font-size: 12px;" href="tel:(+84) 24 666 026 12" data-auth="NotApplicable">(+84) 24 666 026 12</a></td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr>
<td style="width: 449.156px; padding-left: 0">
<table style="line-height: 1.6; font-family: Arial; font-size: 11px; color: rgb(78,75,76); padding-top: 10px; padding-left: 2px; font-weight: normal;" align="left">
<tbody>
<tr>
<td><span style="font-weight: bold; font-size: 12px; color: rgb(192,92,1);">Email&nbsp;</span><a style="font-size: 12px;" href="mailto:contact@hivetech.vn" data-auth="NotApplicable">hr@hivetech.vn&nbsp;</a></td>
</tr>
</tbody>
</table>
<table style="line-height: 1.6; font-family: Arial; font-size: 11px; color: rgb(78,75,76); padding-top: 10px; padding-left: 2px; font-weight: normal;" align="left">
<tbody>
<tr>
<td><span style="font-weight: bold; font-size: 12px; color: rgb(192,92,1);">Website&nbsp;</span>&nbsp;<a style="font-size: 12px;" href="https://hivetech.vn" data-auth="NotApplicable">https://www.hivetech.vn&nbsp;</a></td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
<div style="font-family: 'Times New Roman'; font-size: medium; text-align: start; clear: both; height: 0px!important;">&nbsp;</div>
<table style="font-family: 'Times New Roman';">
<tbody>
<tr>
<td>
<table style="max-width: 600px; margin: 0px 8px 0px 0px; color: gray; border-top: 1px solid gray; font-size: 13px;">
<tbody>
<tr>
<td>
<p style="margin-top: 0px; margin-bottom: 0px; font-size: 10px; margin: 0px; line-height: 12px; padding-top: 10px; font-family: Arial;">IMPORTANT: The contents of this email and any attachments are confidential. They are intended for the named recipient(s) only. If you have received this email by mistake, please notify the sender immediately and do not disclose the contents to anyone or make copies thereof.</p>
</td>
</tr>
</tbody>
</table>
<table style="max-width: 469px; margin: 8px 8px 0px 0px; font-size: 12px;">
<tbody>
<tr>
<td>
<table style="color: green;" width="100%">
<tbody>
<tr valign="top">
<td style="padding: 2px 6px 1px 3px; border-right: 1px solid rgb(229,229,229);" width="1%"><img class="x_x_EmojiInsert" src="${theWayImage}" data-imagetype="AttachmentByCid" data-custom="AAMkADNmNjkyZTE3LWRiYjQtNDFmOC05NmFmLTk3ZTAxYmJlZjliNwBGAAAAAADjTEvnNRxBRoTycp%2FPR6U5BwB74VD%2BDql5T5QFCyoJTbUIAAAAAAEPAAB74VD%2BDql5T5QFCyoJTbUIAACslOuFAAABEgAQAE7hy%2BYNRw1NqqQeRTc17Q0%3D" data-outlook-trace="F:2|T:2"></td>
<td style="padding: 1px 8px 4px; font-family: Arial; font-weight: normal;">Please consider your environmental responsibility. Before printing this e-mail message, ask yourself whether you really need a hard copy.</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
<table id="tableSelected1" style="box-sizing: border-box; width: 521.75px; height: 24px;">
<tbody>
<tr>
<td style="width: 34px; padding-right: 4px; box-sizing: border-box; height: 20px;" align="center"><a href="http://hivetech.vn/fresher-java-developer-full-time-2020/" data-auth="NotApplicable"><img class="x_x_EmojiInsert" style="width: 29px; height: 10px; max-width: initial;" src="${newWordImage}" width="29" height="10" data-imagetype="AttachmentByCid" data-custom="AAMkADNmNjkyZTE3LWRiYjQtNDFmOC05NmFmLTk3ZTAxYmJlZjliNwBGAAAAAADjTEvnNRxBRoTycp%2FPR6U5BwB74VD%2BDql5T5QFCyoJTbUIAAAAAAEPAAB74VD%2BDql5T5QFCyoJTbUIAACslOuFAAABEgAQAIJHR4VpgitEu%2Fam0mU5TxI%3D" data-outlook-trace="F:2|T:2"></a></td>
<td style="font-family: Arial; font-weight: bold; box-sizing: border-box; width: 445.75px; height: 20px;">We're recruiting&nbsp;<a style="font-weight: normal; color: rgb(34,34,34);" href="https://www.facebook.com/hivetechvietnam/jobs/" data-auth="NotApplicable">Join us on the quest building excellent teams</a></td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</div>`;
