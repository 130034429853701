import { Form, message } from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import GeneralDrawer from '../../../../components/Drawer/GeneralDrawer';
import { FormInput } from '../../../../components/Form';
import { DEFAULT_STATUS } from '../../../../constants';
import Status from '../../components/Status';
import { levelCriteriaRules, levelItemCriteriaRules } from '../constants';
import useCurrentBreakPoint from '../../../../hooks/useCurrentBreakPoint';
import { handleGetCommonModalWith } from '../../BasicTable/Template/constants';
import FormListCustom from './FormListCustom';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function CriteriaForm(props) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { currentBreakpoint } = useCurrentBreakPoint();
  const { initial, mode } = useSelector(state => state.drawer);

  let location = useLocation();

  const [currentData, setCurrentData] = useState({});

  const catchCallback = e => {
    const msg = e?.data?.message;
    const more = e?.data?.more;
    if (msg?.includes('exists') || more?.includes('Duplicate')) {
      message.error(t('user.updateWarn'));
    }
  };

  const fillData = () => {
    form.resetFields();

    const currentCiteria =
      initial?.criteria_elements?.map(item => ({
        name: item.name,
        id_criteria: item.id_criteria,
        id_element: item.id,
      })) || [];

    const currentDetail = {
      name: initial?.name || '',
      criteria_elements: currentCiteria,
      status: initial?.status || DEFAULT_STATUS,
      description: initial?.description,
    };

    const init = mode === 'add' ? {} : currentDetail;
    form.setFieldsValue({
      ...init,
      status: initial.status ?? DEFAULT_STATUS,
    });
  };

  const onFinish = async data => {
    if (location.pathname === '/request/add') {
      props.setToggle(!props.toggle);
    }
    const criteria_elements = data.criteria_elements;

    let newCriteriaData = {};
    criteria_elements?.forEach((item, index) => {
      const indexData = index.toString();
      newCriteriaData[indexData] = {
        id_criteria: criteria_elements?.[indexData]?.id_criteria || '',
        id_element: criteria_elements?.[indexData]?.id_element || '',
        name: item.name,
      };
    });
    const dataRequest = { ...data, criteria_elements: newCriteriaData };
    let deleteElementsId = [];
    if (mode === 'edit' && initial?.criteria_elements) {
      const criteriaElementsId = criteria_elements.map(item => item.id_element);
      initial?.criteria_elements?.forEach(initEle => {
        if (!criteriaElementsId.includes(initEle?.id)) {
          deleteElementsId.push(initEle?.id);
        }
      });
    }
    if (deleteElementsId) {
      deleteElementsId.forEach(async item => {
        await props.deleteElementApi(item);
      });
    }
    await props.editApi(initial.id, dataRequest);
    if (location.pathname !== '/request/add') {
      props.fetchData();
    }
  };

  const handleRedo = useCallback(() => {
    form.resetFields();
    if (initial) {
      const currentCriteria =
        initial?.criteria_elements?.map(item => ({
          name: item.name,
          id_criteria: item.id_criteria,
          id_element: item.id,
        })) || [];
      form.setFieldsValue({
        ...currentData,
        criteria_elements: currentCriteria,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentData]);

  const handlChangeName = useCallback(e => {
    setCurrentData(prev => ({ ...prev, name: e.target.value }));
  }, []);

  const handleChangeStatus = useCallback(e => {
    setCurrentData(prev => ({ ...prev, status: e.target.value }));
  }, []);
  const handleChangeDescription = useCallback(e => {
    setCurrentData(prev => ({ ...prev, description: e.target.value }));
  });

  const handleGetModalWidth = useMemo(() => {
    const modalWidth = handleGetCommonModalWith(currentBreakpoint);
    return modalWidth;
  }, [currentBreakpoint]);

  useEffect(() => {
    setCurrentData({
      name: initial?.name || '',
      status: initial?.status || DEFAULT_STATUS,
    });
  }, [initial]);

  return (
    <GeneralDrawer
      {...props}
      fillData={fillData}
      form={form}
      catchCallback={catchCallback}
      fullscreenClassName="table-fullscreen"
      requiredFields={['name']}
      modal
      onFinish={mode === 'edit' ? onFinish : undefined}
      width={handleGetModalWidth}
    >
      <FormInput
        name="name"
        label={t('criteria.name')}
        placeholder={t('criteria.namePlaceHolder')}
        min={3}
        required
        onChange={handlChangeName}
      />
      {/* <FormListCustom
        name="criteria_elements"
        label={t('criteria.levelCriteria')}
        addLabel={t('criteria.addLevelLabel')}
        undoLabel={t('common.undo')}
        // rules={levelCriteriaRules}
        // itemRules={levelItemCriteriaRules}
        placeholder={t('criteria.levelCriteriaPlaceholder')}
        handleRedo={mode === 'edit' ? handleRedo : undefined}
        // required
      /> */}
      <FormInput
        label={t('position.description')}
        onChange={handleChangeDescription}
        name="description"
        rows={8}
        textArea
      />
      <Status handlChangeStatus={handleChangeStatus} />
    </GeneralDrawer>
  );
}
