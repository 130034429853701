import { Form } from 'antd';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import GeneralDrawer from '../../../../components/Drawer/GeneralDrawer';
import { FormDatePicker, FormInput } from '../../../../components/Form';
import {
  API_RESPONSE_DATE_FORMAT,
  DISPLAY_FORMAT,
} from '../../../../global/constants';
import { requiredFields } from '../constant';
import { handleGetCommonModalWith } from '../../BasicTable/Template/constants';
import useCurrentBreakPoint from '../../../../hooks/useCurrentBreakPoint';

export default function ReleaseForm(props) {
  const [form] = Form.useForm();
  const { t } = useTranslation('translation', {
    keyPrefix: 'release',
  });
  const { currentBreakpoint } = useCurrentBreakPoint();

  const { initial, mode } = useSelector(state => state.drawer);

  const fillData = () => {
    form.resetFields();
    const init = mode === 'add' ? {} : initial;
    const release = init.daterelease;

    form.setFieldsValue({
      ...init,
      daterelease: release ? moment(release) : undefined,
    });
  };

  const transformValues = values => {
    const release = values.daterelease;
    if (release) values.daterelease = release.format(API_RESPONSE_DATE_FORMAT);
    else delete values.daterelease;
    return values;
  };

  const modalWidth = useMemo(() => {
    const modalWidth = handleGetCommonModalWith(currentBreakpoint);
    return modalWidth;
  }, [currentBreakpoint]);

  return (
    <GeneralDrawer
      {...props}
      form={form}
      fillData={fillData}
      requiredFields={requiredFields}
      transformValues={transformValues}
      modal
      fullscreenClassName="table-fullscreen"
      width={modalWidth}
    >
      <FormInput
        name="version"
        label={t('version')}
        placeholder={t('versionPlaceholder')}
        required
      />
      <FormDatePicker
        name="daterelease"
        label={t('dateRelease')}
        format={DISPLAY_FORMAT}
      />
      <FormInput
        name="conent"
        label={t('description')}
        required
        textArea
        rows={12}
      />
    </GeneralDrawer>
  );
}
